datasource-list .add-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

datasource-list .panel-ga > .panel-body {
    height: auto;
    min-height: 50px;
}

datasource-list .dataTables_wrapper{
    /*top: -27px;*/
}
  
datasource-list td{
    padding-right: 10px !important;
    padding-left: 10px !important;
}
  
datasource-list button.multiselect{
    padding: 3px 7px;
}
  
datasource-list realtime > .realtime-component > .row{
    height: unset !important;
    margin-bottom: 0!important;
}
  
datasource-list .power-container{
    min-height: 0;
}
  
datasource-list .noLoad{
}
 
datasource-list .power-container  .row{
    margin-bottom: 0!important;
    margin-right: 0!important;
    margin-left: 0!important;
}
  
datasource-list .dataTables_wrapper .row {
    margin-right: -15px;
}

datasource-list .power-container>.row>.col-md-12{
    padding-left: 0px;
}

datasource-list .source.meter-generation{
    background-color: var(--alert---alert-500);
    margin-left: 24px;
}

datasource-list .source.meter-generation:hover{
    background-color: var(--alert---alert-700);
    color: var(--grayscale---basic--white);
    text-decoration: none;
}

datasource-list .source {
    font-family: "Roboto";
    background-color: var(--primary-color);
    color: var(--grayscale---basic--white);
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    height: 20px;
    padding: 3px;
    border-collapse: separate;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
    display: table;
    cursor: pointer;
    float: left;
    max-width: 140px;
    margin-bottom: 3px;
}

datasource-list .dropdown-menu.meters-drop > li > a:hover {
    background-color: transparent;
}

tr.childs .source.meter{
  margin-left: 38px;
}
  
.source.meter {
  background-color: var(--primary-color);
}

datasource-list .source.meter.meter:hover {
  background-color: var(--hover1);
  color: var(--grayscale---basic--white);
  text-decoration: none;
}

datasource-list .source.group:hover {
 background-color: var(--hover2);
 color: var(--grayscale---basic--white);
 text-decoration: none;
}

datasource-list .source.group {
    background-color: var(--secondary-color);
}

datasource-list .fa-chevron-down {
    float: left;
    margin-left: 15px;
    margin-top: 8px;
    cursor: pointer;
    display: inline-table;
}

  datasource-list label.checkbox{
    display: inline-block !important;
    left: -25px;
    top: -4px;
    z-index: 1;
    font-size: 14px;
    padding-right: 0px !important;
  }
  datasource-list .cbx{
    z-index: 0;
    float: left;
    border: 1px solid var(--primary-color);
  }
  datasource-list .active .cbx{
    border-color: transparent;
    background: var(--primary-color);
    animation: jelly 0.6s ease;
  }
  datasource-list .active .cbx:after{
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
  
  datasource-list input[type="checkbox"]{
    visibility: hidden;
  }

  datasource-list .panel-ga .loading-component {
    background: none;
    justify-content: left;
  }

  @media (min-width: 1500px) {
    datasource-list .source.meter-generation{
        margin-right: 100px
    }
  }

  @media (min-width: 1900px) {
    datasource-list .source.meter-generation{
        margin-right: 200px
    }
  }