@charset "UTF-8";
// <!-- build:css assets/style-rnd-part.css -->
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-multiselect/dist/css/bootstrap-multiselect.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "~bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css";
@import "~metismenu/dist/metisMenu.min.css";

// @import "~elegant-icons/style.css";
@import "~tether-shepherd/dist/css/shepherd-theme-default.css";
@import "assets/css/dashboard-projects.css";
@import "~pace-progress/themes/green/pace-theme-minimal.css";
@import "~angular-datepicker/dist/angular-datepicker.css";
@import "~@cgross/angular-notify/angular-notify.css";
@import "~datatables.net-responsive-dt/css/responsive.dataTables.min.css";
@import "~datatables.net-dt/css/jquery.dataTables.min.css";
@import "~ng-tags-input/build/ng-tags-input.min.css";
@import "~angular-bootstrap-toggle/dist/angular-bootstrap-toggle.min.css";
@import "assets/css/styles.css";
@import "~textangular/dist/textAngular.css";

// @import "~components-font-awesome/css/fontawesome-all.min.css";
@import "@greenant/fontawesome-pro/css/all.min.css";
@import "@greenant/fontawesome-pro/scss/brands";
@import "@greenant/fontawesome-pro/scss/regular";
@import "@greenant/fontawesome-pro/scss/solid";
@import "@greenant/fontawesome-pro/scss/v4-shims";
