energy-bill-breadcrumb .top-breadcrumb {
  margin-top: -5px;
}

energy-bill-breadcrumb .flex {
  display: flex;
}

energy-bill-breadcrumb .support-info {
  flex-direction: column;
  flex-wrap: wrap;
  text-align: start;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -12px 10px;
  border-left: 1px solid lightgrey;
  padding-left: 10px;
}
