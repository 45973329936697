registrations .page-tabs{
  white-space: nowrap;
}

registrations .move-left-navbar, registrations .move-right-navbar{
  align-self: center;
  z-index: 1;
  border: 2px var(--grayscale---gray-600) solid;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
}

registrations .move-right-navbar{
  margin-left: 15px;
  margin-right: -15px;
}

registrations .move-left-navbar{
  margin-right: 15px;
  margin-left: -15px;
}

registrations .move-left-navbar:active, registrations .move-right-navbar:active{
  border: 1px var(--grayscale---gray-600) solid;
}

registrations .btn-disabled{
  color: var(--grayscale---gray-400);
  border: 2px var(--grayscale---gray-400) solid;
  cursor: default;
}

registrations .btn-disabled:active{
  border: 2px var(--grayscale---gray-400) solid;
}

registrations .navbar-content{
  overflow: hidden;
  padding-top: 3px;
}