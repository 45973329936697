:root {
    /* White Label */
    --primary-color: #33CC99;
    --secondary-color: #669966;
    --lighter-primary: #DAFBF0;
    --hover1: #21937B;
    --hover2: #527A52;

     /* Graficos com White Label */
     --chartColor: #33CC99;
     --chartColordark: #29A37A;;
     --chartColorlight: #85E0C2;

    /* New Color theme */
    --brand-colors---brand-50: #F2FFFA;
    --brand-colors---brand-100: #DAFBF0;
    --brand-colors---brand-200: #AFEBD6;
    --brand-colors---brand-300: #7FDFBE;
    --brand-colors---brand-400: #56D5AA;
    --brand-colors---brand-500: #33CC99;
    --brand-colors---brand-600: #2AB08B;
    --brand-colors---brand-700: #21937B;
    --brand-colors---brand-800: #197568;
    --brand-colors---brand-900: #125751;

    --grayscale---basic--white: #FFFFFF;
    --grayscale---gray-50: #FAFAFA;
    --grayscale---gray-100: #F6F6F6;
    --grayscale---gray-200: #E5E5E5;
    --grayscale---gray-300: #D4D4D4;
    --grayscale---gray-400: #A3A3A3;
    --grayscale---gray-500: #737373;
    --grayscale---gray-600: #525252;
    --grayscale---gray-700: #404040;
    --grayscale---gray-800: #262626;
    --grayscale---gray-900: #171717;

    --success---success-50: #F2FFFA;
    --success---success-100: #DAFBF0;
    --success---success-200: #AFEBD6;
    --success---success-300: #7FDFBE;
    --success---success-400: #56D5AA;
    --success---success-500: #33CC99;
    --success---success-600: #2AB08B;
    --success---success-700: #21937B;
    --success---success-800: #197568;
    --success---success-900: #125751;

    --info---info-50: #F1F8FF;
    --info---info-100: #CCE5FB;
    --info---info-200: #96BFE4;
    --info---info-300: #71A8DB;
    --info---info-400: #5094D3;
    --info---info-500: #3383CC;
    --info---info-600: #2C71AF;
    --info---info-700: #266197;
    --info---info-800: #215382;
    --info---info-900: #1C4770;

    --alert---alert-50: #FFF9E9;
    --alert---alert-100: #FFECB7;
    --alert---alert-200: #FFE28F;
    --alert---alert-300: #FFD86B;
    --alert---alert-400: #FFCF4A;
    --alert---alert-500: #FFC72C;
    --alert---alert-600: #FFBF0E;
    --alert---alert-700: #F2B200;
    --alert---alert-800: #DAA000;
    --alert---alert-900: #C49000;

    --warning---warning-50: #FFF8EC;
    --warning---warning-100: #FEE9CA;
    --warning---warning-200: #FCD190;
    --warning---warning-300: #FBBC5E;
    --warning---warning-400: #FAAA32;
    --warning---warning-500: #F99A0C;
    --warning---warning-600: #D98405;
    --warning---warning-700: #B87004;
    --warning---warning-800: #9C5F03;
    --warning---warning-900: #855103;

    --error---error-50: #FFF4F4;
    --error---error-100: #FFDBDB;
    --error---error-200: #EA9696;
    --error---error-300: #E37474;
    --error---error-400: #DD5555;
    --error---error-500: #D73A3A;
    --error---error-600: #C82B02;
    --error---error-700: #B02424;
    --error---error-800: #9B2020;
    --error---error-900: #881C1C;

    --dataviz---lt--green: #33CC99;
    --dataviz---md--blue: #48A0F0;
    --dataviz---lt--pink: #E062B4;
    --dataviz---dk--orange: #EC8720;
    --dataviz---dk--pink: #9E0059;
    --dataviz---md--yellow: #F8CC2D;
    --dataviz---dk--blue: #045DB7;
    --dataviz---md--green: #008F5D;
    --dataviz---red: #D73A3A;
    --dataviz---lt--orange: #FFC47C;
    --dataviz---md--purple: #9820B1;
    --dataviz---lt--blue: #47C8F0;
    --dataviz---dk--purple: #52357C;
    --dataviz---brown: #996633;
    
    --dataviz---extra--purple: #6c48a2;
}
