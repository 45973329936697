  company-list .add-button{
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

#company-page {
  font-size: 18px;
}

company-detail h3{
  font-weight: bold;
}

#company-page label {
  font-size: 14px;
  color: var(--grayscale---gray-600);
}

#company-page .form-group {
  white-space: nowrap;
}

#company-page .row {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

#company-page .optional{
  font-size: 14px;
  margin-left: 10px;
}

#company-page section {
  border-bottom: 1px #d2d2d2 solid;
  padding-bottom: 9px;
  padding-top: 16px;
}

#company-page .btn-add-ga{
  width: unset;
  font-family: "Roboto";
}

#company-page .contact-delete{
  background-color: transparent;
  color: var(--error---error-500);
  float: right;
  border: none;
  margin: 30px 0px 30px 25px;
}

#organizationSelector{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}