
.consumption-component .power-selector span {
    cursor: pointer;
    width: 60px;
    display: inline-block;
    text-align: center;
    height: 25px;
}

.consumption-selector {
  padding-left: 0px;
}
.consumption-component .chart {
    padding-left: 0px;
}

.consumption-component .chart {
  height: 293px;
  z-index: 0;
}

.consumption-main-info {
  font-size: 16px;
  padding-right: 0px;
  padding-left: 0px;
}

.consumption-main-info.forecast-total,
.consumption-component .consumption-main-info.forecast-total .total {
  color : var(--grayscale---gray-400);
}

.title-measurement  {
  color : var(--grayscale---gray-600);
}

body:not(.nav-toggled) .open-side-panel .consumption-component .consumption-main-info, body:not(.nav-toggled) .open-side-panel .consumption-component .consumption-main-info .title-measurement{
  font-size: 14px!important;
  padding-left: 0px;
  padding-right: 0px;
}

.consumption-component .little-botom-margin {
    margin-bottom: 13px;
    border-bottom: 1px solid var(--grayscale---gray-700);
    width: 100%;
}

.consumption-component .year-free-contract{
  padding: 0px!important;
}

consumption-generation-chart .consumption-component .little-botom-margin, .consumption-component .consumption-main-info.free-contract .little-botom-margin, .consumption-component .consumption-main-info.peak .little-botom-margin{
  margin-bottom: 5px;
}

consumption-generation-chart .fa-chevron-down, .consumption-main-info.free-contract .fa-chevron-down, .consumption-main-info .collapsible-sub-info .fa-chevron-down, .consumption-main-info.peak .fa-chevron-down{
  font-size: smaller;
  float: right;
  padding: 5px;
  cursor: pointer;
}
consumption-generation-chart .consumption-main-info, .consumption-main-info.free-contract, .consumption-main-info.peak, .consumption-main-info.consumption-accumulated{
  font-size: 14px!important;
  padding-left: 0px;
  margin-left: -10px;
  padding-right: 0px;
  margin-right: 10px;
  float: right;
}

consumption-generation-chart .title-measurement, .consumption-main-info.free-contract .title-measurement,  .consumption-main-info.peak .title-measurement{
  font-size: 16px;
}

consumption-generation-chart .sub-info:first, 
.consumption-main-info.free-contract .sub-info:first,
.consumption-main-info.peak.sub-info:first,
.consumption-main-info .collapsible-sub-info.sub-info
{
  display: grid;
}

consumption-generation-chart .sub-info, 
.consumption-main-info.free-contract .sub-info,
.consumption-main-info.peak .sub-info,
.consumption-main-info .collapsible-sub-info .sub-info
{
  display: none;
}

consumption-generation-chart .lbl-value, .consumption-main-info.free-contract .lbl-value, .consumption-main-info.peak .lbl-value{
  padding-bottom: 5px;
}

consumption-generation-chart .value, .consumption-main-info.free-contract .value, .consumption-main-info.peak .value{
  float: right;
}

consumption-generation-chart .value.bind, .consumption-main-info.free-contract .value.bind, .consumption-main-info.peak .value.bind{
  text-align: right;
}

consumption-generation-chart .value-nopeak, .consumption-main-info.free-contract .value-nopeak, .consumption-main-info.peak .value-nopeak{
  display: block;
  padding-left: 45px;
  margin-top: -10px;
  font-size: 15px;
}

.consumption-main-info.free-contract.forecast-total, .consumption-main-info.peak.forecast-total{
  padding-left: 15px!important;
}
.consumption-component .little-space {
    margin-bottom: 7%;
}

.consumption-component .phase {
    position: relative;
    z-index: 1000;
}

.consumption-component .hour-label{
  position: absolute;
  margin-left: -20px;
}


.consumption-component .hour-label.two-digits{
  margin-left: -22px;
}

.consumption-component .hour-label .twenty-four{
  margin-left: 14px;
}

@media (min-width: 1400px) {
  .consumption-component .hour-label{
    margin-left: -28px;
  }

  .consumption-component .hour-label.two-digits{
    margin-left: -32px;
  }

  .consumption-component .hour-label .twenty-four{
    margin-left: 32px;
  }
}


.consumption-component .unit {
  font-weight: 100;
}

.consumption-component .money-value{
  font-weight: 800;
}


.consumption-component .total {
  color :var(--chartColor);
}

.consumption-component .total.generation {
  color : var(--alert---alert-500);
}

.consumption-component .consumption-main-info.no-peak{
  height: 286px;
}

.consumption-component .total.no-peak{
  position: absolute;
  top: 0;
  bottom: 0;
  height: 62px;
  margin: auto;

}

.consumption-component .no-peak .money{
  font-size: 24px;
  margin-top: auto;
}

.consumption-component .no-peak .energy{
  font-size: 24px;
}

.consumption-component .no-peak  .money-small{
  font-size: 20px;
}

.consumption-component  .no-peak  .energy-small{
  font-size: 20px;
}

body:not(.nav-toggled) .open-side-panel .consumption-component .sub-info .row{
  display: ruby;
}

body:not(.nav-toggled) .open-side-panel .consumption-component .no-peak  .money,
body:not(.nav-toggled) .open-side-panel .consumption-component  .no-peak  .energy{
  font-size: 18px;
}

.consumption-component .forecast-controllers{
  position: absolute;
  z-index: 1;
  width: 100%;
  left: -20px;
  top: 3px;
}

.consumption-component .forecast-controllers label{
  font-size: 14px;
  margin-bottom: 0;
}

.consumption-component .standardProfile .checkbox:checked ~ .cbx{
  color: var(--dataviz---dk--blue);
}
.consumption-component .checkbox:checked ~ .cbx {
    background: var(--dataviz---dk--blue);
}
.consumption-component .checkbox:checked ~ .cbx.forecast {
    background: var(--grayscale---gray-700);
}

.consumption-component .solar-view-active {
    border-bottom: 4px solid var(--primary-color);
    font-weight: 800;
}

.consumption-selector {
  margin-bottom: -38px;
  margin-top: -3px;
  position: relative;
  z-index: 2;
  /*margin-left: 16px;*/
  /*width: 33%;*/
}

.consumption-component .energy-alert {
  color: var(--error---error-500);
}

.consumption-component .solar-view-selector span, .consumption-component .consumption-selector span {
    cursor: pointer;
    width: 60px;
    display: inline-block;
    text-align: center;
    height: 25px;
    font-size: 16px;
    color: var(--grayscale---gray-700);
}

.consumption-component span.consumption-selector-big  {
  width: 95px;
}

.consumption-component .active {
  border-bottom: 4px solid var(--primary-color);
  font-weight: 800;
}

.consumption-component .overtaking{
  color: var(--error---error-500);
  font-size: 18px;
}

#consumptionAccumulated .highcharts-series-2 {
  stroke-dasharray: 2, 5;
}
.icon-img{
  margin-bottom: 10px;
}

@media (max-width: 1400px) {
  .year-free-contract>div{
    margin-left: -15px;
  }
}

@media(min-width:768px) {
    .panel-ga.consumptionChart>.panel-body {
        height: 305px;
    }
}
