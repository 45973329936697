proinfa-list .add-button {
  position: absolute;
  left: 225px;
  top: 130px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
  width: calc(100% - 225px) !important;
}

proinfa-list .buttons {
  float: right;
}

proinfa-list table.ga-table.dataTable tbody td {
  border-bottom: 0px;
}

proinfa-list .child-row .tr {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

proinfa-list .side-panel-ga {
  height: 94vh;
  display: none;
}

proinfa-list .side-panel-ga-content {
  height: 100%;
  padding-top: 26px;
}

proinfa-list .side-panel-ga {
  right: -287px;
  margin-top: 0px;
}

proinfa-list .expired {
  color: var(--error---error-500);
}

/* proinfa-list .side-panel-ga.side-panel-ga-open {
  transform: translateX(0px) !important;
} */
