consuming-unit .navbar-left {
  justify-content: right !important;
}

consuming-unit .navbar-content > h3,
consuming-unit .navbar-content > i {
  margin-right: 10px;
}

consuming-unit .box {
  display: flex;
  /* flex-flow: row wrap; */
  justify-content: space-evenly;
}

consuming-unit .uc-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 445px;
  height: 134px;
  margin-left: 22px;
  background-color: var(--grayscale---gray-100);
}

consuming-unit .uc-type-ds {
  height: 305px;
}

consuming-unit .uc-type-ind {
  height: 355px !important;
}

consuming-unit .type-label {
  padding-left: 0px !important;
}

consuming-unit label {
  font-size: 16px;
  font-weight: 400 !important;
}

consuming-unit .checkbox input[type=checkbox], consuming-unit .checkbox-inline input[type=checkbox], 
consuming-unit .radio input[type=radio], consuming-unit .radio-inline input[type=radio]{
  position: relative!important;
  margin-right: 5px!important;
}

consuming-unit .radio-label-sel {
  margin-bottom: 30px;
}

consuming-unit .group-ind {
  margin-top: 25px;
  margin-left: 45px;
}

consuming-unit .radio-container {
  display: flex;
  align-items: center;
}

consuming-unit .radio+.radio{
  margin-top: 10px;
}

consuming-unit .radio+.radio .radio-field {
  margin-left: 56px;
}

consuming-unit .form-title {
  font-weight: 500 !important;
  margin: 30px 0px 30px 30px;
}

consuming-unit small {
  margin-left: 20px;
  color: var(--grayscale---gray-500);
}

consuming-unit .radio+.radio small{
  margin-left: 76px;
}

consuming-unit item-selector .dropdown-item {
  font-size: 13px;
}

consuming-unit .credential-fields {
  padding-top: 15px;
  padding-left: 60px;
}

consuming-unit .org-sel {
  padding-left: 0px !important;
  margin-top: -6px !important;
}

consuming-unit .no-left-pad {
  padding-left: 0px !important;
}

consuming-unit .max-input {
  width: max-content !important;
}

consuming-unit .cred-type-radio {
  margin-left: 46px;
  margin-bottom: 5px;
}

consuming-unit .wrapper {
  background-color: whitesmoke;
  list-style-type: none;
  padding: 0;
  border-radius: 3px;
}

consuming-unit .form-row {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}

consuming-unit .form-row > label {
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
}

consuming-unit .form-row > item-selector > div > div > input {
  flex: 2;
  padding: 0.5em;
}
