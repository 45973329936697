.comparativeChart .totals{
	padding-top: 0px;
	border-left: 1px solid var(--grayscale---gray-200);
	padding-right: 0px;
}
.comparativeChart .totals .total-year{
	margin-top: 35px;
	padding-left: 10px!important;
}

.comparativeChart :not(.totals)>.total-year:not(.totals){
	display: flex;
	justify-content: center;
}

.comparativeChart .total-year.billed:not(.totals){
	display: flex;
	justify-content: space-between;
}

.comparativeChart .total-year .row.series{
	display: flex;
}

.comparativeChart .total-year.billed .row.series:first-child{
	margin-left: 25%;
}

.comparativeChart .total-year.billed .row.series:last-child{
	margin-right: 15%;
}


.comparativeChart .month-total{
	margin-top: 30px;
}

.comparativeChart .chart, .comparativeChart .totals{
	height: 200px;
}

.comparativeChart.year .chart, .comparativeChart.year .totals{
	height: 240px;
	padding-bottom: 15px;
}

.comparativeChart .loading-component {
	height: 240px;
	z-index: 7;
}

.year .comparativeChart .loading-component {
	height: 280px;
}


.comparativeChart.year .chart .totals{
	margin-top: 50px;
}

.comparativeChart .percent {
	font-size: 30px;
	padding-left: 25px;
	text-align: center;
}

.comparativeChart .percent.green, .comparativeChart .total.green {
	color: var(--success---success-500);
}

.comparativeChart .percent.red, .comparativeChart .total.red {
	color: var(--error---error-500);
}


.comparativeChart .total {
	font-size: 16px;
	padding-top:8px;
	text-align: center;
}

.comparativeChart .total .series label{
	font-size: 13px;
}

.comparativeChart .checkbox.serie-ml:checked ~ .cbx{
	background-color: var(--chartColor);
}

.comparativeChart .checkbox.serie-acr:checked ~ .cbx{
	background-color: var(--dataviz---md--blue);
}

.comparativeChart .checkbox.serie-economy:checked ~ .cbx{
	background-color: var(--alert---alert-500);
}

.comparativeChart .gains {
	margin-bottom: 30px;
}

.comparativeChart .gains h3{
	margin-top: 12px;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
}

.comparative-selector {
	padding-left: 0px;
  }
  
  .comparative-selector {
	margin-bottom: 10px;
	margin-top: -3px;
	position: relative;
	z-index: 2;
  }
  
  .comparativeChart .comparative-selector span {
	  cursor: pointer;
	  width: 185px;
	  display: inline-block;
	  text-align: center;
	  height: 25px;
	  font-size: 16px;
	  color: var(--grayscale---gray-700);
  }
  
  .comparativeChart .comparative-selector span.big {
	  width: 220px;
  }
  
  .comparativeChart .active {
	border-bottom: 4px solid var(--primary-color);
	font-weight: 800;
  }
  
  .year>.panel-ga.comparativeChart>.panel-body {
	  height: 290px;
  }
  