/*
 * Template Name: AppKit
 * Version: 1.0
*/
/* Theme default */
html {
	height: 100%;
	background: #f9f9fb;
}
.preload * {
	transition: none !important;
}
.container-fluid {
	padding: 0 24px;
}
body {
	height: 100%;
	font-family: "Open Sans", arial, sans-serif;
	color: #494d55;
	font-size: 13px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #494d55;
	min-height: 100%;
	overflow-x: hidden;
}

hr {
	border-top: 1px solid lightgrey;
}

.form-submit-buttons {
	padding: 15px;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.video-container iframe {
	max-width: 100%;
}
.breadcrumb {
	background: none;
	padding: 0;
}
button.close:focus {
	outline: none;
}
.close {
	text-shadow: none;
}
/* ======= Dropdown ======= */
.dropdown-toggle {
	cursor: pointer;
}
.dropdown-menu {
	border: 1px solid #e6e6e6;
	border-radius: 0;
	background-clip: padding-box;
	/* box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); */
	box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5);
	cursor: default;
	max-height: 250px !important;
	overflow: auto;
	border-radius: 4px;
}
.dropdown-menu > li {
	margin-bottom: 0;
	font-size: 13px;
}
.dropdown-menu > li > a {
	padding: 10px 15px;
	color: var(--grayscale---gray-600);
	cursor: pointer;
}
.dropdown-menu > li > a > i {
	color: var(--grayscale---gray-400);
}
.dropdown-menu > li > a:hover {
	background: var(--grayscale---gray-100);
}
.dropdown-menu > li > a .icon {
	vertical-align: middle;
	font-size: 20px;
	display: inline-block;
	margin-right: 5px;
}
.dropdown-menu .dropdown-footer {
	background: #f9f9fb;
	color: #616670;
	padding: 15px;
	text-align: center;
}
.dropdown-menu .dropdown-footer a {
	color: #616670;
}
.dropdown-menu .dropdown-footer a:hover {
	color: #494d55;
}

.dropdown-overflow {
	overflow: auto;
	max-height: 300px;
}

/* ======= Pace ======== */
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.pace-inactive {
	display: none;
}
.pace .pace-progress {
	background: #40babd;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}
.theme-2 .pace .pace-progress {
	background: #6dbd63;
}
.theme-3 .pace .pace-progress {
	background: #497cb1;
}
.theme-4 .pace .pace-progress {
	background: #ec6952;
}
/* ======= Header ======= */
.header {
	background: var(--grayscale---basic--white);
	position: relative;
	z-index: 1000;
	height: 80px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
}
.header .branding {
	/*background: #40babd;*/
	background: var(--primary-color);
	float: left;
	height: 80px;
	transition: width 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	width: 180px;
	overflow: hidden;
	position: relative;
	will-change: width;
}
.theme-2 .header .branding {
	background: #6dbd63;
}
.theme-3 .header .branding {
	background: #497cb1;
}
.theme-4 .header .branding {
	background: #ec6952;
}

.theme-ga-1 .header .branding {
	background: var(--primary-color);
}
.compact:not(.nav-toggled) .header .branding,
.mobile.nav-toggled .header .branding,
.nav-toggled:not(.compact):not(.mobile) .header .branding {
	width: 40px;
}
.mobile:not(.nav-toggled) .header .branding {
	width: 0;
	top: -2px;
}
.header .branding .logo-icon {
	position: relative;
	width: 30px;
	height: 30px;
}
.header .branding .logo {
	font-size: 28px;
	margin: 0;
	padding-top: 20px;
	text-transform: uppercase;
	font-weight: 600;
}
.header .branding .logo .highlight {
	font-weight: 800;
}
.header .branding .logo a {
	color: var(--grayscale---basic--white);
	white-space: nowrap;
}
.header .branding .logo a:hover {
	text-decoration: none;
}
.header .branding .logo .nav-label {
	white-space: nowrap;
}
.compact:not(.nav-toggled) .header .branding .logo .nav-label,
.mobile .header .branding .logo .nav-label,
.nav-toggled:not(.compact):not(.mobile) .header .branding .logo .nav-label {
	display: none;
}
.header .topbar {
	background: #fff;
	padding: 15px;
	display: block;
	height: 80px;
	text-align: center;
}
.header .topbar .icon {
	color: #9499a3;
}
.header .main-nav-toggle {
	display: inline-block;
	float: left;
	background: none;
	border: none;
	padding: 12px 12px;
	margin-top: 5px;
	margin-left: 15px;
	position: relative;
}
.header .main-nav-toggle .icon {
	position: absolute;
	left: 2px;
	top: 13px;
	color: #40babd;
	font-weight: bold;
	transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.theme-2 .header .main-nav-toggle .icon {
	color: #6dbd63;
}
.theme-3 .header .main-nav-toggle .icon {
	color: #497cb1;
}
.theme-4 .header .main-nav-toggle .icon {
	color: #ec6952;
}
.nav-toggled .header .main-nav-toggle .icon,
.compact .header .main-nav-toggle .icon,
.mobile .header .main-nav-toggle .icon {
	transform: rotateZ(180deg);
}
.compact.nav-toggled .header .main-nav-toggle .icon,
.mobile.nav-toggled .header .main-nav-toggle .icon {
	transform: rotateZ(0);
}
.header .main-nav-toggle .icon-bar {
	display: block;
	width: 20px;
	height: 2px;
	border-radius: 2px;
	background-clip: padding-box;
	background: #9499a3;
}
.header .main-nav-toggle .icon-bar + .icon-bar {
	margin-top: 5px;
}
.header .main-nav-toggle:hover .icon-bar {
	background: #616670;
}
.header .main-nav-toggle:focus {
	outline: none;
}
.header .search-container {
	float: left;
	display: inline-block;
	margin-left: 10px;
	margin-top: 5px;
	position: relative;
}
.header .search-container .icon {
	position: absolute;
	left: 2px;
	top: 10px;
	font-size: 18px;
	color: #afb3bb;
	cursor: pointer;
}
.header .search-container .icon:hover {
	color: #40babd;
}
.theme-2 .header .search-container .icon:hover {
	color: #6dbd63;
}
.theme-3 .header .search-container .icon:hover {
	color: #497cb1;
}
.theme-4 .header .search-container .icon:hover {
	color: #ec6952;
}
.header .search-container .icon.clear-search {
	left: auto;
	right: 0;
	display: none;
	cursor: pointer;
}
.header .search-container .main-search-input-wrapper {
	padding-left: 0;
}
.header .search-container .main-search-input {
	background: var(--grayscale---basic--white);
	border-radius: 0;
	background-clip: padding-box;
	border: none;
	border-bottom: 1px solid #f3f3f3;
	padding-left: 30px;
	padding-top: 2px;
	padding-bottom: 5px;
	margin-top: 5px;
	width: 260px;
	vertical-align: middle;
}
.header .search-container .main-search-input:focus {
	border-color: #40babd;
}
.theme-2 .header .search-container .main-search-input:focus {
	border-color: #6dbd63;
}
.theme-3 .header .search-container .main-search-input:focus {
	border-color: #497cb1;
}
.theme-4 .header .search-container .main-search-input:focus {
	border-color: #ec6952;
}
.header .navbar-tools {
	float: right;
}
.header .utilities-container {
	float: left;
	display: inline-block;
	padding-top: 5px;
	margin-top: 5px;
}
.header .utilities-container .item {
	position: relative;
	margin-right: 15px;
	display: inline-block;
	float: left;
	margin-right: 25px;
}
.header .utilities-container .icon {
	font-size: 22px;
	vertical-align: middle;
	position: relative;
	top: 2px;
	cursor: pointer;
}
.header .utilities-container .icon.pe-7s-mail {
	font-size: 26px;
}
.header .utilities-container .icon.fs1 {
	top: 0;
}
.header .utilities-container .icon.fa {
	top: 0;
}
.header .utilities-container .badge {
	position: absolute;
	right: -8px;
	top: -5px;
}
.header .utilities-container .item-notifications .dropdown-toggle {
	padding-bottom: 10px;
}
.header .utilities-container .item-notifications .dropdown-menu {
	width: 420px;
	left: auto;
	right: -101px;
	padding: 0;
	font-size: 12px;
}
.header .utilities-container .item-notifications .dropdown-menu a {
	color: #616670;
}
.header .utilities-container .item-notifications .dropdown-menu a:hover {
	color: #494d55;
	text-decoration: none;
}
.header
	.utilities-container
	.item-notifications
	.dropdown-menu
	.dropdown-footer
	a:hover {
	text-decoration: underline;
}
.header .utilities-container .item-notifications .dropdown-menu .item {
	padding: 15px;
	padding-right: 60px;
	margin: 0;
	position: relative;
	border-bottom: 1px solid #f3f3f7;
}
.header .utilities-container .item-notifications .dropdown-menu .item:hover {
	background: #f9f9fb;
}
.header
	.utilities-container
	.item-notifications
	.dropdown-menu
	.item
	.media-body
	.action {
	color: #494d55;
	font-weight: 600;
}
.header
	.utilities-container
	.item-notifications
	.dropdown-menu
	.item
	.profile
	img {
	width: 32px;
	height: 32px;
}
.header .utilities-container .item-notifications .dropdown-menu .item .meta {
	color: #797f8b;
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 11px;
}
.header .utilities-container .item-notifications .dropdown-menu .arrow {
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #eaeaf1;
	position: absolute;
	right: 102px;
	top: -8px;
}
.header .utilities-container .item-notifications .dropdown-menu .arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid var(--grayscale---basic--white);
	position: absolute;
	right: -8px;
	top: 1px;
}
.header .utilities-container .item-messages .dropdown-toggle {
	padding-bottom: 10px;
}
.header .utilities-container .item-messages .dropdown-menu {
	width: 420px;
	left: auto;
	right: -101px;
	padding: 0;
	font-size: 12px;
}
.header .utilities-container .item-messages .dropdown-menu a {
	color: #616670;
}
.header .utilities-container .item-messages .dropdown-menu a:hover {
	color: #494d55;
	text-decoration: none;
}
.header
	.utilities-container
	.item-messages
	.dropdown-menu
	.dropdown-footer
	a:hover {
	text-decoration: underline;
}
.header .utilities-container .item-messages .dropdown-menu .item {
	padding: 15px;
	padding-right: 60px;
	margin: 0;
	position: relative;
	border-bottom: 1px solid #f3f3f7;
}
.header .utilities-container .item-messages .dropdown-menu .item:hover {
	background: #f9f9fb;
}
.header
	.utilities-container
	.item-messages
	.dropdown-menu
	.item
	.media-body
	.sender {
	color: #494d55;
	font-weight: 600;
	display: block;
	margin-bottom: 3px;
}
.header
	.utilities-container
	.item-messages
	.dropdown-menu
	.item
	.media-body
	.message-title {
	color: #494d55;
	display: block;
}
.header
	.utilities-container
	.item-messages
	.dropdown-menu
	.item
	.media-body
	.excerpt {
	display: block;
}
.header .utilities-container .item-messages .dropdown-menu .item .profile img {
	width: 32px;
	height: 32px;
}
.header .utilities-container .item-messages .dropdown-menu .item .meta {
	color: #797f8b;
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 11px;
}
.header .utilities-container .item-messages .dropdown-menu .arrow {
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #e6e6e6;
	position: absolute;
	right: 102px;
	top: -8px;
}
.header .utilities-container .item-messages .dropdown-menu .arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid var(--grayscale---basic--white);
	position: absolute;
	right: -8px;
	top: 1px;
}
.header .utilities-container .item-more .dropdown-toggle {
	padding-bottom: 10px;
}
.header .utilities-container .item-more .dropdown-menu {
	left: auto;
	right: -66px;
	width: 302px;
	padding: 0;
}
.header .utilities-container .item-more .dropdown-menu .title {
	margin-top: 0;
	margin-bottom: 10px;
	padding: 15px;
	font-size: 14px;
	color: #616670;
	background: #f9f9fb;
	border-bottom: 1px solid #f3f3f7;
	text-align: center;
}
.header .utilities-container .item-more .dropdown-menu .arrow {
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #e6e6e6;
	position: absolute;
	right: 67px;
	top: -8px;
}
.header .utilities-container .item-more .dropdown-menu .arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #f9f9fb;
	position: absolute;
	right: -8px;
	top: 1px;
}
.header .utilities-container .item-more .dropdown-menu .more-list {
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
	overflow: hidden;
}
.header .utilities-container .item-more .dropdown-menu .more-list > li {
	text-align: center;
	margin-bottom: 0;
	display: inline;
	font-size: 12px;
}
.header .utilities-container .item-more .dropdown-menu .more-list > li > a {
	color: #616670;
	display: inline-block;
	width: 100px;
	height: 100px;
	padding-top: 18px;
	float: left;
	overflow: hidden;
	margin: 0;
}
.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover {
	color: #40babd;
	text-decoration: none;
}
.theme-2
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover {
	color: #6dbd63;
}
.theme-3
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover {
	color: #497cb1;
}
.theme-4
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover {
	color: #ec6952;
}
.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover
	.icon {
	color: #40babd;
}
.theme-2
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover
	.icon {
	color: #6dbd63;
}
.theme-3
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover
	.icon {
	color: #497cb1;
}
.theme-4
	.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a:hover
	.icon {
	color: #ec6952;
}
.header
	.utilities-container
	.item-more
	.dropdown-menu
	.more-list
	> li
	> a
	.icon {
	vertical-align: inherit;
	font-size: 38px;
	display: inline-block;
	margin-right: 0;
}
.header .user-container {
	float: left;
	display: inline-block;
	margin-top: 5px;
}
.header .user-container.dropdown {
	cursor: pointer;
}

.fa-ellipsis-vertical {
	font-size: 20px;
	padding: 2px 9px;
}
.header .user-container.dropdown .fa-caret-down {
	color: #9499a3;
}
.header .user-container:hover img {
	-webkit-opacity: 0.9;
	-moz-opacity: 0.9;
	opacity: 0.9;
}
.header .user-container:hover .fa-caret-down {
	color: #494d55;
}
.header .user-container img {
	width: 36px;
	height: 36px;
	border-radius: 2px;
	background-clip: padding-box;
}
.header .user-container .icon {
	font-size: 20px;
}
.header .user-container .dropdown-menu {
	left: auto;
	right: 0;
	top: 45px;
	border: 1px solid #e6e6e6;
	border-radius: 0;
	background-clip: padding-box;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.header .user-container .dropdown-menu > li > a {
	padding: 10px 15px;
	color: #494d55;
}
.header .user-container .dropdown-menu > li > a:hover {
	background: #f9f9fb;
}
.header .user-container .dropdown-menu > li > a .icon {
	vertical-align: middle;
	font-size: 20px;
	display: inline-block;
	margin-right: 5px;
}
.header .user-container .dropdown-menu .arrow {
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #e6e6e6;
	position: absolute;
	right: 20px;
	top: -8px;
}
.header .user-container .dropdown-menu .arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid var(--grayscale---basic--white);
	position: absolute;
	right: -8px;
	top: 1px;
}
/* ======= Main Nav ======= */
.main-nav-wrapper {
	background: var(--grayscale---gray-700);
	color: var(--grayscale---basic--white);
	min-height: 100%;
	position: absolute;
	transition: width 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	width: 180px;
}
.compact:not(.nav-toggled) .main-nav-wrapper,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper {
	width: 40px;
}
.compact:not(.nav-toggled) .main-nav-wrapper .collapse,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper .collapse,
.compact:not(.nav-toggled) .main-nav-wrapper .collapsing,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper .collapsing,
.compact:not(.nav-toggled) .main-nav-wrapper .in,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper .in {
	height: auto !important;
}
.compact:not(.nav-toggled) .main-nav-wrapper a:hover + .sub-menu,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper a:hover + .sub-menu,
.compact:not(.nav-toggled) .main-nav-wrapper .sub-menu:hover,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper .sub-menu:hover {
	position: absolute;
	background-color: #31343a;
	display: block;
	z-index: 10;
}
.compact:not(.nav-toggled) .main-nav-wrapper a:hover + .sub-menu .nav-label,
.nav-toggled:not(.compact):not(.mobile)
	.main-nav-wrapper
	a:hover
	+ .sub-menu
	.nav-label,
.compact:not(.nav-toggled) .main-nav-wrapper .sub-menu:hover .nav-label,
.nav-toggled:not(.compact):not(.mobile)
	.main-nav-wrapper
	.sub-menu:hover
	.nav-label {
	display: block;
}
.mobile.nav-toggled .main-nav-wrapper {
	width: 40px;
}
.mobile:not(.nav-toggled) .main-nav-wrapper {
	width: 0px;
}
/* .mobile:not(.nav-toggled) .main-nav-wrapper li {
	transform: translateX(-40px);
	transition: transform;
	color: var(--grayscale---basic--white);
} */
.main-nav-wrapper .main-nav ul {
	padding-left: 0;
}
.main-nav-wrapper .main-nav li {
	list-style: none;
	display: block;
	font-size: 14px;
}
.main-nav-wrapper .main-nav li.active a,
.mobile .main-nav-wrapper .main-nav li a {
	background: #31343a;
}
.main-nav-wrapper .main-nav li.divider {
	height: 1px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.1);
}
.main-nav-wrapper .main-nav li .nav-label + .label {
	margin-left: 5px;
}
.main-nav-wrapper .main-nav li a {
	display: block;
	padding: 10px 0;
	padding-left: 10px;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	line-height: 1.5;
}
.main-nav-wrapper .main-nav li a:hover {
	text-decoration: none;
	background: #31343a;
	color: var(--grayscale---basic--white);
}
.mobile:not(.nav-toggled) .main-nav-wrapper .main-nav li a {
	padding-left: 0;
}
.main-nav-wrapper .main-nav li a .icon {
	line-height: 1.6;
	font-size: 16px;
	position: relative;
	top: -2px;
}
.compact .main-nav-wrapper .main-nav li a .icon {
	margin-right: 0;
}
.main-nav-wrapper .main-nav li .arrow {
	float: right;
	margin-right: 15px;
	margin-top: 5px;
	vertical-align: sub;
}
.main-nav-wrapper .main-nav li .icon {
	display: inline-block;
	margin-right: 5px;
	font-size: 18px;
	vertical-align: sub;
}
.main-nav-wrapper .main-nav li .badge {
	margin-left: 5px;
	display: inline-block;
}
.main-nav-wrapper .main-nav li .sub-menu {
	overflow: hidden;
}
.main-nav-wrapper .main-nav li .sub-menu li a {
	font-size: 13px;
	padding: 10px 0;
	padding-left: 30px;
	color: rgba(255, 255, 255, 0.6);
	/*border-left: 4px solid transparent;*/
	border-left: 4px solid var(--primary-color) transparent;
	border-bottom: none;
}
.main-nav-wrapper .main-nav li .sub-menu li a:hover {
	background: var(--grayscale---gray-800);
}
.main-nav-wrapper .main-nav li .sub-menu li.active a {
	color: var(--grayscale---basic--white);
	border-left: 4px solid #40babd;
	background: var(--grayscale---gray-800);
}
.theme-2 .main-nav-wrapper .main-nav li .sub-menu li.active a {
	border-left-color: #6dbd63;
}
.theme-3 .main-nav-wrapper .main-nav li .sub-menu li.active a {
	border-left-color: #497cb1;
}
.theme-4 .main-nav-wrapper .main-nav li .sub-menu li.active a {
	border-left-color: #ec6952;
}
.theme-ga-1 .main-nav-wrapper .main-nav li .sub-menu li.active a {
	border-left-color: var(--primary-color);
}
.main-nav-wrapper .main-nav li .sub-menu li.active.has-sub-menu-level2 a {
	border-left-color: transparent;
}
.main-nav-wrapper .main-nav li .sub-menu li.active .sub-menu-level2 li a {
	color: rgba(255, 255, 255, 0.5);
	padding-left: 55px;
}
.main-nav-wrapper .main-nav li .sub-menu li.active .sub-menu-level2 li a:hover {
	color: var(--grayscale---basic--white);
}
.main-nav-wrapper
	.main-nav
	li
	.sub-menu
	li.active
	.sub-menu-level2
	li.active
	a {
	color: var(--grayscale---basic--white);
	border-left: 4px solid #40babd;
}
.theme-2
	.main-nav-wrapper
	.main-nav
	li
	.sub-menu
	li.active
	.sub-menu-level2
	li.active
	a {
	border-left-color: #6dbd63;
}
.theme-3
	.main-nav-wrapper
	.main-nav
	li
	.sub-menu
	li.active
	.sub-menu-level2
	li.active
	a {
	border-left-color: #497cb1;
}
.theme-4
	.main-nav-wrapper
	.main-nav
	li
	.sub-menu
	li.active
	.sub-menu-level2
	li.active
	a {
	border-left-color: #ec6952;
}
/* Override metisMenu CSS */
.active > a > .fa.arrow:before {
	content: "\f0d8";
}
.fa.arrow:before {
	content: "\f0d7";
}
/* ===== Content Wrapper ===== */
.content-wrapper {
	background: #f9f9fb;
	margin-left: 180px;
	margin-right: 0;
	padding-top: 30px;
	padding-bottom: 60px;
	transition: margin 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	min-height: 1000px;
	/* prevent the body background colour to show */
}
.compact .content-wrapper {
	margin-left: 48px;
}
.compact.nav-toggled .content-wrapper {
	margin-left: 180px;
}
.mobile .content-wrapper {
	margin-left: 0;
}
.nav-toggled.mobile .content-wrapper {
	margin-left: 40px;
}
.nav-toggled:not(.compact):not(.mobile) .content-wrapper {
	margin-left: 40px;
}
.content-wrapper .view-title {
	font-size: 18px;
	margin-top: 0;
	margin-bottom: 30px;
	font-weight: 600;
}
.compact:not(.nav-toggled) .main-nav .nav-label,
.mobile.nav-toggled .main-nav .nav-label,
.nav-toggled:not(.compact):not(.mobile) .main-nav .nav-label,
.compact:not(.nav-toggled) .main-nav .label,
.mobile.nav-toggled .main-nav .label,
.nav-toggled:not(.compact):not(.mobile) .main-nav .label,
.compact:not(.nav-toggled) .main-nav li .badge,
.mobile.nav-toggled .main-nav li .badge,
.nav-toggled:not(.compact):not(.mobile) .main-nav li .badge,
.compact:not(.nav-toggled) .main-nav .fa.arrow,
.mobile.nav-toggled .main-nav .fa.arrow,
.nav-toggled:not(.compact):not(.mobile) .main-nav .fa.arrow,
.compact:not(.nav-toggled) .main-nav .sub-menu,
.mobile.nav-toggled .main-nav .sub-menu,
.nav-toggled:not(.compact):not(.mobile) .main-nav .sub-menu {
	display: none;
}
.compact:not(.nav-toggled) .main-nav a,
.mobile.nav-toggled .main-nav a,
.nav-toggled:not(.compact):not(.mobile) .main-nav a {
	transition: none !important;
}
.compact:not(.nav-toggled) .main-nav li .sub-menu li.active a,
.mobile.nav-toggled .main-nav li .sub-menu li.active a,
.nav-toggled:not(.compact):not(.mobile) .main-nav li .sub-menu li.active a {
	background-color: #31343a;
	border-left: none;
	color: rgba(255, 255, 255, 0.5);
	padding-left: 34px;
}
.compact:not(.nav-toggled) .main-nav ul,
.mobile.nav-toggled .main-nav ul,
.nav-toggled:not(.compact):not(.mobile) .main-nav ul {
	overflow: visible;
}
.compact:not(.nav-toggled) .main-nav .sub-menu,
.mobile.nav-toggled .main-nav .sub-menu,
.nav-toggled:not(.compact):not(.mobile) .main-nav .sub-menu {
	position: absolute;
	width: 180px;
	z-index: 10;
	left: 40px;
	margin-top: -44px;
}
.compact:not(.nav-toggled) .main-nav .sub-menu .sub-menu,
.mobile.nav-toggled .main-nav .sub-menu .sub-menu,
.nav-toggled:not(.compact):not(.mobile) .main-nav .sub-menu .sub-menu {
	display: none;
	left: 180px;
}
.compact:not(.nav-toggled) .main-nav li:hover > .sub-menu,
.mobile.nav-toggled .main-nav li:hover > .sub-menu,
.nav-toggled:not(.compact):not(.mobile) .main-nav li:hover > .sub-menu {
	display: block;
}
.compact:not(.nav-toggled) .main-nav li:hover > .sub-menu li:hover > .sub-menu,
.mobile.nav-toggled .main-nav li:hover > .sub-menu li:hover > .sub-menu,
.nav-toggled:not(.compact):not(.mobile)
	.main-nav
	li:hover
	> .sub-menu
	li:hover
	> .sub-menu {
	display: block;
}
.mobile .main-nav ul.sub-menu > li .nav-label {
	display: block !important;
}
.mobile .main-nav .collapse,
.mobile .main-nav .collapsing,
.mobile .main-nav .in {
	height: auto !important;
}
.nav-filter {
	cursor: pointer;
}
/* ===== Layout No Nav ===== */
.layout-no-leftnav {
	background: #f9f9fb;
}
.layout-no-leftnav .header {
	background: none;
	box-shadow: none;
	height: auto;
}
/* ===== Footer ===== */
.site-footer {
	background: var(--grayscale---basic--white);
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 50px;
	margin-left: 180px;
	padding: 15px;
	border-top: 1px solid #f3f3f3;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.site-footer .copyright {
	color: #a2a6af;
}
/* ==== 404 and 500 error */
.jumbotron.error-404 .action {
	margin-top: 60px;
}
.jumbotron.error-500 .action {
	margin-top: 60px;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	/* .container-fluid {
		padding: 15px;
	} */
	.content-wrapper {
		padding-top: 15px;
		border-left: 0;
	}
	.content-wrapper .view-title {
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 260px;
		font-size: 16px;
	}
	.header .main-nav-toggle {
		margin-left: 5px;
	}
	.header .utilities-container .item {
		margin-right: 15px;
	}
	.header .utilities-container .item-messages .dropdown-menu {
		width: 280px;
	}
	.header .utilities-container .item-notifications .dropdown-menu {
		width: 280px;
	}
	.header .search-container {
		position: static;
	}
	.header .search-container .icon.fa-search {
		position: relative;
	}
	.header .search-container .icon.clear-search {
		right: 15px;
		top: 15px;
		color: #494d55;
	}
	.header .search-container .main-search-input-wrapper {
		display: none;
		position: absolute;
		left: 0px;
		top: 80px;
		width: 100%;
	}
	.header .search-container .main-search-input {
		border-color: #f0f0f0;
		background: #f0f0f0;
		margin-top: 0;
		padding: 15px;
		height: 50px;
		width: 100%;
	}
	.header .search-container .main-search-input:focus {
		background: #e8e8e8;
		border-color: #e8e8e8;
	}
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.js-cloak {
	display: none !important;
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ======= Side Panel ======== */

.side-panel {
	position: fixed;
	right: -320px;
	width: 320px;
	/*top: 0;*/
	top: 80px;
	height: 100%;
	min-height: 100%;
	background: var(--grayscale---basic--white);
	z-index: 1;
	will-change: transform;
	transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.side-panel.side-panel-open {
	transform: translateX(-320px);
}
.side-panel .side-panel-inner {
	position: relative;
	padding: 15px;
}
.side-panel .close {
	position: absolute;
	right: 15px;
	top: 10px;
	font-size: 28px;
	font-weight: 400;
	margin: 0;
	text-shadow: none;
}
.side-panel .title {
	font-size: 18px;
	margin-top: 15px;
	margin-bottom: 0;
	padding-bottom: 15px;
	border-bottom: 1px solid #f3f3f3;
}
.side-panel .title .fa {
	display: inline-block;
	color: #a2a6af;
	margin-right: 8px;
}
.side-panel .item {
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #f3f3f3;
	min-height: 50px;
}
.side-panel .time-stamp {
	margin-top: 5px;
	color: #a2a6af;
	font-size: 12px;
}
.side-panel .symbol-holder {
	position: absolute;
	left: 0;
	top: 15px;
	width: 36px;
}
.side-panel .user-profile {
	width: 36px;
	height: 36px;
}
.side-panel .content-holder {
	margin-left: 46px;
}
.side-panel .subject-line {
	color: #616670;
}
.side-panel .name {
	font-weight: bold;
	color: #494d55;
}
.side-panel .excerpt {
	color: #a2a6af;
}
/* Extra small devices (phones, less than 768px) */
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
.module {
	border: 1px solid #f3f3f3;
	border-bottom-width: 2px;
	background: var(--grayscale---basic--white);
	margin-bottom: 30px;
	position: relative;
	border-radius: 4px;
	background-clip: padding-box;
}
.module.module-no-heading .module-content-inner {
	padding-top: 0;
}
.module.module-has-footer .module-inner {
	padding-bottom: 0;
}
.module .module-inner {
	padding: 30px;
}
.module .module-content-has-chart {
	padding-bottom: 30px;
}
.module .collapse-module > .icon {
	transition: all ease 0.2s;
	display: inline-block;
}
.module.collapsed .collapse-module > .icon {
	transform: rotateZ(180deg);
}
.module .module-heading {
	border-bottom: 1px solid #f3f3f7;
	padding-bottom: 15px;
	position: relative;
}
.module .module-heading .module-title {
	font-size: 14px;
	font-weight: 600;
	margin: 0;
}
.module .module-heading .actions {
	position: absolute;
	right: 0;
	top: 0;
	line-height: 1;
}
.module .module-heading .actions .more-link .dropdown-menu {
	margin-top: 10px;
	min-width: 135px;
}
.module .module-heading .actions .more-link .dropdown-menu > li > a {
	padding: 5px 10px;
	color: #494d55;
}
.module .module-heading .actions .more-link .dropdown-menu .divider {
	margin: 4px 0;
}
.module .module-heading .actions .more-link .arrow {
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #dadae7;
	position: absolute;
	left: 15px;
	top: -8px;
}
.module .module-heading .actions .more-link .arrow:before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid var(--grayscale---basic--white);
	position: absolute;
	right: -8px;
	top: 1px;
}
.module .module-heading .actions li {
	vertical-align: middle;
}
.module .module-heading .actions li a {
	color: #9499a3;
}
.module .module-heading .actions li a:hover {
	color: #616670;
	text-decoration: none;
}
.module .module-heading .actions li .icon {
	font-size: 18px;
}
.module .module-content-header .title {
	margin-top: 0;
	margin-bottom: 0;
}
.module .module-content-inner {
	padding-top: 30px;
	padding-bottom: 30px;
}
.module .module-content h4.has-divider {
	border-bottom: 1px dotted #f3f3f7;
	padding-bottom: 5px;
	margin-bottom: 15px;
}
.module .module-content .dropdown-menu li {
	margin-bottom: 0;
}
.module .module-content .dropdown-menu > li > a {
	padding: 5px 20px;
}
.module .module-content .list li {
	margin-bottom: 10px;
}
.module .module-content .list ul,
.module .module-content .list ol {
	margin-top: 10px;
}
.module .module-content dl dd {
	margin-bottom: 10px;
}
.module .module-content blockquote {
	font-size: 14px;
}
.module .module-content .module-content-inner .custom-quote {
	border-color: #b1e3e5;
}
.module .module-content .module-content-inner .custom-quote p {
	font-size: 14px;
	color: #616670;
	line-height: 1.5;
}
.module .module-content .module-content-inner .custom-quote .fa {
	color: #40babd;
	margin-right: 10px;
}
.theme-2 .module .module-content .module-content-inner .custom-quote .fa {
	color: #6dbd63;
}
.theme-3 .module .module-content .module-content-inner .custom-quote .fa {
	color: #497cb1;
}
.theme-4 .module .module-content .module-content-inner .custom-quote .fa {
	color: #ec6952;
}
.theme-2 .module .module-content .module-content-inner .custom-quote {
	border-color: #d2eacf;
}
.theme-3 .module .module-content .module-content-inner .custom-quote {
	border-color: #b3c9e0;
}
.theme-4 .module .module-content .module-content-inner .custom-quote {
	border-color: #fbe1dc;
}
.module .module-content .module-content-inner .custom-quote .people {
	margin-top: 10px;
	font-size: 12px;
	color: #494d55;
	font-style: normal;
}
.module .module-content .module-content-inner .custom-quote .people .name {
	color: #339597;
}
.theme-2
	.module
	.module-content
	.module-content-inner
	.custom-quote
	.people
	.name {
	color: #51a746;
}
.theme-3
	.module
	.module-content
	.module-content-inner
	.custom-quote
	.people
	.name {
	color: #3a638d;
}
.theme-4
	.module
	.module-content
	.module-content-inner
	.custom-quote
	.people
	.name {
	color: #e74124;
}
.module .module-footer {
	background: var(--grayscale---basic--white);
	border-top: 1px solid #f3f3f7;
	padding: 15px;
}
.module .module-footer a {
	color: #9499a3;
}
.module .module-footer a:hover {
	color: #40babd;
}
.theme-2 .module .module-footer a:hover {
	color: #6dbd63;
}
.theme-3 .module .module-footer a:hover {
	color: #497cb1;
}
.theme-4 .module .module-footer a:hover {
	color: #ec6952;
}
.module .module-footer .meta {
	margin-bottom: 0;
	color: #9499a3;
	font-size: 12px;
}
.module .module-footer .shortcuts {
	margin-bottom: 0;
}
.module .module-footer .shortcuts li {
	position: relative;
	margin-right: 10px;
}
.module .module-footer .shortcuts li:before {
	position: absolute;
	background-color: #eaeaf1;
	border-radius: 50%;
	content: "";
	line-height: 1;
	width: 6px;
	height: 6px;
	top: 0.5em;
	left: -8px;
}
.module .module-footer .shortcuts li.first:before {
	display: none;
}
.module .close-module,
.module .collapse-module {
	cursor: pointer;
}
.module-placeholder {
	opacity: 0.5;
	border-style: dashed;
	border-width: 2px;
}
.module-draggable .module-heading {
	cursor: move;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.module .module-inner {
		padding: 30px 15px;
	}
	.module .module-heading {
		padding-bottom: 15px;
	}
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ======= Typography ========= */
p {
	margin-bottom: 15px;
	line-height: 1.5;
}
a {
	color: #3aa7aa;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.theme-2 a {
	color: #5cb551;
}
.theme-3 a {
	color: #426f9f;
}
.theme-4 a {
	color: #e9553b;
}
a:hover {
	text-decoration: underline;
	color: #339597;
}
.theme-2 a:hover {
	color: #51a746;
}
.theme-3 a:hover {
	color: #3a638d;
}
.theme-4 a:hover {
	color: #e74124;
}
a:active {
	text-decoration: none;
}
a:focus {
	text-decoration: none;
}
h1,
.h1 {
	font-size: 28px;
}
h2,
.h2 {
	font-size: 24px;
}
h3,
.h3 {
	font-size: 18px;
}
h4,
.h4 {
	font-size: 16px;
}
h5,
.h5 {
	font-size: 14px;
}
h6,
.h6 {
	font-size: 13px;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
	color: #616670;
}
.list-custom-style .fa {
	margin-right: 5px;
}
.list-custom-style li {
	list-style: none;
}
.list-inline .fa {
	margin-right: 5px;
}
.text-muted {
	color: #797f8b;
}
.text-primary {
	color: #494d55;
}
.text-theme {
	color: #40babd;
}
.theme-2 .text-theme {
	color: #6dbd63;
}
.theme-3 .text-theme {
	color: #497cb1;
}
.theme-4 .text-theme {
	color: #ec6952;
}
.text-success {
	color: #75c181;
}
.text-info {
	color: #58bbee;
}
.text-warning {
	color: #f8a13f;
}
.text-danger {
	color: #f77b6b;
}
.text-pink {
	color: #ea5395;
}
.text-purple {
	color: #8a40a7;
}
.text-yellow {
	color: #f2b542;
}
.bg-primary {
	background-color: #494d55;
	color: rgba(255, 255, 255, 0.8);
}
.bg-success {
	background-color: #cde9d1;
	color: rgba(0, 0, 0, 0.8);
}
.bg-info {
	background-color: #cceafa;
	color: rgba(0, 0, 0, 0.8);
}
.bg-warning {
	background-color: #fcddba;
	color: rgba(0, 0, 0, 0.8);
}
.bg-danger {
	background-color: #fee7e4;
	color: rgba(0, 0, 0, 0.8);
}
.bg-theme {
	background-color: #40babd;
	color: var(--grayscale---basic--white);
}
.theme-2 .bg-theme {
	background-color: #6dbd63;
}
.theme-3 .bg-theme {
	background-color: #497cb1;
}
.theme-4 .bg-theme {
	background-color: #ec6952;
}
.bg-pink {
	background-color: #ea5395;
	color: var(--grayscale---basic--white);
}
.bg-purple {
	background-color: #8a40a7;
	color: var(--grayscale---basic--white);
}
.bg-yellow {
	background-color: #f2b542;
	color: var(--grayscale---basic--white);
}
.highlight-border {
	border-color: #40babd;
}
.theme-2 .highlight-border {
	border-color: #6dbd63;
}
.theme-3 .highlight-border {
	border-color: #497cb1;
}
.theme-4 .highlight-border {
	border-color: #ec6952;
}
.well {
	background-color: #f9f9fb;
	border: 1px solid #eaeaf1;
}
/* ======= Buttons ====== */

.ga-btn {
	color: var(--secondary-color);
	font-weight: bold !important;
	border: 1px solid var(--secondary-color);
	background-color: transparent;
	background-repeat: no-repeat;
	box-sizing: border-box;
	border-radius: 4px;
}

.ga-btn:hover {
	color: var(--grayscale---basic--white);
	font-weight: bold !important;
	border: 1px solid var(--secondary-color);
	background: var(--secondary-color);
	box-sizing: border-box;
	border-radius: 4px;
}

.ga-btn:disabled {
	color: #9e9e9e;
	border: 1px solid #9e9e9e;
	font-weight: bold !important;
	background-color: transparent;
	background-repeat: no-repeat;
	box-sizing: border-box;
	border-radius: 4px;
}

.ga-btn-cancel {
	color: var(--grayscale---gray-600);
	border: 1px solid var(--grayscale---gray-400);
	box-sizing: border-box;
	border-radius: 4px;
	background-color: transparent;
	background-repeat: no-repeat;
}

.ga-btn-cancel:hover {
	color: var(--success---success-500);
	border: 1px solid var(--success---success-500);
	box-sizing: border-box;
	border-radius: 4px;
	background-color: transparent;
	background-repeat: no-repeat;
}

.btn,
a.btn {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	font-weight: 400;
	font-size: 13px;
	line-height: 1.5;
}
.btn .fa,
a.btn .fa {
	margin-right: 5px;
}
.btn-oval {
	border-radius: 60px;
	background-clip: padding-box;
	border: none;
}
.btn-square {
	border-radius: 0;
	background-clip: padding-box;
}
.btn-circle {
	border-radius: 50%;
	background-clip: padding-box;
	border: none;
	width: 36px;
	height: 36px;
	text-align: center;
	padding: 0;
}
.btn-circle .fa {
	margin-right: 0;
	font-size: 14px;
}
.btn-circle .pe-icon {
	font-size: 18px;
	padding-top: 3px;
	font-weight: bold;
}
.btn-circle .fs1 {
	font-size: 16px;
	padding-top: 3px;
	font-weight: bold;
}
.btn-group-lg > .btn,
.btn-lg {
	padding: 8px 16px;
}
.btn-group-sm > .btn,
.btn-sm {
	padding: 4px 8px;
}
.btn-group-xs > .btn,
.btn-xs {
	padding: 2px 4px;
}
.btn-lg {
	font-size: 16px;
}
.btn-sm {
	font-size: 13px;
}
.btn-xs {
	font-size: 10px;
}
.btn-default {
	background: unset;
	border: 1px solid var(--grayscale---gray-400);
	color: var(--grayscale---gray-400);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default.hover {
	border-color: #e6e7ea;
	color: #6c727e;
	background: unset;
	box-shadow: none;
}
.btn-default-ga:active:focus {
	border-color: var(--hover1);
	color: var(--hover1);
	background: unset;
	box-shadow: none;
	outline: unset;
}
.theme-2 .btn-default {
	color: #a2a6af;
}
.theme-2 .btn-default:hover,
.theme-2 .btn-default:focus,
.theme-2 .btn-default:active,
.theme-2 .btn-default.active,
.theme-2 .btn-default.focus,
.theme-2 .btn-default.hover {
	color: #6c727e;
}
.theme-3 .btn-default {
	color: #a2a6af;
}
.theme-3 .btn-default:hover,
.theme-3 .btn-default:focus,
.theme-3 .btn-default:active,
.theme-3 .btn-default.active,
.theme-3 .btn-default.focus,
.theme-3 .btn-default.hover {
	color: #6c727e;
}
.theme-4 .btn-default {
	color: #a2a6af;
}
.theme-4 .btn-default:hover,
.theme-4 .btn-default:focus,
.theme-4 .btn-default:active,
.theme-4 .btn-default.active,
.theme-4 .btn-default.focus,
.theme-4 .btn-default.hover {
	color: #6c727e;
}
.open > .dropdown-toggle.btn-default {
	border-color: #e6e7ea;
}
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:active,
.open > .dropdown-toggle.btn-default:hover {
	border-color: #e6e7ea;
	background: #f9f9fb;
	box-shadow: none;
}
.btn-reset {
	background: var(--grayscale---gray-100);
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
}
.btn-reset:hover {
	background: var(--grayscale---gray-200);
	border: 1px solid var(--brand-colors---brand-700);
	color: var(--brand-colors---brand-700);
}
.btn-primary {
	background: var(--grayscale---gray-200);
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
}
.theme-2 .btn-primary {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .btn-primary {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .btn-primary {
	background: #ec6952;
	border-color: #ec6952;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.hover,
.btn-primary.active:hover {
	background: var(--grayscale---gray-400);
	color: var(--brand-colors---brand-700);
	border: 1px solid var(--brand-colors---brand-700);
}
.theme-2 .btn-primary:hover,
.theme-2 .btn-primary:focus,
.theme-2 .btn-primary:active,
.theme-2 .btn-primary.active,
.theme-2 .btn-primary.hover,
.theme-2 .btn-primary.active:hover {
	background: #5cb551;
	border-color: #5cb551;
}
.theme-3 .btn-primary:hover,
.theme-3 .btn-primary:focus,
.theme-3 .btn-primary:active,
.theme-3 .btn-primary.active,
.theme-3 .btn-primary.hover,
.theme-3 .btn-primary.active:hover {
	background: #426f9f;
	border-color: #426f9f;
}
.theme-4 .btn-primary:hover,
.theme-4 .btn-primary:focus,
.theme-4 .btn-primary:active,
.theme-4 .btn-primary.active,
.theme-4 .btn-primary.hover,
.theme-4 .btn-primary.active:hover {
	background: #e9553b;
	border-color: #e9553b;
}
.btn-success {
	background: #75c181;
	border: 1px solid #75c181;
	color: var(--grayscale---basic--white);
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.hover {
	background: #63b971;
	border: 1px solid #63b971;
	color: var(--grayscale---basic--white);
}
.btn-info {
	background: #58bbee;
	border: 1px solid #58bbee;
	color: var(--grayscale---basic--white);
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.hover {
	background: #41b2ec;
	-ms-flex-order: 1px solid #41b2ec;
	order: 1px solid #41b2ec;
	color: var(--grayscale---basic--white);
}
.btn-warning {
	background: #f8a13f;
	border: 1px solid #f8a13f;
	color: var(--grayscale---basic--white);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.hover {
	background: #f79526;
	border: 1px solid #f79526;
	color: var(--grayscale---basic--white);
}
.btn-danger {
	background: #f77b6b;
	border: 1px solid #f77b6b;
	color: var(--grayscale---basic--white);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.hover {
	background: #f66553;
	border: 1px solid #f66553;
	color: var(--grayscale---basic--white);
}
.btn-pink {
	background: #ea5395;
	border: 1px solid #ea5395;
	color: var(--grayscale---basic--white);
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.hover {
	background: #e73c87;
	border: 1px solid #e73c87;
	color: var(--grayscale---basic--white);
}
.btn-purple {
	background: #8a40a7;
	border: 1px solid #8a40a7;
	color: var(--grayscale---basic--white);
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.btn-purple.hover {
	background: #7b3995;
	border: 1px solid #7b3995;
	color: var(--grayscale---basic--white);
}
.btn-yellow {
	background: #f2b542;
	border: 1px solid #f2b542;
	color: var(--grayscale---basic--white);
}
.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active,
.btn-yellow.hover {
	background: #f0ac2a;
	border: 1px solid #f0ac2a;
	color: var(--grayscale---basic--white);
}
.btn-link {
	color: #40babd;
}
.theme-2 .btn-link {
	color: #6dbd63;
}
.theme-3 .btn-link {
	color: #497cb1;
}
.theme-4 .btn-link {
	color: #ec6952;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.btn-link.hover {
	color: #339597;
}
.theme-2 .btn-link:hover,
.theme-2 .btn-link:focus,
.theme-2 .btn-link:active,
.theme-2 .btn-link.active,
.theme-2 .btn-link.hover {
	color: #51a746;
}
.theme-3 .btn-link:hover,
.theme-3 .btn-link:focus,
.theme-3 .btn-link:active,
.theme-3 .btn-link.active,
.theme-3 .btn-link.hover {
	color: #3a638d;
}
.theme-4 .btn-link:hover,
.theme-4 .btn-link:focus,
.theme-4 .btn-link:active,
.theme-4 .btn-link.active,
.theme-4 .btn-link.hover {
	color: #e74124;
}
.btn-default-alt {
	background: #616670;
	border: 1px solid #616670;
	color: var(--grayscale---basic--white);
}
.btn-default-alt:hover,
.btn-default-alt:focus,
.btn-default-alt:active,
.btn-default-alt.active,
.btn-default-alt.hover {
	background: #494d55;
	color: var(--grayscale---basic--white);
	border: 1px solid #494d55;
}
.btn-primary-alt {
	background: none;
	border: 1px solid #40babd;
	color: #40babd;
}
.theme-2 .btn-primary-alt {
	color: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .btn-primary-alt {
	color: #497cb1;
	border-color: #497cb1;
}
.theme-4 .btn-primary-alt {
	color: #ec6952;
	border-color: #ec6952;
}
.btn-primary-alt:hover,
.btn-primary-alt:focus,
.btn-primary-alt:active,
.btn-primary-alt.active,
.btn-primary-alt.hover {
	border-color: #40babd;
	background: #40babd;
	color: var(--grayscale---basic--white);
}
.theme-2 .btn-primary-alt:hover,
.theme-2 .btn-primary-alt:focus,
.theme-2 .btn-primary-alt:active,
.theme-2 .btn-primary-alt.active,
.theme-2 .btn-primary-alt.hover {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .btn-primary-alt:hover,
.theme-3 .btn-primary-alt:focus,
.theme-3 .btn-primary-alt:active,
.theme-3 .btn-primary-alt.active,
.theme-3 .btn-primary-alt.hover {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .btn-primary-alt:hover,
.theme-4 .btn-primary-alt:focus,
.theme-4 .btn-primary-alt:active,
.theme-4 .btn-primary-alt.active,
.theme-4 .btn-primary-alt.hover {
	background: #ec6952;
	border-color: #ec6952;
}
.btn-success-alt {
	background: none;
	border: 1px solid #75c181;
	color: #75c181;
}
.btn-success-alt:hover,
.btn-success-alt:focus,
.btn-success-alt.active,
.btn-success-alt.hover {
	border-color: #75c181;
	background: #75c181;
	color: var(--grayscale---basic--white);
}
.btn-info-alt {
	background: none;
	border: 1px solid #58bbee;
	color: #58bbee;
}
.btn-info-alt:hover,
.btn-info-alt:focus,
.btn-info-alt.active,
.btn-info-alt.hover {
	border-color: #58bbee;
	background: #58bbee;
	color: var(--grayscale---basic--white);
}
.btn-warning-alt {
	background: none;
	border: 1px solid #f8a13f;
	color: #f8a13f;
}
.btn-warning-alt:hover,
.btn-warning-alt:focus,
.btn-warning-alt.active,
.btn-warning-alt.hover {
	border-color: #f8a13f;
	background: #f8a13f;
	color: var(--grayscale---basic--white);
}
.btn-danger-alt {
	background: none;
	border: 1px solid #f77b6b;
	color: #f77b6b;
}
.btn-danger-alt:hover,
.btn-danger-alt:focus,
.btn-danger-alt.active,
.btn-danger-alt.hover {
	border-color: #f77b6b;
	background: #f77b6b;
	color: var(--grayscale---basic--white);
}
.btn-pink-alt {
	background: none;
	border: 1px solid #ea5395;
	color: #ea5395;
}
.btn-pink-alt:hover,
.btn-pink-alt:focus,
.btn-pink-alt.active,
.btn-pink-alt.hover {
	border-color: #ea5395;
	background: #ea5395;
	color: var(--grayscale---basic--white);
}
.btn-purple-alt {
	background: none;
	border: 1px solid #8a40a7;
	color: #8a40a7;
}
.btn-purple-alt:hover,
.btn-purple-alt:focus,
.btn-purple-alt.active,
.btn-purple-alt.hover {
	border-color: #8a40a7;
	background: #8a40a7;
	color: var(--grayscale---basic--white);
}
.btn-yellow-alt {
	background: none;
	border: 1px solid #f2b542;
	color: #f2b542;
}
.btn-yellow-alt:hover,
.btn-yellow-alt:focus,
.btn-yellow-alt.active,
.btn-yellow-alt.hover {
	border-color: #f2b542;
	background: #f2b542;
	color: var(--grayscale---basic--white);
}
.open > .dropdown-toggle.btn-default-alt {
	background: #494d55;
	border-color: #494d55;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.open > .dropdown-toggle.btn-primary {
	background: #3aa7aa;
	border-color: #3aa7aa;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.theme-2 .open > .dropdown-toggle.btn-primary {
	background: #5cb551;
	border-color: #5cb551;
}
.theme-3 .open > .dropdown-toggle.btn-primary {
	background: #426f9f;
	border-color: #426f9f;
}
.theme-4 .open > .dropdown-toggle.btn-primary {
	background: #e9553b;
	border-color: #e9553b;
}
.open > .dropdown-toggle.btn-success {
	background: #63b971;
	border-color: #63b971;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.open > .dropdown-toggle.btn-info {
	background: #41b2ec;
	border-color: #41b2ec;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.open > .dropdown-toggle.btn-warning {
	background: #f79526;
	border-color: #f79526;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.open > .dropdown-toggle.btn-danger {
	background: #f66553;
	border-color: #f66553;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	color: var(--grayscale---basic--white);
}
.btn-default-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #616670;
	box-shadow: 0px 4px 0px #494d55;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-default-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #555963;
	background: #6c727e;
	color: var(--grayscale---basic--white);
}
.btn-default-3d:focus {
	background: #797f8b;
	color: var(--grayscale---basic--white);
}
.btn-default-3d:active {
	box-shadow: 0px 2px 0px #555963;
	color: var(--grayscale---basic--white);
}
.btn-primary-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #40babd;
	box-shadow: 0px 4px 0px #339597;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.theme-2 .btn-primary-3d {
	background: #6dbd63;
	box-shadow: 0px 4px 0px #51a746;
}
.theme-3 .btn-primary-3d {
	background: #497cb1;
	box-shadow: 0px 4px 0px #3a638d;
}
.theme-4 .btn-primary-3d {
	background: #ec6952;
	box-shadow: 0px 4px 0px #e74124;
}
.btn-primary-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #3aa7aa;
	background: #52c2c4;
	color: var(--grayscale---basic--white);
}
.theme-2 .btn-primary-3d:hover {
	background: #7ec575;
	box-shadow: 0px 2px 0px #5cb551;
}
.theme-3 .btn-primary-3d:hover {
	background: #5989bb;
	box-shadow: 0px 2px 0px #426f9f;
}
.theme-4 .btn-primary-3d:hover {
	background: #ef7d69;
	box-shadow: 0px 2px 0px #e9553b;
}
.btn-primary-3d:focus {
	background: #65c8cb;
	color: var(--grayscale---basic--white);
}
.theme-2 .btn-primary-3d:focus {
	background: #8fcc87;
}
.theme-3 .btn-primary-3d:focus {
	background: #6b96c2;
}
.theme-4 .btn-primary-3d:focus {
	background: #f19180;
}
.btn-primary-3d:active {
	box-shadow: 0px 2px 0px #3aa7aa;
	color: var(--grayscale---basic--white);
}
.theme-2 .btn-primary-3d:active {
	box-shadow: 0px 2px 0px #5cb551;
}
.theme-3 .btn-primary-3d:active {
	box-shadow: 0px 2px 0px #426f9f;
}
.theme-4 .btn-primary-3d:active {
	box-shadow: 0px 2px 0px #e9553b;
}
.btn-success-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #75c181;
	box-shadow: 0px 4px 0px #52b161;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-success-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #63b971;
	background: #87c991;
	color: var(--grayscale---basic--white);
}
.btn-success-3d:focus {
	background: #98d1a1;
	color: var(--grayscale---basic--white);
}
.btn-success-3d:active {
	box-shadow: 0px 2px 0px #63b971;
	color: var(--grayscale---basic--white);
}
.btn-info-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #58bbee;
	box-shadow: 0px 4px 0px #2aa8e9;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-info-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #41b2ec;
	background: #6fc4f0;
	color: var(--grayscale---basic--white);
}
.btn-info-3d:focus {
	background: #86cef3;
	color: var(--grayscale---basic--white);
}
.btn-info-3d:active {
	box-shadow: 0px 2px 0px #41b2ec;
	color: var(--grayscale---basic--white);
}
.btn-warning-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #f8a13f;
	box-shadow: 0px 4px 0px #f6890e;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-warning-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #f79526;
	background: #f9ad58;
	color: var(--grayscale---basic--white);
}
.btn-warning-3d:focus {
	background: #fab970;
	color: var(--grayscale---basic--white);
}
.btn-warning-3d:active {
	box-shadow: 0px 2px 0px #f79526;
	color: var(--grayscale---basic--white);
}
.btn-danger-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #f77b6b;
	box-shadow: 0px 4px 0px #f4503b;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-danger-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #f66553;
	background: #f89183;
	color: var(--grayscale---basic--white);
}
.btn-danger-3d:focus {
	background: #faa69b;
	color: var(--grayscale---basic--white);
}
.btn-danger-3d:active {
	box-shadow: 0px 2px 0px #f66553;
	color: var(--grayscale---basic--white);
}
.btn-pink-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #ea5395;
	box-shadow: 0px 4px 0px #e42679;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-pink-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #e73c87;
	background: #ed6aa3;
	color: var(--grayscale---basic--white);
}
.btn-pink-3d:focus {
	background: #f080b1;
	color: var(--grayscale---basic--white);
}
.btn-pink-3d:active {
	box-shadow: 0px 2px 0px #e73c87;
	color: var(--grayscale---basic--white);
}
.btn-purple-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #8a40a7;
	box-shadow: 0px 4px 0px #6c3282;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-purple-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #7b3995;
	background: #9948b9;
	color: var(--grayscale---basic--white);
}
.btn-purple-3d:focus {
	background: #a35ac0;
	color: var(--grayscale---basic--white);
}
.btn-purple-3d:active {
	box-shadow: 0px 2px 0px #7b3995;
	color: var(--grayscale---basic--white);
}
.btn-yellow-3d {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	position: relative;
	background: #f2b542;
	box-shadow: 0px 4px 0px #efa212;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	color: var(--grayscale---basic--white);
	font-weight: bold;
}
.btn-yellow-3d:hover {
	top: 2px;
	box-shadow: 0px 2px 0px #f0ac2a;
	background: #f4be5a;
	color: var(--grayscale---basic--white);
}
.btn-yellow-3d:focus {
	background: #f5c872;
	color: var(--grayscale---basic--white);
}
.btn-yellow-3d:active {
	box-shadow: 0px 2px 0px #f0ac2a;
	color: var(--grayscale---basic--white);
}
.btn-single-icon {
	width: 36px;
	height: 36px;
	text-align: center;
	padding: 0;
	padding-top: 3px;
}
.btn-single-icon .fa {
	font-size: 16px;
	margin-right: 0;
}
.social-btn {
	color: var(--grayscale---basic--white);
	min-width: 220px;
	text-align: left;
}
.social-btn:hover,
.social-btn:focus {
	color: var(--grayscale---basic--white);
}
.social-btn .fa {
	font-size: 18px;
	position: relative;
	top: 2px;
	margin-right: 10px;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	padding-right: 10px;
	width: 30px;
}
.twitter-btn {
	background: #55acee;
}
.twitter-btn:hover {
	background: #2795e9;
}
.facebook-btn {
	background: #3b5998;
}
.facebook-btn:hover {
	background: #2d4373;
}
.google-btn {
	background: #dd4b39;
}
.google-btn:hover {
	background: #c23321;
}
.github-btn {
	background: #444;
}
.github-btn:hover {
	background: #2b2b2b;
}
.btn-group.open .dropdown-toggle {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
}
.btn-group.open .dropdown-toggle.btn-default {
	box-shadow: none;
}

.btn-group.open .dropdown-toggle {
    box-shadow: unset;
}

.btn-group > .btn + .dropdown-toggle.btn-default-alt {
	background: #555963;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-default-alt:hover {
	background: #494d55;
}
.btn-group > .btn + .dropdown-toggle.btn-primary {
	background: #3aa7aa;
	color: var(--grayscale---basic--white);
}
.theme-2 .btn-group > .btn + .dropdown-toggle.btn-primary {
	background: #5cb551;
}
.theme-3 .btn-group > .btn + .dropdown-toggle.btn-primary {
	background: #426f9f;
}
.theme-4 .btn-group > .btn + .dropdown-toggle.btn-primary {
	background: #e9553b;
}
.btn-group > .btn + .dropdown-toggle.btn-primary:hover {
	background: #339597;
}
.theme-2 .btn-group > .btn + .dropdown-toggle.btn-primary:hover {
	background: #51a746;
}
.theme-3 .btn-group > .btn + .dropdown-toggle.btn-primary:hover {
	background: #3a638d;
}
.theme-4 .btn-group > .btn + .dropdown-toggle.btn-primary:hover {
	background: #e74124;
}
.btn-group > .btn + .dropdown-toggle.btn-success {
	background: #63b971;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-success:hover {
	background: #52b161;
}
.btn-group > .btn + .dropdown-toggle.btn-info {
	background: #41b2ec;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-info:hover {
	background: #2aa8e9;
}
.btn-group > .btn + .dropdown-toggle.btn-warning {
	background: #f79526;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-warning:hover {
	background: #f6890e;
}
.btn-group > .btn + .dropdown-toggle.btn-danger {
	background: #f66553;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-danger:hover {
	background: #f4503b;
}
.btn-group > .btn + .dropdown-toggle.btn-pink {
	background: #e73c87;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-pink:hover {
	background: #e42679;
}
.btn-group > .btn + .dropdown-toggle.btn-purple {
	background: #7b3995;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-purple:hover {
	background: #6c3282;
}
.btn-group > .btn + .dropdown-toggle.btn-yellow {
	background: #f0ac2a;
	color: var(--grayscale---basic--white);
}
.btn-group > .btn + .dropdown-toggle.btn-yellow:hover {
	background: #efa212;
}
.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
	background: #f9f9fb;
	color: #D4D4D4;
	border: 1px solid #D4D4D4;
}
.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
	background: #40babd;
	border: 1px solid #40babd;
}
.theme-2 .btn-primary.disabled,
.theme-2 .btn-primary.disabled.active,
.theme-2 .btn-primary.disabled.focus,
.theme-2 .btn-primary.disabled:active,
.theme-2 .btn-primary.disabled:focus,
.theme-2 .btn-primary.disabled:hover,
.theme-2 .btn-primary[disabled],
.theme-2 .btn-primary[disabled].active,
.theme-2 .btn-primary[disabled].focus,
.theme-2 .btn-primary[disabled]:active,
.theme-2 .btn-primary[disabled]:focus,
.theme-2 .btn-primary[disabled]:hover,
.theme-2 fieldset[disabled] .btn-primary,
.theme-2 fieldset[disabled] .btn-primary.active,
.theme-2 fieldset[disabled] .btn-primary.focus,
.theme-2 fieldset[disabled] .btn-primary:active,
.theme-2 fieldset[disabled] .btn-primary:focus,
.theme-2 fieldset[disabled] .btn-primary:hover {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .btn-primary.disabled,
.theme-3 .btn-primary.disabled.active,
.theme-3 .btn-primary.disabled.focus,
.theme-3 .btn-primary.disabled:active,
.theme-3 .btn-primary.disabled:focus,
.theme-3 .btn-primary.disabled:hover,
.theme-3 .btn-primary[disabled],
.theme-3 .btn-primary[disabled].active,
.theme-3 .btn-primary[disabled].focus,
.theme-3 .btn-primary[disabled]:active,
.theme-3 .btn-primary[disabled]:focus,
.theme-3 .btn-primary[disabled]:hover,
.theme-3 fieldset[disabled] .btn-primary,
.theme-3 fieldset[disabled] .btn-primary.active,
.theme-3 fieldset[disabled] .btn-primary.focus,
.theme-3 fieldset[disabled] .btn-primary:active,
.theme-3 fieldset[disabled] .btn-primary:focus,
.theme-3 fieldset[disabled] .btn-primary:hover {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .btn-primary.disabled,
.theme-4 .btn-primary.disabled.active,
.theme-4 .btn-primary.disabled.focus,
.theme-4 .btn-primary.disabled:active,
.theme-4 .btn-primary.disabled:focus,
.theme-4 .btn-primary.disabled:hover,
.theme-4 .btn-primary[disabled],
.theme-4 .btn-primary[disabled].active,
.theme-4 .btn-primary[disabled].focus,
.theme-4 .btn-primary[disabled]:active,
.theme-4 .btn-primary[disabled]:focus,
.theme-4 .btn-primary[disabled]:hover,
.theme-4 fieldset[disabled] .btn-primary,
.theme-4 fieldset[disabled] .btn-primary.active,
.theme-4 fieldset[disabled] .btn-primary.focus,
.theme-4 fieldset[disabled] .btn-primary:active,
.theme-4 fieldset[disabled] .btn-primary:focus,
.theme-4 fieldset[disabled] .btn-primary:hover {
	background: #ec6952;
	border-color: #ec6952;
}
.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
	background: #75c181;
	border: 1px solid #75c181;
}
.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
	background: #58bbee;
	border: 1px solid #58bbee;
}
.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
	background: #f8a13f;
	border: 1px solid #f8a13f;
}
.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
	background: #f77b6b;
	border: 1px solid #f77b6b;
}
/* ======= Panels ========= */
.panel {
	box-shadow: none;
}
.panel .panel-heading {
	padding: 15px;
}
.panel .panel-heading .panel-title {
	font-size: 14px;
	font-weight: 600;
}
.panel .panel-heading .panel-title a {
	font-weight: 600;
	color: #616670;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
}
.panel .panel-heading .panel-title a.active {
	color: #40babd;
}
.theme-2 .panel .panel-heading .panel-title a.active {
	color: #6dbd63;
}
.theme-3 .panel .panel-heading .panel-title a.active {
	color: #497cb1;
}
.theme-4 .panel .panel-heading .panel-title a.active {
	color: #ec6952;
}
.panel .panel-heading .panel-title a .fa {
	margin-right: 5px;
	color: #40babd;
}
.panel-default .panel-heading .panel-title a .fa {
	margin-right: 5px;
	color: #494d55;
}
.theme-2 .panel .panel-heading .panel-title a .fa {
	color: #6dbd63;
}
.theme-3 .panel .panel-heading .panel-title a .fa {
	color: #497cb1;
}
.theme-4 .panel .panel-heading .panel-title a .fa {
	color: #ec6952;
}
.panel .panel-heading.icon-right .panel-title {
	position: relative;
}
.panel .panel-heading.icon-right .panel-title .fa {
	position: absolute;
	right: 0;
}
.panel-default {
	border-color: #eaeaf1;
}
.panel-default > .panel-heading {
	color: #494d55;
	background: #f9f9fb;
	border-color: #eaeaf1;
}
.panel-default .panel-footer {
	background: #f9f9fb;
	color: #616670;
}
.panel-theme {
	border-color: #616670;
}
.panel-theme > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #616670;
	border-color: #616670;
}
.panel-theme .panel-footer {
	background: #616670;
	border-color: #616670;
	color: var(--grayscale---basic--white);
}
.panel-primary {
	border-color: #40babd;
}
.theme-2 .panel-primary {
	border-color: #6dbd63;
}
.theme-3 .panel-primary {
	border-color: #497cb1;
}
.theme-4 .panel-primary {
	border-color: #ec6952;
}
.panel-primary > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #40babd;
	border-color: #40babd;
}
.theme-2 .panel-primary > .panel-heading {
	border-color: #6dbd63;
	background: #6dbd63;
}
.theme-3 .panel-primary > .panel-heading {
	border-color: #497cb1;
	background: #497cb1;
}
.theme-4 .panel-primary > .panel-heading {
	border-color: #ec6952;
	background: #ec6952;
}
.panel-primary .panel-footer {
	background: #40babd;
	border-color: #40babd;
	color: var(--grayscale---basic--white);
}
.theme-2 .panel-primary .panel-footer {
	border-color: #6dbd63;
	background: #6dbd63;
}
.theme-3 .panel-primary .panel-footer {
	border-color: #497cb1;
	background: #497cb1;
}
.theme-4 .panel-primary .panel-footer {
	border-color: #ec6952;
	background: #ec6952;
}
.panel-success {
	border-color: #75c181;
}
.panel-success > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #75c181;
	border-color: #75c181;
}
.panel-success .panel-footer {
	background: #75c181;
	border-color: #75c181;
	color: var(--grayscale---basic--white);
}
.panel-info {
	border-color: #58bbee;
}
.panel-info > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #58bbee;
	border-color: #58bbee;
}
.panel-info .panel-footer {
	background: #58bbee;
	border-color: #58bbee;
	color: var(--grayscale---basic--white);
}
.panel-warning {
	border-color: #f8a13f;
}
.panel-warning > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #f8a13f;
	border-color: #f8a13f;
}
.panel-warning .panel-footer {
	background: #f8a13f;
	border-color: #f8a13f;
	color: var(--grayscale---basic--white);
}
.panel-danger {
	border-color: #f77b6b;
}
.panel-danger > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #f77b6b;
	border-color: #f77b6b;
}
.panel-danger .panel-footer {
	background: #f77b6b;
	border-color: #f77b6b;
	color: var(--grayscale---basic--white);
}
.panel-pink {
	border-color: #ea5395;
}
.panel-pink > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #ea5395;
	border-color: #ea5395;
}
.panel-pink .panel-footer {
	background: #ea5395;
	border-color: #ea5395;
	color: var(--grayscale---basic--white);
}
.panel-purple {
	border-color: #8a40a7;
}
.panel-purple > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #8a40a7;
	border-color: #8a40a7;
}
.panel-purple .panel-footer {
	background: #8a40a7;
	border-color: #8a40a7;
	color: var(--grayscale---basic--white);
}
.panel-yellow {
	border-color: #f2b542;
}
.panel-yellow > .panel-heading {
	color: var(--grayscale---basic--white);
	background: #f2b542;
	border-color: #f2b542;
}
.panel-yellow .panel-footer {
	background: #f2b542;
	border-color: #f2b542;
	color: var(--grayscale---basic--white);
}
.panel-group-theme-1 .panel {
	border: none;
}
.panel-group-theme-1 .panel .panel-heading {
	border-radius: 4px;
	background-clip: padding-box;
}
.panel-group-theme-1 .panel .panel-heading a:hover {
	text-decoration: none;
	color: #40babd;
}
.theme-2 .panel-group-theme-1 .panel .panel-heading a:hover {
	color: #6dbd63;
}
.theme-3 .panel-group-theme-1 .panel .panel-heading a:hover {
	color: #497cb1;
}
.theme-4 .panel-group-theme-1 .panel .panel-heading a:hover {
	color: #ec6952;
}
.panel-group-theme-1 .panel .panel-body {
	border: none !important;
}
/* ======= Tabs ========= */
.nav-tabs {
	border-color: #eaeaf1;
	margin-bottom: 15px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	border: 1px solid #eaeaf1;
	border-bottom-color: transparent;
	color: #494d55;
	background-color: var(--grayscale---basic--white);
}
.nav-tabs > li > a {
	color: #9499a3;
	font-weight: 600;
}
.nav-tabs > li > a:hover {
	background: #f9f9fb;
	border-color: #f9f9fb;
	color: #494d55;
}
.nav-tabs-theme-1 {
	border: none;
	display: inline-block;
	margin: 0 auto;
	margin-bottom: 15px;
	text-align: center;
	position: relative;
}
.nav-tabs-theme-1 > li {
	margin-right: 15px;
}
.nav-tabs-theme-1 > li.last {
	margin-right: 0;
}
.nav-tabs-theme-1 > li > a {
	border-radius: 0;
	background-clip: padding-box;
	color: #797f8b;
	padding: 5px;
	border: none;
	border-bottom: 2px solid transparent;
}
.nav-tabs-theme-1 > li > a:hover {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	color: #40babd;
}
.theme-2 .nav-tabs-theme-1 > li > a:hover {
	color: #6dbd63;
}
.theme-3 .nav-tabs-theme-1 > li > a:hover {
	color: #497cb1;
}
.theme-4 .nav-tabs-theme-1 > li > a:hover {
	color: #ec6952;
}
.nav-tabs-theme-1 > li.active > a {
	color: #494d55;
	background: transparent;
	border: none;
	border-bottom: 2px solid #40babd;
}
.theme-2 .nav-tabs-theme-1 > li.active > a {
	border-bottom-color: #6dbd63;
}
.theme-3 .nav-tabs-theme-1 > li.active > a {
	border-bottom-color: #497cb1;
}
.theme-4 .nav-tabs-theme-1 > li.active > a {
	border-bottom-color: #ec6952;
}
.nav-tabs-theme-1 > li.active > a:hover,
.nav-tabs-theme-1 > li.active > a:focus {
	border: none;
	border-bottom: 2px solid #40babd;
	background: transparent;
	color: #494d55;
}
.theme-2 .nav-tabs-theme-1 > li.active > a:hover,
.theme-2 .nav-tabs-theme-1 > li.active > a:focus {
	border-bottom-color: #6dbd63;
}
.theme-3 .nav-tabs-theme-1 > li.active > a:hover,
.theme-3 .nav-tabs-theme-1 > li.active > a:focus {
	border-bottom-color: #497cb1;
}
.theme-4 .nav-tabs-theme-1 > li.active > a:hover,
.theme-4 .nav-tabs-theme-1 > li.active > a:focus {
	border-bottom-color: #ec6952;
}
.nav-tabs-theme-2 {
	border-color: #eaeaf1;
	margin-bottom: 15px;
}
.nav-tabs-theme-2 > li.active > a,
.nav-tabs-theme-2 > li.active > a:focus,
.nav-tabs-theme-2 > li.active > a:hover {
	border: 1px solid #eaeaf1;
	color: #494d55;
	border-top: 2px solid #40babd;
	border-bottom: 1px solid var(--grayscale---basic--white);
}
.theme-2 .nav-tabs-theme-2 > li.active > a,
.theme-2 .nav-tabs-theme-2 > li.active > a:focus,
.theme-2 .nav-tabs-theme-2 > li.active > a:hover {
	border-top-color: #6dbd63;
}
.theme-3 .nav-tabs-theme-2 > li.active > a,
.theme-3 .nav-tabs-theme-2 > li.active > a:focus,
.theme-3 .nav-tabs-theme-2 > li.active > a:hover {
	border-top-color: #497cb1;
}
.theme-4 .nav-tabs-theme-2 > li.active > a,
.theme-4 .nav-tabs-theme-2 > li.active > a:focus,
.theme-4 .nav-tabs-theme-2 > li.active > a:hover {
	border-top-color: #ec6952;
}
.nav-tabs-theme-2 > li > a {
	color: #9499a3;
	border-radius: 0;
	background-clip: padding-box;
}
.nav-tabs-theme-2 > li > a:hover {
	background: #f9f9fb;
	border-color: #f9f9fb;
	color: #494d55;
}
.nav-tabs-theme-3 {
	border: none;
	margin: 0 auto;
	margin-bottom: 15px;
	/* OLD - iOS 6-, Safari 3.1-6 */
	/* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;
	/* TWEENER - IE 10 */
	/* NEW - Chrome */
	display: flex;
	/* NEW, Spec - Opera 12.1, Firefox 20+ */
	text-align: center;
	position: relative;
}
.nav-tabs-theme-3:before {
	content: "";
	display: block;
	border-bottom: 1px solid #eaeaf1;
	position: absolute;
	bottom: 0;
}
.nav-tabs-theme-3 > li {
	margin-bottom: -1px;
	position: relative;
	z-index: 1;
	background: var(--grayscale---basic--white);
	border: 1px solid #eaeaf1;
	border-right: none;
	-webkit-box-flex: 1;
	/* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;
	/* OLD - Firefox 19- */
	width: 20%;
	/* For old syntax, otherwise collapses. */
	/* Chrome */
	-ms-flex: 1;
	/* IE 10 */
	flex: 1;
}
.nav-tabs-theme-3 > li > a {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	border-radius: 0;
	background-clip: padding-box;
	margin-right: 0px;
	color: #9499a3;
	padding: 15px;
	background: var(--grayscale---basic--white);
	border: none;
}
.nav-tabs-theme-3 > li > a .fa,
.nav-tabs-theme-3 > li > a .fs1 {
	font-size: 20px;
}
.nav-tabs-theme-3 > li > a .pe-icon {
	font-size: 26px;
}
.nav-tabs-theme-3 > li > a:hover {
	background: #f9f9fb;
}
.nav-tabs-theme-3 > li.active {
	border-top-color: #40babd;
	border-bottom-color: var(--grayscale---basic--white);
}
.theme-2 .nav-tabs-theme-3 > li.active {
	border-top-color: #6dbd63;
}
.theme-3 .nav-tabs-theme-3 > li.active {
	border-top-color: #497cb1;
}
.theme-4 .nav-tabs-theme-3 > li.active {
	border-top-color: #ec6952;
}
.nav-tabs-theme-3 > li.active > a,
.nav-tabs-theme-3 > li.active > a:focus,
.nav-tabs-theme-3 > li.active > a:hover {
	color: #40babd;
	box-shadow: inset 0 2px 0 #40babd;
	background: var(--grayscale---basic--white);
	border: none;
}
.theme-2 .nav-tabs-theme-3 > li.active > a,
.theme-2 .nav-tabs-theme-3 > li.active > a:focus,
.theme-2 .nav-tabs-theme-3 > li.active > a:hover {
	color: #6dbd63;
	box-shadow: inset 0 2px 0 #6dbd63;
}
.theme-3 .nav-tabs-theme-3 > li.active > a,
.theme-3 .nav-tabs-theme-3 > li.active > a:focus,
.theme-3 .nav-tabs-theme-3 > li.active > a:hover {
	color: #497cb1;
	box-shadow: inset 0 2px 0 #497cb1;
}
.theme-4 .nav-tabs-theme-3 > li.active > a,
.theme-4 .nav-tabs-theme-3 > li.active > a:focus,
.theme-4 .nav-tabs-theme-3 > li.active > a:hover {
	color: #ec6952;
	box-shadow: inset 0 2px 0 #ec6952;
}
.nav-tabs-theme-3 > li.active > a:hover,
.nav-tabs-theme-3 > li.active > a:focus:hover,
.nav-tabs-theme-3 > li.active > a:hover:hover,
.nav-tabs-theme-3 > li.active > a:focus,
.nav-tabs-theme-3 > li.active > a:focus:focus,
.nav-tabs-theme-3 > li.active > a:hover:focus {
	border: none;
}
.nav-tabs-theme-3 > li.active:hover > a {
	border-top: 0;
	border: none;
	color: #40babd;
}
.theme-2 .nav-tabs-theme-3 > li.active:hover > a {
	color: #6dbd63;
}
.theme-3 .nav-tabs-theme-3 > li.active:hover > a {
	color: #497cb1;
}
.theme-4 .nav-tabs-theme-3 > li.active:hover > a {
	color: #ec6952;
}
.nav-tabs-theme-3 > li.last,
.nav-tabs-theme-3 > li:last-child {
	border-right: 1px solid #eaeaf1;
}
/* ======= Carousels ======= */
/* Bootstrap */
.bootstrap-carousel .carousel-caption {
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	font-weight: 600;
}
.bootstrap-carousel .carousel li {
	margin-right: 0;
}
/* ======= Maps ======= */
.gmap-wrapper {
	position: relative;
}
.gmap {
	height: 320px;
	text-align: left;
	font-weight: normal;
}
.gmap,
.gmap *,
.gmap *:before,
.gmap *:after {
	box-sizing: content-box;
}
.gmap#map-7 {
	height: 520px;
}
.gmap .title {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
	margin-top: 5px;
}
.gmap .gm-style-iw {
	width: 150px;
}
.map-overlay {
	position: relative;
	background: rgba(73, 77, 85, 0.8);
	padding: 15px;
	font-size: 13px;
	color: var(--grayscale---basic--white);
}
.map-overlay:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid rgba(73, 77, 85, 0.8);
	position: absolute;
	bottom: -10px;
	margin-left: -10px;
	left: 50%;
}
/* Flexslider */
.flexslider {
	border-radius: 0;
	background-clip: padding-box;
	padding: 0;
	margin-bottom: 0;
	border: none;
	box-shadow: none;
	overflow: hidden;
}
.flexslider .flex-direction-nav a {
	color: var(--grayscale---basic--white);
	-webkit-opacity: 0.7;
	-moz-opacity: 0.7;
	opacity: 0.7;
	border-radius: 50%;
	background-clip: padding-box;
	background: #333;
	text-align: center;
	width: 32px;
	height: 32px;
}
.flexslider .flex-direction-nav a:before {
	font-size: 18px;
	margin-bottom: 30px;
	padding-top: 5px;
	color: var(--grayscale---basic--white);
	text-shadow: none;
}
.flexslider .flex-direction-nav a:hover {
	-webkit-opacity: 0.9;
	-moz-opacity: 0.9;
	opacity: 0.9;
}
.flexslider .flex-control-nav {
	bottom: 15px;
}
.flexslider .flex-control-paging li a {
	background: rgba(0, 0, 0, 0.4);
}
.flexslider .flex-control-paging li a.flex-active {
	background: rgba(0, 0, 0, 0.8);
}
/* Owl */
.owl-theme .owl-controls .owl-page span {
	background: #616670;
}
/* ======= Alerts ========= */
.alert-theme {
	background: #d0d3d7;
	border: 1px solid #b5b8bf;
	color: #494d55;
}
.alert-theme a {
	font-weight: 600;
	color: #020203;
}
.alert-theme a:hover {
	color: #000000;
}
.alert-theme.alert-success {
	background: #dff0e1;
	border: 1px solid #cde9d1;
	color: #40904d;
}
.alert-theme.alert-success a {
	font-weight: 600;
	color: #295b31;
}
.alert-theme.alert-success a:hover {
	color: #214927;
}
.alert-theme.alert-info {
	background: #daf0fb;
	border: 1px solid #bee4f8;
	color: #127db4;
}
.alert-theme.alert-info a {
	font-weight: 600;
	color: #0e5d86;
}
.alert-theme.alert-info a:hover {
	color: #0b4d6f;
}
.alert-theme.alert-warning {
	background: #feeedc;
	border: 1px solid #fde4c9;
	color: #b16106;
}
.alert-theme.alert-warning a {
	font-weight: 600;
	color: #804605;
}
.alert-theme.alert-warning a:hover {
	color: #673804;
}
.alert-theme.alert-danger {
	background: #fee7e4;
	border: 1px solid #fcd1cc;
	color: #d7230c;
}
.alert-theme.alert-danger a {
	font-weight: 600;
	color: #a71b09;
}
.alert-theme.alert-danger a:hover {
	color: #8e1708;
}
.alert-theme.alert-pink {
	background: #f8c5db;
	border: 1px solid #f5aecd;
	color: #a91555;
}
.alert-theme.alert-pink a {
	font-weight: 600;
	color: #7b0f3e;
}
.alert-theme.alert-pink a:hover {
	color: #650c33;
}
.alert-theme.alert-purple {
	background: #c291d5;
	border: 1px solid #b87fce;
	color: #3e1d4b;
}
.alert-theme.alert-purple a {
	font-weight: 600;
	color: #1f0f26;
}
.alert-theme.alert-purple a:hover {
	color: #100714;
}
.alert-theme.alert-yellow {
	background: #fae4b9;
	border: 1px solid #f9daa1;
	color: #a9720c;
}
.alert-theme.alert-yellow a {
	font-weight: 600;
	color: #795208;
}
.alert-theme.alert-yellow a:hover {
	color: #614207;
}
.alert-theme-solid {
	background: #797f8b;
	border: 1px solid #797f8b;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.6);
}
.alert-theme-solid a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-success {
	background: #75c181;
	border: 1px solid #75c181;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-success .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-success .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-success a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.4);
}
.alert-theme-solid.alert-success a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-info {
	background: #58bbee;
	border: 1px solid #58bbee;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-info .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-info .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-info a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.4);
}
.alert-theme-solid.alert-info a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-warning {
	background: #f8a13f;
	border: 1px solid #f8a13f;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-warning .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-warning .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-warning a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.4);
}
.alert-theme-solid.alert-warning a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-danger {
	background: #f77b6b;
	border: 1px solid #f77b6b;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-danger .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-danger .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-danger a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.5);
}
.alert-theme-solid.alert-danger a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-pink {
	background: #ea5395;
	border: 1px solid #ea5395;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-pink .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-pink .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-pink a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.5);
}
.alert-theme-solid.alert-pink a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-purple {
	background: #8a40a7;
	border: 1px solid #8a40a7;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-purple .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-purple .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-purple a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.5);
}
.alert-theme-solid.alert-purple a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.alert-theme-solid.alert-yellow {
	background: #f2b542;
	border: 1px solid #f2b542;
	color: var(--grayscale---basic--white);
}
.alert-theme-solid.alert-yellow .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.alert-theme-solid.alert-yellow .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-theme-solid.alert-yellow a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.5);
}
.alert-theme-solid.alert-yellow a:hover {
	color: rgba(0, 0, 0, 0.8);
}
/* ======= Promo alert ===== */
.alert-promo {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 0;
	background: #3d4147;
	color: rgba(0, 0, 0, 0.5);
	color: rgba(255, 255, 255, 0.8);
	border-radius: 0;
	background-clip: padding-box;
	border: none;
}
.alert-promo .close {
	color: var(--grayscale---basic--white);
	text-shadow: none;
	font-weight: bold;
	-webkit-opacity: 0.75;
	-moz-opacity: 0.75;
	opacity: 0.75;
}
.alert-promo .close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.alert-promo .btn {
	margin-left: 5px;
}
.alert-promo .btn-xs {
	font-size: 10px;
}
.alert-promo .btn-sm {
	font-size: 11px;
}
.notifyjs-appkit-base {
	padding: 10px;
	border-radius: 4px;
	background-clip: padding-box;
	-webkit-opacity: 0.95;
	-moz-opacity: 0.95;
	opacity: 0.95;
	margin-bottom: 10px;
	font-weight: 600;
	background: #65c8cb;
	border: 1px solid #65c8cb;
	color: var(--grayscale---basic--white);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
}
.theme-2 .notifyjs-appkit-base {
	background: #8fcc87;
	border-color: #8fcc87;
}
.theme-3 .notifyjs-appkit-base {
	background: #6b96c2;
	border-color: #6b96c2;
}
.theme-4 .notifyjs-appkit-base {
	background: #f19180;
	border-color: #f19180;
}
.notifyjs-appkit-base .fa {
	font-size: 16px;
	font-weight: bold;
	margin-right: 5px;
}
.notifyjs-appkit-base a {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.4);
}
.notifyjs-appkit-base a:hover {
	color: rgba(0, 0, 0, 0.8);
}
.notifyjs-appkit-success {
	background: #75c181;
	border: 1px solid #75c181;
	color: var(--grayscale---basic--white);
}
.notifyjs-appkit-info {
	background: #58bbee;
	border: 1px solid #58bbee;
	color: var(--grayscale---basic--white);
}
.notifyjs-appkit-warn {
	background: #f8a13f;
	border: 1px solid #f8a13f;
	color: var(--grayscale---basic--white);
}
.notifyjs-appkit-error {
	background: #f77b6b;
	border: 1px solid #f77b6b;
	color: var(--grayscale---basic--white);
}
/* ======= Badge ======== */
.badge-circle {
	border-radius: 50%;
	background-clip: padding-box;
	padding: 0px;
	padding-top: 4px;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 1;
}
.badge {
	background: #616670;
}
.badge-default {
	background: #616670;
}
.badge-primary {
	background: #40babd;
}
.theme-2 .badge-primary {
	background: #6dbd63;
}
.theme-3 .badge-primary {
	background: #497cb1;
}
.theme-4 .badge-primary {
	background: #ec6952;
}
.badge-success {
	background: #75c181;
}
.badge-info {
	background: #58bbee;
}
.badge-warning {
	background: #f8a13f;
}
.badge-danger {
	background: #f77b6b;
}
.badge-pink {
	background: #ea5395;
}
.badge-purple {
	background: #8a40a7;
}
.badge-yellow {
	background: #f2b542;
}
/* ======= label ========= */
.label-number {
	background: #e9e9e9;
	color: #494d55;
	font-weight: 600;
	border-radius: 0;
	background-clip: padding-box;
}
.label-number-alt {
	background: #797f8b;
	color: var(--grayscale---basic--white) !important;
	font-weight: 600;
	border-radius: 0;
	background-clip: padding-box;
	font-size: 11px;
}
.label-number-alt:hover {
	background: #494d55;
}
.label-default {
	background: #616670;
}
.label-primary {
	background: #40babd;
}
.theme-2 .label-primary {
	background: #6dbd63;
}
.theme-3 .label-primary {
	background: #497cb1;
}
.theme-4 .label-primary {
	background: #ec6952;
}
.label-success {
	background: #75c181;
}
.label-info {
	background: #58bbee;
}
.label-warning {
	background: #f8a13f;
}
.label-danger {
	background: #f77b6b;
}
.label-new {
	background: #75c181;
}
.label-normal {
	background: #58bbee;
}
.label-high {
	background: #f8a13f;
}
.label-low,
.label-grey {
	background: #c3c3c3;
}
.label-critical,
.label-pink {
	background: #ea5395;
}
.label-purple {
	background: #8a40a7;
}
.label-yellow {
	background: #f2b542;
}
.label-open {
	color: #75c181;
	background: none;
	font-size: 12px;
	font-weight: normal;
}
.label-closed {
	color: #f77b6b;
	background: none;
	font-size: 12px;
	font-weight: normal;
}
.label-todo {
	color: #f8a13f;
	background: none;
	font-size: 12px;
	font-weight: normal;
}
.label-review {
	color: #616670;
	background: none;
	font-size: 12px;
	font-weight: normal;
}
.label-progress {
	color: #8a40a7;
	background: none;
	font-size: 12px;
	font-weight: normal;
}
/* ======= Progress ========= */
.progress-container {
	overflow: hidden;
	display: block;
}
.progress {
	border-radius: 16px;
	background: var(--grayscale---gray-200);
	box-shadow: none;
	display: block;
	margin-bottom: 0px;
	height: 10px;
	width: 80%;
}
.progress-bar {
	box-shadow: none;
	font-weight: 600;
	background: var(--primary-color);
}
.progress-sm {
	height: 15px;
}
.progress-sm .progress-bar {
	line-height: 15px;
	font-size: 12px;
}
.progress-bar-theme {
	background: #40babd;
}
.theme-2 .progress-bar-theme {
	background: #6dbd63;
}
.theme-3 .progress-bar-theme {
	background: #497cb1;
}
.theme-4 .progress-bar-theme {
	background: #ec6952;
}
.progress-bar-success {
	background: #75c181;
}
.progress-bar-info {
	background: #58bbee;
}
.progress-bar-warning {
	background: #f8a13f;
}
.progress-bar-danger {
	background: #f77b6b;
}
.progress-bar-purple {
	background: #8a40a7;
}
.progress-bar-pink {
	background: #ea5395;
}
.progress-bar-yellow {
	background: #f2b542;
}
/* ======= List Group ======= */
a.list-group-item {
	border-color: #eaeaf1;
	color: #616670;
}
a.list-group-item .list-group-item-heading {
	font-weight: 600;
	color: #616670;
}
a.list-group-item:focus,
a.list-group-item:hover {
	background: #f9f9fb;
	color: #494d55;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
	background: #40babd;
	border-color: #40babd;
}
.theme-2 .list-group-item.active,
.theme-2 .list-group-item.active:focus,
.theme-2 .list-group-item.active:hover {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .list-group-item.active,
.theme-3 .list-group-item.active:focus,
.theme-3 .list-group-item.active:hover {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .list-group-item.active,
.theme-4 .list-group-item.active:focus,
.theme-4 .list-group-item.active:hover {
	background: #ec6952;
	border-color: #ec6952;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
	color: rgba(255, 255, 255, 0.8);
}
.list-group-item-success,
a.list-group-item-success {
	background: #dff0e1;
	border: 1px solid #cde9d1;
	color: #40904d;
}
.list-group-item-success:hover,
a.list-group-item-success:hover,
.list-group-item-success:focus,
a.list-group-item-success:focus {
	background: #bbe1c1;
	border: 1px solid #bbe1c1;
	color: #316c3a;
}
.list-group-item-info,
a.list-group-item-info {
	background: #daf0fb;
	border: 1px solid #bee4f8;
	color: #127db4;
}
.list-group-item-info:hover,
a.list-group-item-info:hover,
.list-group-item-info:focus,
a.list-group-item-info:focus {
	background: #abddf6;
	border: 1px solid #a7dbf6;
	color: #0e5d86;
}
.list-group-item-warning,
a.list-group-item-warning {
	background: #feeedc;
	border: 1px solid #fde4c9;
	color: #b16106;
}
.list-group-item-warning:hover,
a.list-group-item-warning:hover,
.list-group-item-warning:focus,
a.list-group-item-warning:focus {
	background: #fcd6ab;
	border: 1px solid #fcd8b0;
	color: #804605;
}
.list-group-item-danger,
a.list-group-item-danger {
	background: #fee7e4;
	border: 1px solid #fcd1cc;
	color: #d7230c;
}
.list-group-item-danger:hover,
a.list-group-item-danger:hover,
.list-group-item-danger:focus,
a.list-group-item-danger:focus {
	background: #fbbcb4;
	border: 1px solid #fbbcb4;
	color: #a71b09;
}
/* ======= Pager ======== */
.pager li > a,
.pager li > span {
	color: #616670;
}
.pager li > a:hover {
	color: #494d55;
}
.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
	color: #c3c3c3;
}
/* ====== Pagination ====== */
.pagination li a {
	border-radius: 4px;
	background-clip: padding-box;
	border: none;
	margin-right: 5px;
	color: #616670;
}
.pagination li a:hover {
	color: #494d55;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	color: #c3c3c3;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #797f8b;
	border: none;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	background-color: #f9f9fb;
	border: none;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 4px;
	background-clip: padding-box;
	background: none;
}
/* ====== Popover ====== */
.popover {
	border-radius: 4px;
	background-clip: padding-box;
	border: 1px solid #dadae7;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.popover.top > .arrow {
	border-top-color: #dadae7;
}
.popover.bottom > .arrow {
	border-bottom-color: #dadae7;
}
.popover.left > .arrow {
	border-left-color: #dadae7;
}
.popover.right > .arrow {
	border-right-color: #dadae7;
}
.popover-title {
	padding: 10px 15px;
	background: #f9f9fb;
	-webkit-border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topright: 4px;
	-moz-border-radius-bottomright: 0;
	-moz-border-radius-bottomleft: 0;
	-moz-border-radius-topleft: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top-left-radius: 4px;
	background-clip: padding-box;
	border-bottom-color: #f0f0f5;
	color: #494d55;
}
/* ====== Table ======== */
.table-responsive {
	border: none;
}
.table {
	border-color: #eaeaf1;
}
.table > thead > tr > th {
	font-weight: 600;
	color: #616670;
}
.table > thead > tr > th,
.table > thead > tr > td {
	border-color: #eaeaf1;
}
.table > tbody > tr > th {
	font-weight: 600;
	color: #616670;
}
.table > tbody > tr > th,
.table > tbody > tr > td {
	border-color: #eaeaf1;
}
.table-striped > tbody > tr:nth-of-type(odd) {
	background: #f9f9fb;
}
.table-hover > tbody > tr:hover {
	background: #f9f9fb;
}
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
	background: #f9f9fb;
}
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
	background: #f0f0f5;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
	background: #dff0e1;
}
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
	background: #bbe1c1;
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
	background: #daf0fb;
}
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
	background: #abddf6;
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
	background: #feeedc;
}
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
	background: #fcd6ab;
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
	background: #fee7e4;
}
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
	background: #fbbcb4;
}
/* Simple Table */
.table-simple > tbody > tr > td,
.table-simple > tbody > tr > th,
.table-simple > tfoot > tr > td,
.table-simple > tfoot > tr > th,
.table-simple > thead > tr > td,
.table-simple > thead > tr > th {
	border: none;
}
.table-simple thead th {
	color: #9499a3;
	font-weight: 600;
}
.table-simple a {
	color: #494d55;
}
.table-simple a:hover {
	color: #40babd;
}
.theme-2 .table-simple a:hover {
	color: #6dbd63;
}
.theme-3 .table-simple a:hover {
	color: #497cb1;
}
.theme-4 .table-simple a:hover {
	color: #ec6952;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
	background-image: none !important;
}
/* Datatable */
.dataTables_wrapper .row {
	margin-right: 0;
	margin-bottom: 15px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 0;
	border: 1px solid transparent;
	background: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	background: none;
	border: 1px solid transparent;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	background: none;
	border: 1px solid transparent;
	box-shadow: none;
}
.dataTables_wrapper .dataTables_info {
	color: #a2a6af;
}
.dataTables_wrapper table.dataTable tfoot th,
.dataTables_wrapper table.dataTable tfoot td {
	border-color: #a2a6af;
}
div.dataTables_length label,
div.dataTables_filter label {
	margin-bottom: 0;
	color: #616670;
}
.dataTables_wrapper .dataTables_filter input {
	vertical-align: inherit;
}
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
	background: inherit;
}

.dataTables_wrapper table.child-row thead {
	/*display:none;*/
}

table.table thead th.sorting:after,
table.table thead th.sorting_asc:after,
table.table thead th.sorting_desc:after {
	top: 10px;
}
table.table thead th.sorting:after,
table.table thead th.sorting_asc:after,
table.table thead th.sorting_desc:after {
	top: 12px;
}
table.dataTable thead th {
	border-bottom: 2px solid #eaeaf1;
}

table.dataTable thead th.details-control {
	padding-left: 20px;
	width: 8px;
}

table.dataTable tbody td.details-control {
}

table.dataTable tbody td.firstColChild {
	padding-left: 50px;
}

table.dataTable tbody td.firstColChild2 {
	padding-left: 57px;
}

table.dataTable tbody tr.shown td.details-control i {
	transform: scaleY(-1);
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 15px;
}

table.dataTable tbody tr.shown + tr > td {
	padding: 0;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.dataTables_wrapper .dataTables_filter input[type="search"] {
		width: 160px;
	}
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ===== Forms (basic) ===== */
label {
	font-weight: 500;
	color: var(--grayscale---gray-600);
	font-size: 14px;
}
label .help-block {
	font-weight: 400;
}
.help-block {
	color: #a2a6af;
}
.form-group-striped .form-group {
	padding: 15px 0;
	margin-bottom: 0;
}
.form-group-striped .form-group:nth-child(odd) {
	background: #f9f9fb;
}
.form-control {
	box-shadow: none;
	border-color: var(--grayscale---gray-200);
	border-radius: 2px;
	background-clip: padding-box;
}
.form-control::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #afb3bb;
}
.form-control:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #afb3bb;
}
.form-control::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #afb3bb;
}
.form-control:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #afb3bb;
}
.form-control:focus {
	border-color: #bcbcd2;
	box-shadow: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: var(--grayscale---gray-100);
	color: #c3c3c3;
}
/* uib-typeahead workaoud for in-place applied style
   It applies transparent backgroud, even for disabled
   inputs
*/
.form-control[disabled][uib-typeahead] {
	background-color: #eeeeee !important;
}
.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
	color: #9499a3;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
input[type="button"],
textarea {
	-moz-appearance: none;
	appearance: none;
	/* for mobile safari */
	-webkit-appearance: none;
}
#focusedInput {
	border-color: #65c8cb;
	box-shadow: none;
}
.theme-2 #focusedInput {
	border-color: #8fcc87;
}
.theme-3 #focusedInput {
	border-color: #6b96c2;
}
.theme-4 #focusedInput {
	border-color: #f19180;
}
.input-group-addon {
	background: #f9f9fb;
	color: #616670;
}
.option-divider {
	text-align: center;
	position: relative;
	margin-bottom: 30px;
	color: #999999;
	text-transform: uppercase;
}
.option-divider span {
	background: var(--grayscale---basic--white);
	display: inline-block;
	padding: 0 10px;
	position: relative;
}
.option-divider:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	background: #f3f3f7;
	height: 1px;
	width: 100%;
}
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
	color: #48a156;
}
.has-success .form-control,
.has-success .input-group-addon {
	border-color: #98d1a1;
	box-shadow: none;
}
.has-success .form-control:focus,
.has-success .input-group-addon:focus {
	border-color: #48a156;
	box-shadow: none;
}
.has-success label {
	color: #48a156;
}
.has-success .form-control-feedback {
	color: #75c181;
}
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
	color: #e27c08;
}
.has-warning .form-control,
.has-warning .input-group-addon {
	border-color: #fab970;
	box-shadow: none;
}
.has-warning .form-control:focus,
.has-warning .input-group-addon:focus {
	border-color: #e27c08;
	box-shadow: none;
}
.has-warning label {
	color: #e27c08;
}
.has-warning .form-control-feedback {
	color: #f8a13f;
}
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
	color: #f33a22;
}
.has-error .form-control,
.has-error .input-group-addon {
	border-color: #faa69b;
	box-shadow: none;
}
.has-error .form-control:focus,
.has-error .input-group-addon:focus {
	border-color: #f33a22;
	box-shadow: none;
}
.has-error label {
	color: #f33a22;
}
.has-error .form-control-feedback {
	color: #f77b6b;
}
/* ===== Forms (advanced) ===== */
/* Bootstrap slider */
.slider-track {
	background: #eaeaf1;
	background-color: #eaeaf1;
	background: -o-linear-gradient(top, #eaeaf1, #eaeaf1);
	box-shadow: none;
}
.slider-selection {
	background: #40babd;
}
.theme-2 .slider-selection {
	background: #6dbd63;
}
.theme-3 .slider-selection {
	background: #497cb1;
}
.theme-4 .slider-selection {
	background: #ec6952;
}
.slider-handle {
	background-color: #2d8284;
	background: -o-linear-gradient(top, #2d8284, #2d8284);
}
.theme-2 .slider-handle {
	background-color: #48953f;
	background: -o-linear-gradient(top, #48953f, #48953f);
}
.theme-3 .slider-handle {
	background-color: #33567b;
	background: -o-linear-gradient(top, #33567b, #33567b);
}
.theme-4 .slider-handle {
	background-color: #da3518;
	background: -o-linear-gradient(top, #da3518, #da3518);
}
.slider-selection.tick-slider-selection {
	background-color: #40babd;
	background: -o-linear-gradient(top, #40babd, #40babd);
}
.theme-2 .slider-selection.tick-slider-selection {
	background-color: #6dbd63;
	background: -o-linear-gradient(top, #6dbd63, #6dbd63);
}
.theme-3 .slider-selection.tick-slider-selection {
	background-color: #497cb1;
	background: -o-linear-gradient(top, #497cb1, #497cb1);
}
.theme-4 .slider-selection.tick-slider-selection {
	background-color: #ec6952;
	background: -o-linear-gradient(top, #ec6952, #ec6952);
}
.slider-tick {
	background: #eaeaf1;
	background-color: #eaeaf1;
	background: -o-linear-gradient(top, #eaeaf1, #eaeaf1);
	box-shadow: none;
	border: none;
}
.slider-tick.in-selection {
	background-color: #40babd;
	background: -o-linear-gradient(top, #40babd, #40babd);
}
.theme-2 .slider-tick.in-selection {
	background-color: #6dbd63;
	background: -o-linear-gradient(top, #6dbd63, #6dbd63);
}
.theme-3 .slider-tick.in-selection {
	background-color: #497cb1;
	background: -o-linear-gradient(top, #497cb1, #497cb1);
}
.theme-4 .slider-tick.in-selection {
	background-color: #ec6952;
	background: -o-linear-gradient(top, #ec6952, #ec6952);
}
.slider-vertical + .slider-vertical {
	margin-left: 15px;
	margin-bottom: 15px;
}
.bootstrap-switch {
	border-color: #eaeaf1;
	font-weight: 600;
	color: #616670;
}
.bootstrap-switch.bootstrap-switch-focused {
	border-color: #dadae7;
	box-shadow: none;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
	color: #494d55;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
	background: #40babd;
}
.theme-2 .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.theme-2
	.bootstrap-switch
	.bootstrap-switch-handle-off.bootstrap-switch-primary {
	background: #6dbd63;
}
.theme-3 .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.theme-3
	.bootstrap-switch
	.bootstrap-switch-handle-off.bootstrap-switch-primary {
	background: #497cb1;
}
.theme-4 .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.theme-4
	.bootstrap-switch
	.bootstrap-switch-handle-off.bootstrap-switch-primary {
	background: #ec6952;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
	background: #eaeaf1;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
	background: #40babd;
	text-shadow: none;
}
.theme-2 .datepicker table tr td.active:hover,
.theme-2 .datepicker table tr td.active:hover:hover,
.theme-2 .datepicker table tr td.active.disabled:hover,
.theme-2 .datepicker table tr td.active.disabled:hover:hover,
.theme-2 .datepicker table tr td.active:active,
.theme-2 .datepicker table tr td.active:hover:active,
.theme-2 .datepicker table tr td.active.disabled:active,
.theme-2 .datepicker table tr td.active.disabled:hover:active,
.theme-2 .datepicker table tr td.active.active,
.theme-2 .datepicker table tr td.active:hover.active,
.theme-2 .datepicker table tr td.active.disabled.active,
.theme-2 .datepicker table tr td.active.disabled:hover.active,
.theme-2 .datepicker table tr td.active.disabled,
.theme-2 .datepicker table tr td.active:hover.disabled,
.theme-2 .datepicker table tr td.active.disabled.disabled,
.theme-2 .datepicker table tr td.active.disabled:hover.disabled,
.theme-2 .datepicker table tr td.active[disabled],
.theme-2 .datepicker table tr td.active:hover[disabled],
.theme-2 .datepicker table tr td.active.disabled[disabled],
.theme-2 .datepicker table tr td.active.disabled:hover[disabled] {
	background: #6dbd63;
}
.theme-3 .datepicker table tr td.active:hover,
.theme-3 .datepicker table tr td.active:hover:hover,
.theme-3 .datepicker table tr td.active.disabled:hover,
.theme-3 .datepicker table tr td.active.disabled:hover:hover,
.theme-3 .datepicker table tr td.active:active,
.theme-3 .datepicker table tr td.active:hover:active,
.theme-3 .datepicker table tr td.active.disabled:active,
.theme-3 .datepicker table tr td.active.disabled:hover:active,
.theme-3 .datepicker table tr td.active.active,
.theme-3 .datepicker table tr td.active:hover.active,
.theme-3 .datepicker table tr td.active.disabled.active,
.theme-3 .datepicker table tr td.active.disabled:hover.active,
.theme-3 .datepicker table tr td.active.disabled,
.theme-3 .datepicker table tr td.active:hover.disabled,
.theme-3 .datepicker table tr td.active.disabled.disabled,
.theme-3 .datepicker table tr td.active.disabled:hover.disabled,
.theme-3 .datepicker table tr td.active[disabled],
.theme-3 .datepicker table tr td.active:hover[disabled],
.theme-3 .datepicker table tr td.active.disabled[disabled],
.theme-3 .datepicker table tr td.active.disabled:hover[disabled] {
	background: #497cb1;
}
.theme-4 .datepicker table tr td.active:hover,
.theme-4 .datepicker table tr td.active:hover:hover,
.theme-4 .datepicker table tr td.active.disabled:hover,
.theme-4 .datepicker table tr td.active.disabled:hover:hover,
.theme-4 .datepicker table tr td.active:active,
.theme-4 .datepicker table tr td.active:hover:active,
.theme-4 .datepicker table tr td.active.disabled:active,
.theme-4 .datepicker table tr td.active.disabled:hover:active,
.theme-4 .datepicker table tr td.active.active,
.theme-4 .datepicker table tr td.active:hover.active,
.theme-4 .datepicker table tr td.active.disabled.active,
.theme-4 .datepicker table tr td.active.disabled:hover.active,
.theme-4 .datepicker table tr td.active.disabled,
.theme-4 .datepicker table tr td.active:hover.disabled,
.theme-4 .datepicker table tr td.active.disabled.disabled,
.theme-4 .datepicker table tr td.active.disabled:hover.disabled,
.theme-4 .datepicker table tr td.active[disabled],
.theme-4 .datepicker table tr td.active:hover[disabled],
.theme-4 .datepicker table tr td.active.disabled[disabled],
.theme-4 .datepicker table tr td.active.disabled:hover[disabled] {
	background: #ec6952;
}
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active {
	background: #40babd;
	text-shadow: none;
}
.theme-2 .datetimepicker table tr td.active:active,
.theme-2 .datetimepicker table tr td.active:hover:active,
.theme-2 .datetimepicker table tr td.active.disabled:active,
.theme-2 .datetimepicker table tr td.active.disabled:hover:active,
.theme-2 .datetimepicker table tr td.active.active,
.theme-2 .datetimepicker table tr td.active:hover.active,
.theme-2 .datetimepicker table tr td.active.disabled.active,
.theme-2 .datetimepicker table tr td.active.disabled:hover.active {
	background: #6dbd63;
}
.theme-3 .datetimepicker table tr td.active:active,
.theme-3 .datetimepicker table tr td.active:hover:active,
.theme-3 .datetimepicker table tr td.active.disabled:active,
.theme-3 .datetimepicker table tr td.active.disabled:hover:active,
.theme-3 .datetimepicker table tr td.active.active,
.theme-3 .datetimepicker table tr td.active:hover.active,
.theme-3 .datetimepicker table tr td.active.disabled.active,
.theme-3 .datetimepicker table tr td.active.disabled:hover.active {
	background: #497cb1;
}
.theme-4 .datetimepicker table tr td.active:active,
.theme-4 .datetimepicker table tr td.active:hover:active,
.theme-4 .datetimepicker table tr td.active.disabled:active,
.theme-4 .datetimepicker table tr td.active.disabled:hover:active,
.theme-4 .datetimepicker table tr td.active.active,
.theme-4 .datetimepicker table tr td.active:hover.active,
.theme-4 .datetimepicker table tr td.active.disabled.active,
.theme-4 .datetimepicker table tr td.active.disabled:hover.active {
	background: #ec6952;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background: #40babd;
	border-color: #40babd;
	text-shadow: none;
}
.theme-2 .datepicker table tr td span.active:hover,
.theme-2 .datepicker table tr td span.active:hover:hover,
.theme-2 .datepicker table tr td span.active.disabled:hover,
.theme-2 .datepicker table tr td span.active.disabled:hover:hover,
.theme-2 .datepicker table tr td span.active:focus,
.theme-2 .datepicker table tr td span.active:hover:focus,
.theme-2 .datepicker table tr td span.active.disabled:focus,
.theme-2 .datepicker table tr td span.active.disabled:hover:focus,
.theme-2 .datepicker table tr td span.active:active,
.theme-2 .datepicker table tr td span.active:hover:active,
.theme-2 .datepicker table tr td span.active.disabled:active,
.theme-2 .datepicker table tr td span.active.disabled:hover:active,
.theme-2 .datepicker table tr td span.active.active,
.theme-2 .datepicker table tr td span.active:hover.active,
.theme-2 .datepicker table tr td span.active.disabled.active,
.theme-2 .datepicker table tr td span.active.disabled:hover.active,
.theme-2 .open .dropdown-toggle.datepicker table tr td span.active,
.theme-2 .open .dropdown-toggle.datepicker table tr td span.active:hover,
.theme-2 .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.theme-2
	.open
	.dropdown-toggle.datepicker
	table
	tr
	td
	span.active.disabled:hover {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .datepicker table tr td span.active:hover,
.theme-3 .datepicker table tr td span.active:hover:hover,
.theme-3 .datepicker table tr td span.active.disabled:hover,
.theme-3 .datepicker table tr td span.active.disabled:hover:hover,
.theme-3 .datepicker table tr td span.active:focus,
.theme-3 .datepicker table tr td span.active:hover:focus,
.theme-3 .datepicker table tr td span.active.disabled:focus,
.theme-3 .datepicker table tr td span.active.disabled:hover:focus,
.theme-3 .datepicker table tr td span.active:active,
.theme-3 .datepicker table tr td span.active:hover:active,
.theme-3 .datepicker table tr td span.active.disabled:active,
.theme-3 .datepicker table tr td span.active.disabled:hover:active,
.theme-3 .datepicker table tr td span.active.active,
.theme-3 .datepicker table tr td span.active:hover.active,
.theme-3 .datepicker table tr td span.active.disabled.active,
.theme-3 .datepicker table tr td span.active.disabled:hover.active,
.theme-3 .open .dropdown-toggle.datepicker table tr td span.active,
.theme-3 .open .dropdown-toggle.datepicker table tr td span.active:hover,
.theme-3 .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.theme-3
	.open
	.dropdown-toggle.datepicker
	table
	tr
	td
	span.active.disabled:hover {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .datepicker table tr td span.active:hover,
.theme-4 .datepicker table tr td span.active:hover:hover,
.theme-4 .datepicker table tr td span.active.disabled:hover,
.theme-4 .datepicker table tr td span.active.disabled:hover:hover,
.theme-4 .datepicker table tr td span.active:focus,
.theme-4 .datepicker table tr td span.active:hover:focus,
.theme-4 .datepicker table tr td span.active.disabled:focus,
.theme-4 .datepicker table tr td span.active.disabled:hover:focus,
.theme-4 .datepicker table tr td span.active:active,
.theme-4 .datepicker table tr td span.active:hover:active,
.theme-4 .datepicker table tr td span.active.disabled:active,
.theme-4 .datepicker table tr td span.active.disabled:hover:active,
.theme-4 .datepicker table tr td span.active.active,
.theme-4 .datepicker table tr td span.active:hover.active,
.theme-4 .datepicker table tr td span.active.disabled.active,
.theme-4 .datepicker table tr td span.active.disabled:hover.active,
.theme-4 .open .dropdown-toggle.datepicker table tr td span.active,
.theme-4 .open .dropdown-toggle.datepicker table tr td span.active:hover,
.theme-4 .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.theme-4
	.open
	.dropdown-toggle.datepicker
	table
	tr
	td
	span.active.disabled:hover {
	background: #ec6952;
	border-color: #ec6952;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
	text-shadow: none;
}
.input-daterange .input-group-addon {
	text-shadow: none;
	background: #f9f9fb;
}
.datepicker-inline {
	border: 1px solid #eaeaf1;
}
.chosen-container .chosen-choices {
	border: 1px solid #dadae7;
	box-shadow: none;
	background-image: none;
	padding: 6px 12px;
	height: 34px;
}
.chosen-container .chosen-choices li.search-field input[type="text"] {
	color: #9499a3;
}
.chosen-container .chosen-drop {
	border: 1px solid #dadae7;
}
.chosen-container .chosen-results li.highlighted {
	background: #40babd;
}
.theme-2 .chosen-container .chosen-results li.highlighted {
	background: #6dbd63;
}
.theme-3 .chosen-container .chosen-results li.highlighted {
	background: #497cb1;
}
.theme-4 .chosen-container .chosen-results li.highlighted {
	background: #ec6952;
}
.chosen-container .chosen-results li.highlighted {
	background: #40babd;
	background-image: none;
}
.theme-2 .chosen-container .chosen-results li.highlighted {
	background: #6dbd63;
}
.theme-3 .chosen-container .chosen-results li.highlighted {
	background: #497cb1;
}
.theme-4 .chosen-container .chosen-results li.highlighted {
	background: #ec6952;
}
.chosen-container-single .chosen-single,
.chosen-container-single.chosen-container-active .chosen-single {
	background: none;
	box-shadow: none;
	border-radius: 0;
	background-clip: padding-box;
}
.chosen-container-multi .chosen-choices li.search-choice {
	border: 1px solid #e8e8e8;
	padding: 4px 20px 4px 5px;
	color: #494d55;
	background: var(--grayscale---gray-100);
}
.chosen-container-multi .chosen-choices {
	border-color: #dadae7;
	border-radius: 2px;
	background-clip: padding-box;
}
.chosen-container-multi .chosen-choices li.search-choice a.search-choice-close {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
}
.chosen-container-single .chosen-single {
	border: 1px solid #dadae7;
	padding: 6px 12px;
	height: 34px;
	line-height: 1.42857143;
}
.chosen-container-single .chosen-single div {
	top: 5px;
}
.chosen-container-single .chosen-search input[type="text"] {
	border: 1px solid #eaeaf1;
}
.bootstrap-tagsinput {
	box-shadow: none;
	border-color: #dadae7;
	border-radius: 0;
	background-clip: padding-box;
}
.bootstrap-tagsinput .tag {
	font-size: 12px;
	display: inline-block;
	margin-bottom: 5px;
	padding: 6px 8px;
}
.md-editor > textarea {
	border-color: #dadae7;
	background: var(--grayscale---basic--white);
}
.md-editor.active {
	border-color: #bcbcd2;
	box-shadow: none;
}
.md-editor > textarea,
.md-editor > .md-preview {
	padding: 10px;
}
ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
	background: #f9f9fb;
}
ul.wysihtml5-toolbar .btn .fa {
	margin-right: 0;
}
/* ===== Forms (validation) ===== */
.form-control.parsley-error {
	border-color: #e65348;
}
.parsley-errors-list {
	margin-top: 5px;
	margin-bottom: 5px;
}
.parsley-errors-list {
	padding-left: 0;
	margin-bottom: 15px;
}
.parsley-errors-list li {
	list-style: none;
	color: #f77b6b;
}
/* ===== Forms (in-place editing) ===== */
.view-editable .table .input-type {
	width: 20%;
	font-weight: 600;
}
.view-editable .table .input-type .label {
	margin-left: 5px;
}
.editable-click,
a.editable-click,
a.editable-click:hover {
	border-color: #9499a3;
	color: #494d55;
}
.editable-empty,
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
	color: #f77b6b;
}
/* ===== Forms Dropzone ==== */
.dropzone {
	border: 2px dashed #40babd;
	background: var(--grayscale---basic--white);
}
.theme-2 .dropzone {
	border-color: #6dbd63;
}
.theme-3 .dropzone {
	border-color: #497cb1;
}
.theme-4 .dropzone {
	border-color: #ec6952;
}
.dz-message {
	font-weight: 500;
	font-size: 18px;
	color: #a2a6af;
}
/* ===== Forms ===== */
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
	box-shadow: none;
	color: rgba(0, 0, 0, 0.3);
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.chosen-container {
		width: 100% !important;
	}
	.wysihtml5-sandbox {
		width: 100% !important;
	}
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ====== Ajax Loaders ======= */
/* Gif Loaders */
.loader-default {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
}
.loader-default .loader-default-inner {
	display: table;
	width: 100%;
	height: 100%;
}
.loader-default .loader-default-inner .img-container {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}
/* CSS3 Loaders */
.loader-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
}
.loader-wrapper-1 .spinner {
	margin: 100px auto;
	width: 32px;
	height: 32px;
	position: relative;
}
.loader-wrapper-1 .container1 > div,
.loader-wrapper-1 .container2 > div,
.loader-wrapper-1 .container3 > div {
	width: 8px;
	height: 8px;
	background-color: #40babd;
	border-radius: 100%;
	position: absolute;
	animation: bouncedelay 1.2s infinite ease-in-out;
	/* Prevent first frame from flickering when animation starts */
	animation-fill-mode: both;
}
.theme-2 .loader-wrapper-1 .container1 > div,
.theme-2 .loader-wrapper-1 .container2 > div,
.theme-2 .loader-wrapper-1 .container3 > div {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-1 .container1 > div,
.theme-3 .loader-wrapper-1 .container2 > div,
.theme-3 .loader-wrapper-1 .container3 > div {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-1 .container1 > div,
.theme-4 .loader-wrapper-1 .container2 > div,
.theme-4 .loader-wrapper-1 .container3 > div {
	background-color: #ec6952;
}
.loader-wrapper-1 .spinner .spinner-container {
	position: absolute;
	width: 100%;
	height: 100%;
}
.loader-wrapper-1 .container2 {
	transform: rotateZ(45deg);
}
.loader-wrapper-1 .container3 {
	transform: rotateZ(90deg);
}
.loader-wrapper-1 .circle1 {
	top: 0;
	left: 0;
}
.loader-wrapper-1 .circle2 {
	top: 0;
	right: 0;
}
.loader-wrapper-1 .circle3 {
	right: 0;
	bottom: 0;
}
.loader-wrapper-1 .circle4 {
	left: 0;
	bottom: 0;
}
.loader-wrapper-1 .container2 .circle1 {
	animation-delay: -1.1s;
}
.loader-wrapper-1 .container3 .circle1 {
	animation-delay: -1s;
}
.loader-wrapper-1 .container1 .circle2 {
	animation-delay: -0.9s;
}
.loader-wrapper-1 .container2 .circle2 {
	animation-delay: -0.8s;
}
.loader-wrapper-1 .container3 .circle2 {
	animation-delay: -0.7s;
}
.loader-wrapper-1 .container1 .circle3 {
	animation-delay: -0.6s;
}
.loader-wrapper-1 .container2 .circle3 {
	animation-delay: -0.5s;
}
.loader-wrapper-1 .container3 .circle3 {
	animation-delay: -0.4s;
}
.loader-wrapper-1 .container1 .circle4 {
	animation-delay: -0.3s;
}
.loader-wrapper-1 .container2 .circle4 {
	animation-delay: -0.2s;
}
.loader-wrapper-1 .container3 .circle4 {
	animation-delay: -0.1s;
}
@keyframes bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
.loader-wrapper-2 .spinner {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;
	animation: rotate 2s infinite linear;
}
.loader-wrapper-2 .dot1,
.loader-wrapper-2 .dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: #40babd;
	border-radius: 100%;
	animation: bounce 2s infinite ease-in-out;
}
.theme-2 .loader-wrapper-2 .dot1,
.theme-2 .loader-wrapper-2 .dot2 {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-2 .dot1,
.theme-3 .loader-wrapper-2 .dot2 {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-2 .dot1,
.theme-4 .loader-wrapper-2 .dot2 {
	background-color: #ec6952;
}
.loader-wrapper-2 .dot2 {
	top: auto;
	bottom: 0px;
	animation-delay: -1s;
}
@keyframes rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}
@keyframes bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
.loader-wrapper-3 .spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}
.loader-wrapper-3 .spinner > div {
	width: 16px;
	height: 16px;
	background-color: #40babd;
	border-radius: 100%;
	display: inline-block;
	animation: bouncedelay 1.4s infinite ease-in-out;
	/* Prevent first frame from flickering when animation starts */
	animation-fill-mode: both;
}
.theme-2 .loader-wrapper-3 .spinner > div {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-3 .spinner > div {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-3 .spinner > div {
	background-color: #ec6952;
}
.loader-wrapper-3 .spinner .bounce1 {
	animation-delay: -0.32s;
}
.loader-wrapper-3 .spinner .bounce2 {
	animation-delay: -0.16s;
}
@keyframes bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
.loader-wrapper-4 .spinner {
	width: 30px;
	height: 30px;
	background-color: #40babd;
	margin: 100px auto;
	animation: rotateplane 1.2s infinite ease-in-out;
}
.theme-2 .loader-wrapper-4 .spinner {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-4 .spinner {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-4 .spinner {
	background-color: #ec6952;
}
@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
.loader-wrapper-5 .spinner {
	margin: 100px auto;
	width: 50px;
	height: 30px;
	text-align: center;
	font-size: 10px;
}
.loader-wrapper-5 .spinner > div {
	background-color: #40babd;
	height: 100%;
	width: 6px;
	display: inline-block;
	animation: stretchdelay 1.2s infinite ease-in-out;
}
.theme-2 .loader-wrapper-5 .spinner > div {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-5 .spinner > div {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-5 .spinner > div {
	background-color: #ec6952;
}
.loader-wrapper-5 .spinner .rect2 {
	animation-delay: -1.1s;
}
.loader-wrapper-5 .spinner .rect3 {
	animation-delay: -1s;
}
.loader-wrapper-5 .spinner .rect4 {
	animation-delay: -0.9s;
}
.loader-wrapper-5 .spinner .rect5 {
	animation-delay: -0.8s;
}
@keyframes stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}
.loader-wrapper-6 .spinner {
	width: 40px;
	height: 40px;
	margin: 100px auto;
	background-color: #40babd;
	border-radius: 100%;
	animation: scaleout 1s infinite ease-in-out;
}
.theme-2 .loader-wrapper-6 .spinner {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-6 .spinner {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-6 .spinner {
	background-color: #ec6952;
}
@keyframes scaleout {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	100% {
		transform: scale(1);
		-webkit-transform: scale(1);
		opacity: 0;
	}
}
.loader-wrapper-7 .spinner {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
}
.loader-wrapper-7 .double-bounce1,
.loader-wrapper-7 .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #40babd;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	animation: bounce 2s infinite ease-in-out;
}
.theme-2 .loader-wrapper-7 .double-bounce1,
.theme-2 .loader-wrapper-7 .double-bounce2 {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-7 .double-bounce1,
.theme-3 .loader-wrapper-7 .double-bounce2 {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-7 .double-bounce1,
.theme-4 .loader-wrapper-7 .double-bounce2 {
	background-color: #ec6952;
}
.loader-wrapper-7 .double-bounce2 {
	animation-delay: -1s;
}
@keyframes bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
.loader-wrapper-8 .spinner {
	margin: 100px auto;
	width: 36px;
	height: 36px;
	position: relative;
}
.loader-wrapper-8 .cube1,
.loader-wrapper-8 .cube2 {
	background-color: #40babd;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	animation: cubemove 1.8s infinite ease-in-out;
}
.theme-2 .loader-wrapper-8 .cube1,
.theme-2 .loader-wrapper-8 .cube2 {
	background-color: #6dbd63;
}
.theme-3 .loader-wrapper-8 .cube1,
.theme-3 .loader-wrapper-8 .cube2 {
	background-color: #497cb1;
}
.theme-4 .loader-wrapper-8 .cube1,
.theme-4 .loader-wrapper-8 .cube2 {
	background-color: #ec6952;
}
.loader-wrapper-8 .cube2 {
	animation-delay: -0.9s;
}
@keyframes cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
			scale(0.5);
	}
	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}
/* ====== Override FullCalendar ======= */
.fc-event {
	border-color: #40babd;
	background: #40babd;
	font-weight: 600;
	font-size: 12px;
	color: var(--grayscale---basic--white) !important;
}
.theme-2 .fc-event {
	background: #6dbd63;
	border-color: #6dbd63;
}
.theme-3 .fc-event {
	background: #497cb1;
	border-color: #497cb1;
}
.theme-4 .fc-event {
	background: #ec6952;
	border-color: #ec6952;
}
button.fc-button,
.fa button {
	display: inline-block;
	height: inherit;
	padding: 6px 12px;
	font-weight: 600;
	font-size: 13px;
	line-height: 1.5;
	background: var(--grayscale---basic--white);
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	border: 1px solid #cbcdd2;
	color: #868c97;
	box-shadow: none;
}
button.fc-button:hover,
.fa button:hover,
button.fc-button:focus,
.fa button:focus,
button.fc-button:active,
.fa button:active,
button.fc-button.fc-state-active,
.fa button.fc-state-active {
	border-color: #cbcdd2;
	color: #6c727e;
	background: #f9f9fb;
	box-shadow: none;
}
/* ====== Override Bootstrap Calendar ======= */
.cal-context a {
	color: #494d55;
}
.cal-context a:hover {
	color: #31343a;
}
.event {
	display: block;
	background-color: #616670;
	width: 10px;
	height: 10px;
	margin-right: 5px;
	margin-bottom: 5px;
	box-shadow: none;
	border-radius: 50%;
	background-clip: padding-box;
	border: none;
}
.event-important {
	background-color: #f77b6b;
}
.event-info {
	background-color: #58bbee;
}
.event-warning {
	background-color: #f8a13f;
}
.event-inverse {
	background-color: #1b1b1b;
}
.event-success {
	background-color: #75c181;
}
.event-special {
	background-color: #ea5395;
}
.day-highlight:hover,
.day-highlight {
	background-color: var(--grayscale---basic--white);
}
.day-highlight.dh-event-important:hover,
.day-highlight.dh-event-important {
	background-color: #fee7e4;
}
.day-highlight.dh-event-warning:hover,
.day-highlight.dh-event-warning {
	background-color: #fde9d3;
}
.day-highlight.dh-event-info:hover,
.day-highlight.dh-event-info {
	background-color: #e3f4fc;
}
.day-highlight.dh-event-inverse:hover,
.day-highlight.dh-event-inverse {
	background-color: #c1c1c1;
}
.day-highlight.dh-event-success:hover,
.day-highlight.dh-event-success {
	background-color: #dff0e1;
}
.day-highlight.dh-event-special:hover,
.day-highlight.dh-event-special {
	background-color: #fdf2f7;
}
#cal-day-box .day-highlight.dh-event-important {
	border-color: #fcd1cc;
}
#cal-day-box .day-highlight.dh-event-warning {
	border-color: #fcddba;
}
#cal-day-box .day-highlight.dh-event-info {
	border-color: #cceafa;
}
#cal-day-box .day-highlight.dh-event-inverse {
	border-color: #cecece;
}
#cal-day-box .day-highlight.dh-event-success {
	border-color: #cde9d1;
}
#cal-day-box .day-highlight.dh-event-special {
	border-color: #fce9f1;
}
.cal-month-box,
.cal-week-box,
.cal-year-box {
	border-right-color: #eaeaf1;
}
.cal-month-box [class*="cal-cell"],
.cal-year-box [class*="span"] {
	border-right-color: #eaeaf1;
}
.cal-month-box .cal-row-fluid,
.cal-year-box .row-fluid {
	border-bottom-color: #eaeaf1;
}
#cal-slide-content {
	background: #494d55;
	box-shadow: none;
}
#cal-slide-content:hover {
	color: #494d55;
	background: #616670;
}
#cal-day-box .cal-day-hour-part {
	border-bottom-color: #eaeaf1;
}
#cal-day-box .cal-day-hour:nth-child(odd) {
	background-color: var(--grayscale---basic--white);
}
.cal-event-list .event.pull-left {
	margin-top: 6px;
}
#cal-day-tick {
	bottom: -19px;
}
#cal-week-box {
	background: #40babd;
	color: var(--grayscale---basic--white);
	border-color: #3aa7aa;
	font-weight: 600;
}
.theme-2 #cal-week-box {
	background: #6dbd63;
	border-color: #5cb551;
}
.theme-3 #cal-week-box {
	background: #497cb1;
	border-color: #426f9f;
}
.theme-4 #cal-week-box {
	background: #ec6952;
	border-color: #e9553b;
}
span[data-cal-date] {
	font-size: 13px;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.fc-toolbar .fc-left {
		float: none;
		margin-bottom: 15px;
		overflow: hidden;
	}
	.fc-toolbar .fc-right {
		float: none;
		margin-bottom: 15px;
		overflow: hidden;
	}
	.fc-toolbar .fc-center {
		display: block;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	.fc-toolbar .fc-center h2 {
		float: none;
		font-size: 18px;
		text-align: left;
	}
}
.chart-container {
	padding-top: 15px;
}
.chart-container canvas {
	max-width: 100%;
}
.chartjs-wrapper {
	width: 100%;
	min-height: 200px;
}
.jqstooltip,
.jqstooltip *,
.jqstooltip *:before,
.jqstooltip *:after {
	box-sizing: content-box;
}
.chart-easy-pie .percentage {
	width: 110px;
	height: 110px;
	line-height: 110px;
	vertical-align: middle;
	position: relative;
	margin: 0 auto;
	text-align: center;
	font-size: 18px;
}
.chart-easy-pie .percentage canvas {
	position: absolute;
	left: 0;
	top: 0;
}
.chart-easy-pie .note {
	margin: 10px auto;
	text-align: center;
	display: block;
	color: #a2a6af;
}
body.dragging,
body.dragging * {
	cursor: move !important;
}
.dragged {
	position: absolute;
	top: 0;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
	z-index: 2000;
}
.interactive-list {
	cursor: pointer;
	padding: 0;
}
.interactive-list li {
	margin: 10px 0 10px;
}
.interactive-list li.placeholder {
	position: relative;
	border: none;
	list-style: none;
}
.interactive-list li.placeholder:before {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	margin-top: -5px;
	left: -5px;
	top: -6px;
	border: 5px solid transparent;
	border-left-color: #616670;
	border-right: none;
}
.interactive-list li > span {
	display: block;
	padding: 10px;
	border: 1px solid #f3f3f3;
	border-radius: 2px;
	background-clip: padding-box;
}
.interactive-list li:hover {
	border-color: #dadada;
}
.interactive-list li:hover > span {
	border-color: #dadada;
}
.nestable {
	list-style-position: inside;
}
ul.sortable {
	list-style: none;
	position: relative;
}
ul.sortable li > span {
	padding: 10px 10px 10px 24px;
}
ul.sortable li:hover .fa {
	color: #9499a3;
}
ul.sortable .fa {
	position: absolute;
	margin-top: 14px;
	margin-left: 10px;
	color: #afb3bb;
}
.draggable-column {
	padding-bottom: 100px;
}
.module-draggable:hover {
	border-color: #dadada;
}
.ui-sortable-handle {
	cursor: pointer;
}
/* Modal */
/* Chosen plugin Modal fixes */
.modal .chosen-container {
	width: 100% !important;
}
.modal .chosen-container li.search-field {
	width: 100%;
}
.modal .chosen-container li.search-field > input {
	width: 100% !important;
}
.modal-backdrop {
	background-color: #494d55;
}
.modal-backdrop.in {
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.modal-dialog {
	position: relative;
}
.modal button.close {
	position: absolute;
	right: 15px;
	top: 11px;
	font-size: 28px;
	font-weight: 400;
	margin: 0;
	text-shadow: none;
	-webkit-opacity: 0.35;
	-moz-opacity: 0.35;
	opacity: 0.35;
	color: #494d55;
}
.modal button.close:hover {
	-webkit-opacity: 0.6;
	-moz-opacity: 0.6;
	opacity: 0.6;
}
.modal-content {
	border-radius: 4px;
	background-clip: padding-box;
}
.modal-title {
	text-align: center;
	font-weight: bold;
}
.modal-header {
	border-bottom: 0;
	padding: 30px;
	padding-bottom: 0;
}
.modal-body {
	padding: 30px 60px;
	margin: 5px 5px 5px 0px;
}
.modal-view-controller {
	position: absolute;
	left: 20px;
	top: 15px;
}
.modal-view-controller:hover .fa {
	color: #616670;
}
.modal-view-controller .fa {
	margin-right: 5px;
	color: #a2a6af;
}
.modal-view-controller a {
	color: #a2a6af;
}
.modal-view-controller a:hover {
	color: #616670;
}
/* .modal-fullscreen */
.modal-fullscreen {
	background: var(--grayscale---basic--white);
}
.modal-fullscreen .modal-content {
	background: transparent;
	border: 0;
	box-shadow: none;
}
.modal-backdrop.modal-backdrop-fullscreen {
	background: var(--grayscale---basic--white);
}
.modal-backdrop.modal-backdrop-fullscreen.in {
	background: var(--grayscale---basic--white);
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.modal-body {
		padding: 30px;
	}
	.modal-fullscreen .modal-dialog {
		width: 100%;
		margin: 0 auto;
	}
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.modal-fullscreen .modal-dialog {
		width: 100%;
		margin: 0 auto;
	}
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.modal-fullscreen .modal-dialog {
		width: 80%;
		margin: 0 auto;
	}
}

.del-modal-field {
	padding-top: 7px;
}
/* Large devices (large desktops, 1200px and up) */
/* ======= Tree View ======= */
.jstree-default .jstree-hovered {
	background: #f3f3f3;
}
.jstree-default .jstree-icon {
	color: #616670;
}
.jstree-default .jstree-hovered .jstree-icon {
	color: #494d55;
}
/* ======= jQuery TodoList ========= */
.jquery-todolist.ui-widget {
	/* Generic */
	/* Structured */
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input {
	padding-right: 50px;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input input {
	border: 1px solid #dadae7;
	padding: 6px 12px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-edit-input
	input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #afb3bb;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #afb3bb;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #afb3bb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-edit-input
	input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #afb3bb;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input input:focus {
	border-color: #65c8cb;
	outline: none;
}
.theme-2 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:focus {
	border-color: #8fcc87;
}
.theme-3 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:focus {
	border-color: #6b96c2;
}
.theme-4 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:focus {
	border-color: #f19180;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-input input:active {
	border-color: #65c8cb;
}
.theme-2 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:active {
	border-color: #8fcc87;
}
.theme-3 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:active {
	border-color: #6b96c2;
}
.theme-4 .jquery-todolist.ui-widget .jquery-todolist-edit-input input:active {
	border-color: #f19180;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-save {
	background-image: none;
	text-indent: inherit;
	overflow: hidden;
	position: absolute;
	right: 25px;
	top: 2px;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 1.5;
	font-size: 18px;
	color: #75c181;
}
.jquery-todolist.ui-widget .jquery-todolist-edit-save:before {
	content: "\f058";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}
.jquery-todolist.ui-widget .jquery-todolist-item-action-remove {
	background-image: none;
	width: 20px;
	height: 20px;
	text-indent: inherit;
	font-size: 14px;
	top: 4px;
	right: 8px;
	-webkit-opacity: 0.4;
	-moz-opacity: 0.4;
	opacity: 0.4;
}
.jquery-todolist.ui-widget .jquery-todolist-item-action-remove:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
	color: #f77b6b;
}
.jquery-todolist.ui-widget .jquery-todolist-item-action-remove:before {
	content: "\f00d";
	display: inline-block;
	width: 32px;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}
.jquery-todolist.ui-widget .jquery-todolist-button {
	background: #f77b6b;
	color: var(--grayscale---basic--white);
	font-weight: 600;
}
.jquery-todolist.ui-widget
	.jquery-todolist-button
	.jquery-todolist-item-action-remove-cancel {
	padding: 5px;
	padding-top: 0;
	font-weight: bold;
	font-size: 18px;
	background: #616670;
}
.jquery-todolist.ui-widget
	.jquery-todolist-button
	.jquery-todolist-button-confirm {
	padding: 5px 10px;
}
.jquery-todolist.ui-widget .jquery-todolist-title.ui-widget-header {
	padding: 0;
}
.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-title-text {
	font-size: 16px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-menu-show {
	right: 5px;
	top: -3px;
	padding: 0;
	height: auto;
	font-size: 20px;
	color: #616670;
}
.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-menu-show:hover {
	color: #40babd;
}
.theme-2
	.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-menu-show:hover {
	color: #6dbd63;
}
.theme-3
	.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-menu-show:hover {
	color: #497cb1;
}
.theme-4
	.jquery-todolist.ui-widget
	.jquery-todolist-title.ui-widget-header
	.jquery-todolist-menu-show:hover {
	color: #ec6952;
}
.jquery-todolist.ui-widget .jquery-todolist-items {
	margin: 15px 0;
}
.jquery-todolist.ui-widget .jquery-todolist-items .jquery-todolist-item {
	margin-bottom: 5px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item
	.jquery-todolist-item-title {
	margin-left: 30px;
	padding: 5px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item
	.jquery-todolist-item-title-text {
	font-size: 13px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item
	.jquery-todolist-item-actions-left {
	top: 2px;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item
	.jquery-todolist-item-checkbox {
	background-image: none;
	width: 20px;
	height: 20px;
	text-indent: inherit;
	font-size: 18px;
	top: 6px;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item
	.jquery-todolist-item-checkbox:before {
	content: "\f14a";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
	color: #eaeaf1;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item.jquery-todolist-item-done {
	background: #f9f9fb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item.jquery-todolist-item-done
	.jquery-todolist-item-title-text {
	font-size: 13px;
	color: #868c97;
}
.jquery-todolist.ui-widget
	.jquery-todolist-items
	.jquery-todolist-item.jquery-todolist-item-done
	.jquery-todolist-item-checkbox:before {
	color: #75c181;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text {
	border: 1px solid #dadae7;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #afb3bb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #afb3bb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #afb3bb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #afb3bb;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:focus {
	border-color: #65c8cb;
	outline: none;
}
.theme-2
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:focus {
	border-color: #8fcc87;
}
.theme-3
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:focus {
	border-color: #6b96c2;
}
.theme-4
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:focus {
	border-color: #f19180;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:active {
	border-color: #65c8cb;
}
.theme-2
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:active {
	border-color: #8fcc87;
}
.theme-3
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:active {
	border-color: #6b96c2;
}
.theme-4
	.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-input-text:active {
	border-color: #f19180;
}
.jquery-todolist.ui-widget
	.jquery-todolist-footer
	.jquery-todolist-add
	.jquery-todolist-add-action {
	color: var(--grayscale---basic--white);
	border-radius: 4px;
	background-clip: padding-box;
	background: #75c181;
	top: 1px;
	height: 34px;
	width: 34px;
	font-size: 30px;
}
.jquery-todolist.ui-widget .jquery-todolist-menu {
	background: var(--grayscale---basic--white);
	border: 1px solid #eaeaf1;
	padding: 10px;
	text-align: center;
}
.jquery-todolist.ui-widget .jquery-todolist-menu a {
	margin-bottom: 0;
	padding: 0;
}
.jquery-todolist.ui-widget .jquery-todolist-menu .jquery-todolist-menu-actions {
	right: 5px;
	top: 0;
}
.jquery-todolist.ui-widget
	.jquery-todolist-menu
	.jquery-todolist-menu-actions
	a.jquery-todolist-action {
	font-weight: bold;
	font-size: 20px;
	color: #797f8b;
	padding: 0;
	top: 0;
	right: 0;
}
.jquery-todolist.ui-widget
	.jquery-todolist-menu
	.jquery-todolist-menu-actions
	a.jquery-todolist-action:hover {
	color: #494d55;
}
vector-map,
.vector-map {
	width: 100%;
	height: 300px;
	max-width: 100%;
	display: block;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
	width: 15px;
	height: 15px;
	line-height: 1.2;
	font-weight: bold;
	border-radius: 2px;
	background-clip: padding-box;
	padding: 0;
}
/* ===== Forms (wizard) ===== */
.wizard > .content {
	min-height: 300px;
	background: var(--grayscale---gray-100);
	margin: 0;
	margin-bottom: 15px;
	border-radius: 0;
	background-clip: padding-box;
}
.wizard.vertical > .content {
	background: var(--grayscale---gray-100);
	margin: 0;
	margin-left: 30px;
	margin-bottom: 15px;
}
.wizard > .content > .body {
	padding: 30px;
	width: inherit;
	height: inherit;
}
.wizard > .steps .number {
	background: rgba(0, 0, 0, 0.12);
	font-size: 13px;
	border-radius: 50%;
	background-clip: padding-box;
	color: var(--grayscale---basic--white);
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	font-weight: bold;
	padding-top: 2px;
	margin-right: 5px;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
	background: #40babd;
	font-weight: 600;
}
.theme-2 .wizard > .steps .current a,
.theme-2 .wizard > .steps .current a:hover,
.theme-2 .wizard > .steps .current a:active {
	background: #6dbd63;
}
.theme-3 .wizard > .steps .current a,
.theme-3 .wizard > .steps .current a:hover,
.theme-3 .wizard > .steps .current a:active {
	background: #497cb1;
}
.theme-4 .wizard > .steps .current a,
.theme-4 .wizard > .steps .current a:hover,
.theme-4 .wizard > .steps .current a:active {
	background: #ec6952;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
	background: #9eddde;
}
.theme-2 .wizard > .steps .done a,
.theme-2 .wizard > .steps .done a:hover,
.theme-2 .wizard > .steps .done a:active {
	background: #c1e3bd;
}
.theme-3 .wizard > .steps .done a,
.theme-3 .wizard > .steps .done a:hover,
.theme-3 .wizard > .steps .done a:active {
	background: #a1bcd8;
}
.theme-4 .wizard > .steps .done a,
.theme-4 .wizard > .steps .done a:hover,
.theme-4 .wizard > .steps .done a:active {
	background: #f9cdc5;
}
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
	background: var(--grayscale---gray-100);
	color: #797f8b;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
	padding: 10px 20px;
	margin: 0;
	border-radius: 0;
	background-clip: padding-box;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
}
.wizard > .steps > ul > li {
	width: auto;
	margin-right: 10px;
	margin-bottom: 30px;
}
.wizard > .steps > ul > li > a {
	position: relative;
}
.wizard > .steps > ul > li:last-child {
	margin-right: 0;
}
.wizard > .steps > ul > li.current .step-arrow {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #40babd;
	position: absolute;
	bottom: -10px;
	margin-left: -10px;
	left: 50%;
}
.theme-2 .wizard > .steps > ul > li.current .step-arrow {
	border-top-color: #6dbd63;
}
.theme-3 .wizard > .steps > ul > li.current .step-arrow {
	border-top-color: #497cb1;
}
.theme-4 .wizard > .steps > ul > li.current .step-arrow {
	border-top-color: #ec6952;
}
.wizard.vertical > .steps > ul > li.current .step-arrow {
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #40babd;
	bottom: 50%;
	left: auto;
	right: -20px;
	margin-left: auto;
	top: auto;
	margin-bottom: -10px;
}
.theme-2 .wizard.vertical > .steps > ul > li.current .step-arrow {
	border-left-color: #6dbd63;
}
.theme-3 .wizard.vertical > .steps > ul > li.current .step-arrow {
	border-left-color: #497cb1;
}
.theme-4 .wizard.vertical > .steps > ul > li.current .step-arrow {
	border-left-color: #ec6952;
}
.wizard > .steps > ul > li.current.done .step-arrow {
	border-top-color: #9eddde;
}
.theme-2 .wizard > .steps > ul > li.current.done .step-arrow {
	border-top-color: #c1e3bd;
}
.theme-3 .wizard > .steps > ul > li.current.done .step-arrow {
	border-top-color: #a1bcd8;
}
.theme-4 .wizard > .steps > ul > li.current.done .step-arrow {
	border-top-color: #f9cdc5;
}
.wizard.vertical > .steps > ul > li.current.done .step-arrow {
	border-left-color: #9eddde;
	border-top-color: transparent;
}
.theme-2 .wizard.vertical > .steps > ul > li.current.done .step-arrow {
	border-left-color: #c1e3bd;
}
.theme-3 .wizard.vertical > .steps > ul > li.current.done .step-arrow {
	border-left-color: #a1bcd8;
}
.theme-4 .wizard.vertical > .steps > ul > li.current.done .step-arrow {
	border-left-color: #f9cdc5;
}
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
	background: var(--grayscale---gray-100) !important;
	color: #afb3bb !important;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
	background: #40babd;
}
.theme-2 .wizard > .actions a,
.theme-2 .wizard > .actions a:hover,
.theme-2 .wizard > .actions a:active {
	background: #6dbd63;
}
.theme-3 .wizard > .actions a,
.theme-3 .wizard > .actions a:hover,
.theme-3 .wizard > .actions a:active {
	background: #497cb1;
}
.theme-4 .wizard > .actions a,
.theme-4 .wizard > .actions a:hover,
.theme-4 .wizard > .actions a:active {
	background: #ec6952;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
	.wizard > .steps > ul > li {
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}
	.wizard > .steps > ul > li.current .step-arrow {
		display: none;
	}
	.wizard.vertical > .steps {
		width: 100%;
	}
	.wizard.vertical > .content {
		width: 100%;
		margin-left: 0;
	}
	.wizard > .content > .body {
		padding: 20px;
	}
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ======= Tags ======== */
.tags {
	margin: 0;
	padding: 0;
	right: 24px;
	bottom: -12px;
	overflow: hidden;
	list-style: none;
}
.tags .tag {
	margin-bottom: 15px;
}
.tags .tag a {
	margin-left: 20px;
	padding: 0 10px 0 12px;
	background: #616670;
	color: var(--grayscale---basic--white);
	font-weight: 500;
	text-decoration: none;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.tags .tag a:hover {
	background: #555963;
}
.tags .tag a:hover:before {
	border-color: transparent #555963 transparent transparent;
}
.tags .tag a:before {
	content: "";
	float: left;
	position: absolute;
	top: 0;
	left: -12px;
	width: 0;
	height: 0;
	border-color: transparent #616670 transparent transparent;
	border-style: solid;
	border-width: 12px 12px 12px 0;
}
.tags .tag a:after {
	content: "";
	position: absolute;
	top: 10px;
	left: 0;
	float: left;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: var(--grayscale---basic--white);
}
.tags .tag.tag-theme a {
	background: #40babd;
}
.theme-2 .tags .tag.tag-theme a {
	background: #6dbd63;
}
.theme-3 .tags .tag.tag-theme a {
	background: #497cb1;
}
.theme-4 .tags .tag.tag-theme a {
	background: #ec6952;
}
.tags .tag.tag-theme a:hover {
	background: #3aa7aa;
}
.theme-2 .tags .tag.tag-theme a:hover {
	background: #5cb551;
}
.theme-3 .tags .tag.tag-theme a:hover {
	background: #426f9f;
}
.theme-4 .tags .tag.tag-theme a:hover {
	background: #e9553b;
}
.tags .tag.tag-theme a:hover:before {
	border-color: transparent #3aa7aa transparent transparent;
}
.theme-2 .tags .tag.tag-theme a:hover:before {
	border-color: transparent #5cb551 transparent transparent;
}
.theme-3 .tags .tag.tag-theme a:hover:before {
	border-color: transparent #426f9f transparent transparent;
}
.theme-4 .tags .tag.tag-theme a:hover:before {
	border-color: transparent #e9553b transparent transparent;
}
.tags .tag.tag-theme a:before {
	border-color: transparent #40babd transparent transparent;
}
.theme-2 .tags .tag.tag-theme a:before {
	border-color: transparent #6dbd63 transparent transparent;
}
.theme-3 .tags .tag.tag-theme a:before {
	border-color: transparent #497cb1 transparent transparent;
}
.theme-4 .tags .tag.tag-theme a:before {
	border-color: transparent #ec6952 transparent transparent;
}
.tags .tag.tag-success a {
	background: #75c181;
}
.tags .tag.tag-success a:hover {
	background: #63b971;
}
.tags .tag.tag-success a:hover:before {
	border-color: transparent #63b971 transparent transparent;
}
.tags .tag.tag-success a:before {
	border-color: transparent #75c181 transparent transparent;
}
.tags .tag.tag-info a {
	background: #58bbee;
}
.tags .tag.tag-info a:hover {
	background: #41b2ec;
}
.tags .tag.tag-info a:hover:before {
	border-color: transparent #41b2ec transparent transparent;
}
.tags .tag.tag-info a:before {
	border-color: transparent #58bbee transparent transparent;
}
.tags .tag.tag-warning a {
	background: #f8a13f;
}
.tags .tag.tag-warning a:hover {
	background: #f79526;
}
.tags .tag.tag-warning a:hover:before {
	border-color: transparent #f79526 transparent transparent;
}
.tags .tag.tag-warning a:before {
	border-color: transparent #f8a13f transparent transparent;
}
.tags .tag.tag-danger a {
	background: #f77b6b;
}
.tags .tag.tag-danger a:hover {
	background: #f66553;
}
.tags .tag.tag-danger a:hover:before {
	border-color: transparent #f66553 transparent transparent;
}
.tags .tag.tag-danger a:before {
	border-color: transparent #f77b6b transparent transparent;
}
.tags .tag.tag-purple a {
	background: #8a40a7;
}
.tags .tag.tag-purple a:hover {
	background: #7b3995;
}
.tags .tag.tag-purple a:hover:before {
	border-color: transparent #7b3995 transparent transparent;
}
.tags .tag.tag-purple a:before {
	border-color: transparent #8a40a7 transparent transparent;
}
.tags .tag.tag-pink a {
	background: #ea5395;
}
.tags .tag.tag-pink a:hover {
	background: #e73c87;
}
.tags .tag.tag-pink a:hover:before {
	border-color: transparent #e73c87 transparent transparent;
}
.tags .tag.tag-pink a:before {
	border-color: transparent #ea5395 transparent transparent;
}
.tags .tag.tag-yellow a {
	background: #f2b542;
}
.tags .tag.tag-yellow a:hover {
	background: #f0ac2a;
}
.tags .tag.tag-yellow a:hover:before {
	border-color: transparent #f0ac2a transparent transparent;
}
.tags .tag.tag-yellow a:before {
	border-color: transparent #f2b542 transparent transparent;
}
.tags .tag,
.tags a {
	float: left;
	height: 24px;
	line-height: 24px;
	position: relative;
	font-size: 11px;
}
.flot-chart {
	width: 100%;
	height: 260px;
}
.flot-chart .legendColorBox > div {
	border: none !important;
}
.flot-chart .legendLabel {
	padding-left: 5px;
}
.flotTip {
	background: rgba(0, 0, 0, 0.9) !important;
	color: var(--grayscale---basic--white) !important;
	border: none !important;
	border-radius: 2px !important;
	background-clip: padding-box !important;
}
.morris-hover.morris-default-style {
	background: rgba(0, 0, 0, 0.8);
	color: var(--grayscale---basic--white);
	border: none;
	border-radius: 4px;
	background-clip: padding-box;
	padding: 10px 15px;
}
.chart-container svg {
	max-width: 100%;
}
/* ======= Helper ======== */
.masonry-container {
	position: relative;
}
.cursor-pointer {
	cursor: pointer;
}
.no-overflow {
	overflow: hidden;
}
.text-highlight {
	color: #40babd;
}
.theme-2 .text-highlight {
	color: #6dbd63;
}
.theme-3 .text-highlight {
	color: #497cb1;
}
.theme-4 .text-highlight {
	color: #ec6952;
}
.text-stronger {
	font-weight: 600;
}
.display-block {
	display: block;
}
.display-inline-block {
	display: inline-block;
}
.block-relative {
	position: relative;
}
.padding-xs {
	padding: 5px;
}
.padding-sm {
	padding: 15px;
}
.padding-md {
	padding: 30px;
}
.no-margin {
	margin: 0;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.no-margin-top {
	margin-top: 0 !important;
}
.no-padding-top {
	padding-top: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}
.margin-bottom-xs {
	margin-bottom: 5px;
}
.margin-bottom-sm {
	margin-bottom: 15px;
}
.margin-bottom-md {
	margin-bottom: 30px;
}
.margin-bottom-lg {
	margin-bottom: 60px;
}
.margin-top-xs {
	margin-top: 5px;
}
.margin-top-sm {
	margin-top: 15px;
}
.margin-top-md {
	margin-top: 30px;
}
.margin-top-lg {
	margin-top: 60px;
}
/* ======= Shepherd Tour Custom CSS ========= */
.shepherd-step {
	z-index: 20;
}
@media (max-width: 767px) {
	.shepherd-step {
		display: none !important;
	}
}
.shepherd-element.shepherd-theme-arrows .shepherd-content {
	-webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.15));
	filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.15));
	border: 1px solid #e6e6e6;
}
.shepherd-element.shepherd-theme-arrows .shepherd-content footer {
	padding: 15px 30px;
	background: var(--grayscale---gray-100);
}
.shepherd-text {
	max-width: 400px;
	min-width: 300px;
	color: #494d55;
}
.shepherd-element.shepherd-theme-arrows .shepherd-content .shepherd-text {
	padding: 30px;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	footer
	.shepherd-buttons
	li
	.shepherd-button {
	background: #75c181;
	text-decoration: none;
	font-weight: bold;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	footer
	.shepherd-buttons
	li
	.shepherd-button:hover {
	background: #63b971;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	footer
	.shepherd-buttons
	li
	.shepherd-button.shepherd-button-secondary {
	background: #eeeff1;
	color: #616670;
	text-decoration: none;
	font-weight: bold;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	footer
	.shepherd-buttons
	li
	.shepherd-button.shepherd-button-secondary:hover {
	background: #e6e7ea;
}
.shepherd-element.shepherd-theme-arrows.shepherd-has-title
	.shepherd-content
	header {
	background: #75c181;
	color: var(--grayscale---basic--white);
	padding: 15px 30px;
	font-weight: bold;
}
.shepherd-element.shepherd-theme-arrows .shepherd-content header h3 {
	font-weight: 500;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	header
	a.shepherd-cancel-link {
	-webkit-opacity: 0.5;
	-moz-opacity: 0.5;
	opacity: 0.5;
}
.shepherd-element.shepherd-theme-arrows
	.shepherd-content
	header
	a.shepherd-cancel-link:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
/* Extra small devices (phones, less than 768px) */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.carousel-indicators {
		bottom: 10px;
	}
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	/* login/signup */
	.auth-section .divider {
		text-align: center;
		margin-bottom: 30px;
		text-transform: uppercase;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
	}
	.auth-section .divider span {
		background: var(--grayscale---basic--white);
		display: inline-block;
		padding: 10px 0;
		position: relative;
		top: 80px;
		margin-left: -10px;
	}
	.auth-section .divider:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		background: #f3f3f7;
		height: 100%;
		width: 1px;
	}
	.auth-section .social-btns {
		padding-left: 65px;
	}
	.auth-section .social-btns .note {
		text-align: left;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media print {
	.header {
		display: none;
	}
	#side-panel {
		display: none;
	}
	.main-nav-wrapper {
		display: none;
	}
	.content-wrapper {
		margin-left: 0;
	}
}
/* ======= Configure Style (Remove in production) ======= */
.config-trigger {
	display: block;
	position: absolute;
	top: 30px;
	left: -36px;
	width: 36px;
	height: 36px;
	background: #494d55;
	color: var(--grayscale---basic--white);
	text-align: center;
	-webkit-border-top-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-bottom-left-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topright: 0;
	-moz-border-radius-bottomright: 0;
	-moz-border-radius-bottomleft: 4px;
	-moz-border-radius-topleft: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	background-clip: padding-box;
	text-decoration: none;
}
.config-trigger:hover {
	background: #000;
	text-decoration: none;
}
.config-trigger .fa {
	font-size: 22px;
	padding-top: 7px;
	display: block;
	color: var(--grayscale---basic--white);
}
.config-panel {
	position: absolute;
	top: 80px;
	right: -210px;
	z-index: 10;
	width: 210px;
	will-change: transform;
	transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.config-panel.config-panel-open {
	transform: translateX(-210px);
}
.config-panel .panel-inner {
	position: relative;
	background: #494d55;
	color: var(--grayscale---basic--white);
	padding: 30px;
	-webkit-border-top-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-bottom-left-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topright: 0;
	-moz-border-radius-bottomright: 0;
	-moz-border-radius-bottomleft: 4px;
	-moz-border-radius-topleft: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	background-clip: padding-box;
}
.config-panel .panel-title {
	font-weight: 600;
	margin-bottom: 15px;
	font-size: 14px;
}
.config-panel label {
	color: var(--grayscale---basic--white);
}
.config-panel #color-options {
	margin-bottom: 0px;
}
.config-panel #color-options li {
	margin-bottom: 10px;
}
.config-panel #color-options li a {
	display: block;
	width: 100%;
	height: 25px;
	border: 2px solid transparent;
	cursor: pointer;
}
.config-panel #color-options li a:hover {
	-webkit-opacity: 0.9;
	-moz-opacity: 0.9;
	opacity: 0.9;
	border: 2px solid rgba(255, 255, 255, 0.8);
}
.config-panel #color-options li.active a {
	border: 2px solid var(--grayscale---basic--white);
}
.config-panel #color-options li.theme-1 a {
	background: #40babd;
}
.config-panel #color-options li.theme-2 a {
	background-color: #6dbd63;
}
.config-panel #color-options li.theme-3 a {
	background-color: #497cb1;
}
.config-panel #color-options li.theme-4 a {
	background-color: #ec6952;
}
.config-panel a.close {
	position: absolute;
	right: 5px;
	top: 5px;
	color: var(--grayscale---basic--white);
	-webkit-opacity: 0.75;
	-moz-opacity: 0.75;
	opacity: 0.75;
}
.config-panel a.close:hover {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}
.config-panel a.close .fa {
	color: var(--grayscale---basic--white);
}
.datepicker-dropdown {
	border-radius: 4px;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active {
	background-color: var(--primary-color);
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}
.datepicker table tr td span.focused {
	background-color: var(--primary-color);
}

.highcharts-container.highcharts-container {
	z-index: auto !important;
	overflow: visible !important;
}

.upload_icon {
	background-image: url("/assets/images/custom_icons/icon_upload.svg");
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	margin-bottom: -6%;
}

input#organizationSelect {
	border-radius: 0px 4px 4px 0px !important;
}

.fa-youtube-play:before {
	content: "\f16a";
}

.fa-repeat:before {
	content: "\f01e";
}

.fa-picture-o:before {
	content: "\f03e";
}

/* width */
.modal-content ::-webkit-scrollbar {
	margin-right: 5px;
	width: 5px;
	height: 5px;
}

/* Track */
.modal-content ::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.modal-content ::-webkit-scrollbar-thumb {
	background: var(--primary-color);
	border-radius: 10px;
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5) !important;
	border-radius: 4px;
}
.mat-dialog-container {
	border-radius: 10px !important;
	box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5) !important;
}
