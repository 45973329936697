.main-nav-wrapper {
  width: 224px;
  border-top: 4px var(--grayscale---gray-700) solid;
  position:absolute;
}

.main-nav-wrapper .main-nav li.active a {
  color: var(--grayscale---basic--white);
  box-shadow: inset 4px 0px 0px 0px var(--primary-color);
  background: var(--grayscale---gray-800);
  font-weight: bold;
}

.main-nav-wrapper .main-nav li {
  font-size: 15px;
}

.main-nav-wrapper .main-nav li a span.fa, .main-nav-wrapper .main-nav li a span.far,
.main-nav-wrapper .main-nav li a span.fas  {
  margin-right: 6%;
}

.main-nav-wrapper .main-nav li a span.fa.help {
  font-size: 22px;
}

.main-nav-wrapper .main-nav li a span.fa.export {
  font-size: 18px;
}

.main-nav-wrapper .main-nav li a {
  font-weight: 400;
  color: var(--grayscale---basic--white);
  padding-left: 20px;
  font-size: 14px;
  cursor: pointer;
}
.main-nav-wrapper .main-nav li a i{
  width: 20px;
  text-align: center;
}

@media screen and (min-height: 800px){
  .main-nav-wrapper .main-nav li a {
      height: 42px!important;
      font-size: 15px;
      padding-top: 10px!important;
  }
}

.main-nav-wrapper .main-nav li a span {
  margin-top: 2%;
  margin-left: 10px;
}

.main-nav-wrapper .main-nav li a span.fa.comment_icon {
  background-image: url("/assets/images/menu_icons/comment.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.help_icon {
  background-image: url("/assets/images/menu_icons/help.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.measuring_points_icon {
  background-image: url("/assets/images/menu_icons/measuring-points.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.measurements_icon {
  background-image: url("/assets/images/menu_icons/measurements.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.analisys_icon {
  background-image: url("/assets/images/menu_icons/analisys.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.alerts_icon {
  background-image: url("/assets/images/menu_icons/alerts.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.export_icon {
  background-image: url("/assets/images/menu_icons/export.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.free_market_icon {
  background-image: url("/assets/images/menu_icons/free-market.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.contracts_icon {
  background-image: url("/assets/images/menu_icons/contracts.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.invoices_icon {
  background-image: url("/assets/images/menu_icons/invoices.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.organizations_icon {
  background-image: url("/assets/images/menu_icons/organizations.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.companies_icon {
  background-image: url("/assets/images/menu_icons/companies.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.users_icon {
  background-image: url("/assets/images/menu_icons/users.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.economy_icon {
  background-image: url("/assets/images/menu_icons/economy.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

.main-nav-wrapper .main-nav li a span.fa.registrations_icon {
  background-image: url("/assets/images/menu_icons/registrations.svg");
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  margin-bottom: -5px;
}

@media screen and (min-height: 800px){
  .main-nav-wrapper .main-nav li a span.fa{
      margin-right: 5%;
      margin-bottom: -2%;
      margin-left: -1%;
  }
}
.compact:not(.nav-toggled) .main-nav-wrapper,
.nav-toggled:not(.compact):not(.mobile) .main-nav-wrapper {
  width: 48px;
}

.mobile:not(.nav-toggled) .main-nav-wrapper {
  width: 0px;
}

.nav-toggled.mobile .content-wrapper {
  margin-left: 48px;
}

.mobile.nav-toggled .main-nav-wrapper {
  width: 48px;
}

.main-nav-wrapper .main-nav li.only-mobile {
  display: none;
}
body.mobile .main-nav-wrapper .main-nav li.only-mobile {
  display: block;
}

.not-collapse{
  display: block!important;
}

.submenu{
  color: var(--grayscale---gray-500);
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  display: flex;
  line-height: 14px;
  height: 30px;
  padding-top: 10px;
  padding-left: 14px;
}

@media screen and (min-height: 800px){
  .submenu{
      height: 38px;
      padding-top: 16px;
  }
}

.submenu:hover{
  background-color: var(--grayscale---gray-700) !important;
}

div.footer{
  padding-top: 24px;
  padding-bottom: 0px;
  position: sticky;
  bottom: 64px;
  width: 100%;
  text-align: center;
  background-color: var(--grayscale---gray-700);
}

.nav-toggled:not(.compact):not(.mobile) .main-nav .submenu, .compact:not(.nav-toggled):not(.mobile) .main-nav .submenu{
  visibility: hidden;
  height: 1px;
}

.nav-toggled:not(.compact):not(.mobile) .main-nav li a, .compact:not(.nav-toggled):not(.mobile) .main-nav li a{
  padding-left: 14px;
}
