.power-factor-component {
    margin-top: -23px;
}

.power-factor-component .info-excedent {
    margin-top: 20px;
    z-index: 2;
    position: relative;
    width: 80%;
}

.period-container {
  display: flex;
  flex-direction: row;
}

.period-container span {
  margin-right: 14px;
}

.warning-icon {
  color: var(--grayscale---gray-400) !important;
}

.power-factor-component .info-excedent label{
  font-size: 14px;
  margin-bottom: 0;
}

.power-factor-component-info .body-info{
  margin: 0 !important;
  display: none;

}

.power-factor-component-info .first{
  display: grid;
}


.power-factor-component .info-excedent .checkbox:checked ~ .cbx{
  color: var(--primary-color);;
  background-color: var(--primary-color);
}

.power-factor-component .container {
    margin-top: -24px;
}

.power-factor-component .power-factor-component-info {
  padding-right: 0px;
  padding-left: 0px;
}


body:not(.nav-toggled) .open-side-panel .power-factor-component .date-info{
  font-size: 13px;
}
body:not(.nav-toggled) .open-side-panel .power-factor-component .power-factor-component-info{
  font-size: 14px;
  padding-left: 3px;
}

.power-factor-component .little-space {
    margin-bottom: 7%;
}

.power-factor-component .power-factor-component-info section div,
.power-factor-component .power-factor-component-info section h3 {
    font-size: 16px;
    margin-left: -8px;
    margin-bottom: 8px;
}

.power-factor-component .day-view .power-factor-component-info section div,
.power-factor-component .day-view .power-factor-component-info section h3 {
    margin-left: -3px;
}

.power-factor-component  .little-botom-margin {
    margin-bottom: 13px;
    border-bottom: 1px solid var(--grayscale---gray-700);
    width: 108%;
}

.dropdown-icon{
  font-size: smaller;
  float: right;
  padding: 5px;
  cursor: pointer;
}

.power-factor-component .day-view .container {
    margin-top: 20px;
}

.power-factor-component .hour-label{
  position: absolute;
  margin-left: -20px;
}

.power-factor-component .hour-label.two-digits{
  margin-left: -22px;
}

.power-factor-component .hour-label.twelve{
  /*margin-left: -28px;*/
}
.power-factor-component .hour-label .twenty-four{
  margin-left: 14px;
}

.power-factor-component .chart.col-md-10{
  padding-left: 0px;
}

.power-factor-component #power-factor-inductive{
  color : var(--error---error-500);
}

.power-factor-component #power-factor-inductive .fa-exclamation-circle{
  color : var(--error---error-500);
}

.power-factor-component .inductive-penalty{
  color : var(--error---error-500);
}
.power-factor-component #power-factor-capacitive .fa-exclamation-circle{
  color : var(--dataviz---dk--purple);
}
.capacitive-penalty{
  color : var(--dataviz---dk--purple);
}

.toggle-over-label{
  font-weight: 400;
}

.power-factor-component .date-info{
  font-size: 14px;
  display: block;
  line-height: 12px;
}


body:not(.nav-toggled) .open-side-panel .power-factor-component .date-info{
  font-size: 13px;
}
body:not(.nav-toggled) .open-side-panel .power-factor-component .power-factor-component-info{
  font-size: 14px;
  padding-left: 3px;
}


@media (min-width: 1400px) {
  .power-factor-component .hour-label{
    margin-left: -28px;
  }

  .power-factor-component .hour-label.two-digits{
    margin-left: -32px;
  }

  .power-factor-component .hour-label.twelve{
    /*margin-left: -36px;*/
  }
  .power-factor-component .hour-label .twenty-four{
    margin-left: 32px;
  }
}

.mobile .power-factor-component .chart {
  margin-top: 24px;
}

/* @media (min-width: 768px){
  .panel-ga.powerFactorChart>.panel-body {
    height: 325px;
  }
} */