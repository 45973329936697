composition hr {
  border-top: 1px solid lightgrey;
}

composition .expense-sub {
  font-weight: 400 !important;
}

composition .form-submit-buttons {
  padding: 15px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

composition .contract-icon {
  padding-right: 5px;
}

composition .icon-free {
  -webkit-mask-image: url(/assets/images/custom_icons/icon_tariff.svg);
  -webkit-mask-size: 23px;
  -webkit-mask-repeat: no-repeat;
  background-color: var(--secondary-color);
  width: 25px;
  height: 25px;
  display: inline-block;
}