user-invite hr {
  border-top: 1px solid lightgrey;
}

user-invite h3,
h4 {
  font-weight: bold;
}

user-invite .fa-info-circle {
  width: 25px;
  height: 23px;
  display: inline-block;
}

user-invite .optional-headers {
  cursor: pointer;
}

user-invite .optional-headers[aria-expanded="true"] > i {
  transform: rotate(180deg);
}

user-invite .form-submit-buttons {
  padding: 15px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

user-invite .dialog-buttons {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

user-invite .dialog-btn-finish {
  margin-top: 10px;
}

user-invite .modal-body {
  text-align: center
}

user-invite .dropdown-container {
  position: relative;
}

.fa-info-circle + .tooltip > .tooltip-inner {
  background-color: var(--grayscale---basic--white);
  border: 1px solid var(--grayscale---gray-900);
  color: var(--grayscale---gray-900);
  text-align: left;
  font-size: 13px;
}

user-invite table {
  display: block;
  max-height: 300px;
  overflow-y: auto
}

user-invite th, user-invite td {
  text-align: center
}

user-invite .col-org-name {
  text-align: left;
  font-weight: bold;
}

user-invite .col-empty {
  width: 50px;
}

user-invite .row-no-line {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

user-invite .col-remove {
  padding-left: 30px !important
}
 
user-invite .fa-times:before {
  color: var(--error---error-500)
}