credentials-list .uc-drop-list {
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: 309px;
}

credentials-list .uc-drop-item {
  border-bottom: 1px solid #cccccc;
}

credentials-list .add-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

/*
  Multiselect Specific CSS - Move to Component
*/

credentials-list .multiselect-container > li > a > label:hover {
  background-color: var(--hover2);
  color: var(--grayscale---basic--white) !important;
}

credentials-list .dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: var(--hover2);
  color: var(--grayscale---basic--white);
}

credentials-list .multiselect-group {
  border-bottom: 1px #ccc solid;
  margin-top: 8px;
}

credentials-list .multiselect-container  > li > a {
  padding: 0;
}

credentials-list .input-group-addon {
  height: 34px;
  border-color: var(--secondary-color) !important;
}

credentials-list .input-group-btn > .btn {
  position: initial;
  height: 34px;
  border-color: var(--secondary-color);
}

credentials-list input[type="checkbox"],
input[type="radio"] {
  margin: 3px 0 0;
}

credentials-list .dropdown-menu > .active > a {
  background-color: var(--secondary-color);
}

credentials-list .dropdown-menu > .active > a > label {
  color: var(--grayscale---basic--white) !important;
}

/*
  END Multiselect Specific CSS - Move to Component
*/