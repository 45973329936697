/* You can add global styles to this file, and also import other style files */
/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

html {
  box-sizing: border-box;
}
body {
  height: 100%;
  margin: 0;
  overflow-x: initial;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//@import "assets/css/styles.css";
@import "assets/css/menu.css";

//@import "assets/css/login.css";
@import "assets/css/colors.css";
@import "assets/css/calendar-navigator.css";
@import "assets/css/realtime.css";
@import "assets/css/ga-component-container.css";
@import "assets/css/power-demand-chart.css";
@import "assets/css/power-factor-chart.css";
@import "assets/css/consumption-chart.css";
@import "assets/css/comparative-chart.css";
@import "assets/css/expense.css";
@import "assets/css/goal.css";
@import "assets/css/source-selector.css";
@import "assets/css/alerts.css";
@import "assets/css/meter.css";
@import "assets/css/dashboard.css";
@import "assets/css/export.css";
@import "assets/css/user-manager.css";
@import "assets/css/user-invite.css";
@import "assets/css/contract.css";
@import "assets/css/branch.css";
@import "assets/css/panel.css";
@import "assets/css/supervisor.css";
@import "assets/css/group.css";
@import "assets/css/measuring-points.css";
@import "assets/css/organization.css";
@import "assets/css/tariff-panel.css";
@import "assets/css/consumption-profile.css";
@import "assets/css/demand-optimal.css";
@import "assets/css/outlier-detection.css";
@import "assets/css/power-factor-correction.css";
@import "assets/css/comparative-analysis.css";
@import "assets/css/groupmeter-list.css";
@import "assets/css/tables.css";
@import "assets/css/datasource-list.css";
@import "assets/css/organization-selector.css";
@import "assets/css/datasource-selector.css";
@import "assets/css/company-selector.css";
@import "assets/css/organization-selector-multi.css";
@import "assets/css/proinfa-list.css";
@import "assets/css/pguarantee-list.css";
@import "assets/css/companies.css";
@import "assets/css/regulated-list.css";
@import "assets/css/free-tariff-list.css";
@import "assets/css/composition-list.css";
@import "assets/css/energy-bill-metadata-list.css";
@import "assets/css/energy-bill-list.css";
@import "assets/css/energy-bill-breadcrumb.css";
@import "assets/css/composition.css";
@import "assets/css/tariff-selector.css";
@import "assets/css/composition-selector.css";
@import "assets/css/item-selector.css";
@import "assets/css/free-market.css";
@import "assets/css/optimal-seasoning.css";
@import "assets/css/economy.css";
@import "assets/css/task-manager.css";
@import "assets/css/loading.css";
@import "assets/css/export-bills.css";
@import "assets/css/welcome.css";
@import "assets/css/consuming-unit-list.css";
@import "assets/css/consuming-unit.css";
@import "assets/css/credentials-list.css";
@import "assets/css/regitrations.css";
@import "assets/css/uploads.css";
@import "assets/css/appliances.css";
@import "assets/css/modal-apliance-cluster.css";
@import "assets/css/custom.css";
@import "assets/css/themes/theme-ga-1/custom.css";

.success {
  background: var(--success---success-500);
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
}

.warning {
  background: var(--warning---warning-500) !important;
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
}

.danger {
  background: var(--error---error-500) !important;
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
}

.has-error {
  border: 1px solid var(--error---error-500);
}

.ga-input-error:before {
    content: "\f06a" !important;
    position: absolute !important;
    font-family: "Font Awesome 6 Pro" !important;
    font-size: 14px !important;
    color: var(--error---error-500) !important;
    bottom: 10px !important;
    right: 10px !important;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: visible !important;
}
.mat-dialog-content {
  margin: 0 0px !important;
  padding: 0 0px !important;
  max-height: 70vh !important;
}

.dialog .panel-heading {
  background: var(--secondary-color);
  padding: 5px;
  .modal-title {
    color: #fff;
  }
}

/* .mat-dialog-container{
  padding-top: 0px!important;
  border-radius: 3px!important;
}

.mat-dialog-title{
  margin: 0 -24px 20px!important;
  background-color: var(--secondary-color);
  color: var(--grayscale---basic--white);
  padding: 6px 15px;
}
 */
.mat-dialog-title .close{
  color: var(--grayscale---basic--white)!important;
  position: absolute;
  right: 15px;
  margin: 0;
  opacity: 1;
}

.mat-dialog-title .close:hover {
  opacity: .6;
}

.dropzone{
  margin-top: 30px;
  border: 2px dashed var(--grayscale---gray-400);
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  height: 150px;
  text-align: center;
}

.dropzone .fa-cloud-upload-alt{
  font-size: 60px;
  color: var(--grayscale---gray-400);
}

.dropzone .upload-text{
  font-size: 14px;
  color: var(--grayscale---gray-400);
}

input#fileDropRef{
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 150px;
}

.tooltipHeader {
  text-align: left;
  font-size: 1.3rem;
  padding-left: 5px;
  margin: 0px;
}

.tooltipHeader span {
  font-size: 1.3rem;
  font-weight: 600;
}

.division-header {
  margin: 5px;
}

.tooltipTittle {
  text-align: left;
  color: var(--primary-color);
  font-size: 1.3rem;
  padding-left: 5px;
  font-weight: 600;
  margin: 0 !important;

}

.tooltipSerie {
  text-align: left;
  font-size: 1.2rem;
  padding-left: 5px;
  font-weight: 600;
  margin: 0 !important;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  border: 4px solid var(--primary-color);;
  min-height: 20%;
}

/* width */
body::-webkit-scrollbar {
  width: 16px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: var(--grayscale---gray-400);
  border-radius: 10px;
  border: 4px solid #f1f1f1;
  min-height: 20%;
}

.mat-autocomplete-panel {
  min-width: calc(100%) !important;
}
