.dataTables_wrapper table.child-row thead{
  visibility: collapse;
}

panel-list .meter-group{
  margin-bottom: 25px;
}

panel-list  .side-panel-ga-content {
    padding-top: 26px;
}

panel-list  .side-panel-ga {
    right: -288px;
    margin-top:0px;
}

panel-list  .side-panel-ga-content.scrollbox {
    height: 100%;
}

panel-list  .side-panel-ga-content{
  height: 100%;
}

panel-list  li:last-child .meter-group-header{
  border-bottom: none;
}

panel-list  .meter-group-header{
  border-bottom:  2px solid #eee;
  margin-right: -5px;
  margin-left: -5px;
  display: flex;
}

panel-list  .meter-group-header .fa-ellipsis-vertical {
  color: #ccc;
  margin-right: -10px;
}

panel-list  .meter-group .meter-group{
  margin-bottom: 0px;
}

panel-list  .meter-group .panel-ga > .panel-body{
  height: auto;
  min-height: 50px;
}

panel-list  .meter-row, .meter-group-header{
  padding: 15px 5px;
  font-size: 17px;
  color: var(--grayscale---gray-700);
}


panel-list  .meter-group-header .btn-group>.btn:first-child {
  border-bottom-right-radius:0;
  border-top-right-radius:0;
  border-right: 2px white solid;
}

panel-list  .meter-group-header .btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius:4px;
  border-top-right-radius:4px;
}

/*panel-list  .btn-group>.btn:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}*/
panel-list button.multiselect{
  padding: 3px 7px;
}

panel-list .btn-group-left-caret .btn.dropdown-toggle.beforetoggle {
    border-radius: 4px 0 0 4px;
}

panel-list .meter-group-header .btn{
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--grayscale---gray-600);
  color:var(--grayscale---basic--white);
  font-size: 17px;
}

panel-list .meter-group-header .dropdown-menu>li>a {
  padding-left: 10px;
  font-weight: bold;
}

panel-list .meter-group-header .dropdown-menu>li>a:hover{
  background-color: var(--dataviz---md--green);
  color: var(--grayscale---basic--white);
}

panel-list .btn-group .btn+.dropdown-backdrop+.btn {
    margin-left: -1px;
}

panel-list .meter-row-meter-name{
  background-color: var(--primary-color);
}
panel-list .meter-group-name{
  background-color: var(--grayscale---gray-600);
}

panel-list .meter-row-label{
  color: #ccc;
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 100;
}

panel-list .handle{
  cursor: crosshair;
  font-size: 24px;
  color: #cccccc;
}

panel-list table.table-branches thead th {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

panel-list table.table-branches tbody td {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

panel-list table.table-branches.no-footer {
    border-bottom: 1px solid var(--grayscale---gray-200);
}

panel-list .table-meters{
  margin-bottom: 0px;
}

panel-list .table-meters td{
  background-color: var(--grayscale---gray-100);
}

panel-list table.table-branches  tbody > tr {
  height: 73px;
}

panel-list  table.table-branches  td {
 vertical-align: middle;
}

panel-list table .fa-ellipsis-vertical {
  color: var(--grayscale---gray-400);
  font-size: 18px;
  margin-right: 2px;
}

panel-list .fa-chevron-down{
  float: left;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
  display: inline-table;
  max-width: 140px;
  position: absolute;
}

panel-list .fa-ellipsis-v{
  font-size: 18px;
}

panel-list .loading-component{
  background-color: var(--grayscale---basic--white);
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: left;
  justify-content: left;
  align-items: left;
}

panel-list input.inspection-day {
  width: 70px;
  display: inline-block;
  margin-left: 16px;
}

.with-tabs meter-list .side-panel-ga{
  margin-top: -100px;
}

panel-list .loading-component-container{
  width: 50px;
  min-height: 50px;
  text-align: left;
}

panel-list .panel-ga .loading-component {
  background: none;
  justify-content: left;
}

panel-list realtime .power-container {
  min-height: 20px;
}

panel-list .add-branch-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.mobile panel-list .add-branch-button {
  top: 90px;
}

panel-list .branch-panels {
  z-index: 9;
  margin-top: -150px;
}

.mobile panel-list .branch-panels {
  z-index: 9;
  margin-top: -165px;
}


panel-list #loading-component-modal {
  margin-top: -15px;
  margin-left: -30px;
}

panel-list .meter a {
    color: var(--grayscale---basic--white);
    text-decoration: none;
}

panel-list .fa-lightbulb{
    background-color: var(--alert---alert-500);
    width: 40px;
    border-radius: 30px;
    color: var(--grayscale---basic--white);
    font-size: 30px;
    text-align: center;
    height: 40px;
    padding-left: 2px;
    padding-top: 5px;
}

panel-list label.checkbox{
  display: inline-block !important;
  left: -25px;
  top: -4px;
  z-index: 1;
  font-size: 14px;
  padding-right: 0px !important;
}
panel-list .cbx{
  z-index: 0;
  float: left;
  border: 1px solid var(--primary-color);
}
panel-list .active .cbx{
  border-color: transparent;
  background: var(--primary-color);
  animation: jelly 0.6s ease;
}
panel-list .active .cbx:after{
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

panel-list ul{
  z-index: 60 !important;
  width: 275px;
}

panel-list input[type="checkbox"]{
  visibility: hidden;
}

/* panel-list td[class*="measurement-points-"]{
  padding-left: 16px !important;
}

panel-list td[class*="power-now-"]{
  font-size: 14px !important; 
  color: var(--grayscale---gray-500) !important;
}

panel-list td[class*="power-factor-"]{
  padding-left: 20px !important;
}

panel-list td[class*="consumption-"]{
  text-align: right !important; 
} */

panel-list td{
  padding-right: 10px !important;
  padding-left: 10px !important;
}

panel-list th{
  padding-right: 20px;
}

panel-list thead > tr > th:first-child{
  text-align: left !important;
  padding-right: 10px;
}

panel-list td:first{
  text-align: left;
}

panel-list realtime > .realtime-component > .row{
  height: unset !important;
  margin-bottom: 0!important;
}

panel-list .power-container{
  text-align: left;
}

panel-list .power-container>.row>.col-md-12{
  padding-left: 0px;
}

panel-list .dataTables_wrapper .row {
  margin-right: -15px;
}

panel-list .power-container  .row{
  margin-bottom: 0!important;
  margin-right: 0!important;
  margin-left: 0!important;
}

panel-supervisor .row-values > div{
  padding-left: 0px;
  padding-top: 3px;
  overflow: hidden;
}

panel-supervisor .power-container{
  padding-left: 0px;
}
