@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,400italic,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:200,300,400,400italic,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:200,300,400,400italic,700);

body {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
}

.svg2 path {
	fill: var(--secondary-color);
}
.svg2 use {
	fill: var(--secondary-color);
}
.svg1 path {
	fill: var(--secondary-color);
}
.svg1 use {
	fill: var(--secondary-color);
}
/*
.ga-menu {
  padding-top: 80px;
  position: fixed;
}


.header{
  position: fixed;
    width: 100%;
}

.ga-content {
  padding-top: 80px;
}*/
.content-wrapper {
	min-height: calc(100vh - 80px);
}
.float-left {
	float: left;
}

.float-right {
	float: right;
}

.margin-top-16 {
	margin-top: 16px;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.sticky-after-top {
	position: -webkit-sticky;
	position: sticky;
	top: 80px;
	z-index: 3;
}

.header .branding {
	width: 224px;
}

.header .branding img{
	max-height: 80px;
}

.content-wrapper,
.compact.nav-toggled .content-wrapper {
	margin-left: 224px;
}

.header .branding .logo-icon {
	width: 34px;
	height: 52px;
}

body.nav-toggled .header .branding .logo-icon {
	width: 28px;
	height: 42px;
}

.header .branding .logo-icon_letter {
	width: 78px;
	height: 14px;
}

.compact:not(.nav-toggled) .header .branding,
.mobile.nav-toggled .header .branding,
.nav-toggled:not(.compact):not(.mobile) .header .branding {
	width: 48px;
}

.nav-toggled:not(.compact):not(.mobile) .content-wrapper {
	margin-left: 48px;
}

.mobile:not(.nav-toggled) .header .branding {
	width: 0px;
}

.module {
	border: 1px solid #f3f3f3;
	border-bottom-width: 1px;
	border-bottom-width: 2px;
	background: var(--grayscale---basic--white);
	background-clip: border-box;
	margin-bottom: 30px;
	position: relative;
	border-radius: 4px;
	background-clip: padding-box;
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	padding-top: 8px;
}

.sticky-hide {
    clip-path: inset(0 0 0 0); 
}

.dashboard-widget {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	background-color: var(--grayscale---gray-100);

	.content-wrapper.live-wrapper {
		min-height: 256px;
		padding-bottom: 0px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 24px;
	}
	
	.live-wrapper .live-widget, .goal-widget {
		min-height: 256px;
		position: relative;
	}
	.live-widget {
		width: calc(60% - 12px);
	}
	.goal-widget {
		width: calc(40% - 12px);
	}
	
	@media (max-width: 1300px) {
		.live-widget, .goal-widget {
			width: calc(50% - 12px);
		}
	}

	@media (max-width: 1200px) {
		.live-widget, .goal-widget {
			width: 100%;
		}
	}

	.dash-toolbar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		min-height: 50px;
    	padding: 0px;
		border: 1px solid var(--grayscale---gray-200);
		border-radius: 4px;
		background-color: var(--grayscale---basic--white);
		z-index: 11;
	}

	.toolbar-container {
		display: flex;
		flex-direction: column;
		margin-top: -24px;

		z-index: 11;
		position: sticky;
		top: 80px;
	}

	.toolbar-div {
		height: 24px;
		background-color: var(--grayscale---gray-100);
		z-index: 11;
	}
}

.h-divider {
	margin-top: 5px;
	margin-bottom: 5px;
	height: 1px;
	width: 100%;
	border-top: 1px solid var(--grayscale---gray-200);
}

.topbar {
	border-bottom: 1px solid var(--grayscale---gray-200);
}

.toolbar {
	min-height: 48px;
	padding: 0px;
	border-bottom: 1px solid var(--grayscale---gray-200);
	border-top: 1px solid var(--grayscale---gray-200);
	background-color: var(--grayscale---basic--white);
	position: -webkit-sticky;
	position: sticky;
	top: 80px;
	z-index: 9;
}

.fixed {
	position: fixed;
	top: 0;
	left: 0;
}

.highcharts-tooltip {
	z-index: 9999 !important;
}

body:not(.nav-toggled) .header .branding .logo {
	padding: 0px;
}

.header .branding .logo {
	padding: 0px;
}

.side-panel-ga {
	z-index: 1;
	width: 256px;
	will-change: transform;
	transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
	position: absolute;
	right: -256px;
	background-color: var(--grayscale---basic--white);
	border-left: 1px solid var(--grayscale---gray-200);
	height: calc(100vh - 130px);
}

.dashboard-widget .side-panel-ga {
	margin-top: -54px;
}

.mobile .side-panel-ga {
	display: none;
}

.side-panel-ga-content .container-fluid {
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--grayscale---gray-200);
}

.side-panel-ga-content {
	background-color: var(--grayscale---basic--white);
	height: 100%;
	padding-top: 26px;
}

.side-panel-ga-content.scrollbox {
	height: 87%;
	padding-top: 26px;
}

.side-panel-ga-header .title {
	color: var(--success---success-700);
	font-size: 16px;
	text-align: center;
	font-weight: 700;
	line-height: 21.8px;
}

.side-panel-ga-header {
	display: flex;
	align-items: center;
	padding-left: 13px;
	padding-right: 13px;
}

.side-panel-ga-header .title {
	margin-left: 8px;
}
.side-panel-ga-header .close {
	position: absolute;
	right: 8px;
}

.toolbar-content .side-panel-ga-header {
	display: inline-flex;
}

.toolbar-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.side-panel-ga.side-panel-ga-open {
	transform: translateX(-256px);
	display: block;
	z-index: 100000;
}
.mobile .side-panel-ga.side-panel-ga-open {
	transform: translateX(-273px);
}

.mobile .dashboard-widget .side-panel-ga.side-panel-ga-open {
	transform: translateX(-256px);
	margin-top: 20px;
}

.is-sticky .side-panel-ga.side-panel-ga-open {
	right: -32px;
}
body.compact.mobile .is-sticky .side-panel-ga.side-panel-ga-open {
	right: -256px;
}
body.nav-toggled .is-sticky .side-panel-ga.side-panel-ga-open {
	right: -208px;
}
/*
.compact.mobile .side-panel-ga.side-panel-ga-open {
  transform: translateX(-32px);
}*/

.side-panel-ga-header {
	min-height: 50px;
	border: 1px solid var(--grayscale---gray-200);
}

body.mobile .navbar-tools {
	display: none;
}

/*Menu Selector*/

.dropdown-meter {
	margin-top: 5px;
}

body.mobile.nav-toggled .dropdown-meter {
	margin-left: 16%;
}

body.mobile .dropdown-meter {
	margin-left: 8%;
}

.btn-back {
	height: 24px;
	background-color: var(--primary-color);
	color: var(--grayscale---basic--white);
	padding-top: 1px;
	font-weight: 400;
	font-size: 14px;
	margin-left: 7px;
}

.btn-back .fa-angle-left {
	font-size: 18px;
	font-weight: bold;
	margin-left: -4px;
	margin-right: 6px;
	padding-top: 1px;
}

.btn-back:hover {
	background-color: var(--secondary-color);
	color: var(--grayscale---basic--white);
}

/* Menu Selector End */

/*.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}
.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}
.fix-search .top-header {
  background: #eee;
}

.search {
  position: absolute;
  top: 155px;
  left: 20px;
  right: 20px;
}
.search input {
  width: 265px;
  transition: width 0.2s;
  -webkit-appearance: none;
}
.fix-search .search {
  position: fixed;
  top: 10px;
}
.fix-search .search input {
  width: 250px;
}

.top {
  height: 250px;
  padding-top: 40px;
  position: relative;
}*/

.pace .pace-progress {
	z-index: 6;
	right: 100%;
	width: 100%;
	height: 2px;
}

/**Scroll Box**/
.scrollbox {
	overflow: auto;
	width: 280px;
	max-height: 360px;
	padding: 0 5px;
	margin-top: 8px;
}

.scroll-side-panel {
	overflow: auto;
}

.scrollbox a {
	padding: 2px;
}

.scrollbox a:hover {
	background-color: #d3d3d3;
}

.scrollbox a:focus,
.scrollbox a:hover {
	color: #333;
	text-decoration: none;
}

.track3 {
	width: 10px;
	background: rgba(0, 0, 0, 0);
	margin-right: 2px;
	border-radius: 10px;
	-webkit-transition: background 250ms linear;
	transition: background 250ms linear;
}

.track3:hover,
.track3.dragging {
	background: var(--grayscale---gray-100); /* Browsers without rgba support */
}

.handle3 {
	width: 7px;
	right: 0;
	/*background: #999;*/
	background: var(--grayscale---gray-400);
	filter: alpha(opacity=40);
	-moz-opacity: 0.5;
	border-radius: 7px;
	-webkit-transition: width 250ms;
	transition: width 250ms;
}

.dashboard-panel .handle3 {
	right: 8px;
}

.track3:hover .handle3,
.track3.dragging .handle3 {
	width: 10px;
}

.side-panel-gadgets {
	min-width: 126px;
	border-top: 0px;
	border-bottom: 0px;
	border-right: 0px;
	cursor: pointer;
	display: flex !important;
}

.side-panel-gadgets:hover {
	background-color: var(--grayscale---gray-400);
}

.modal-body {
	padding: 15px 30px;
}

.red-modal .modal-header {
	background-color: var(--error---error-500);
}

.center {
	text-align: center;
}
.left {
	text-align: left;
}
.right {
	text-align: right;
}

.btn-color-ga {
	font-family: "Roboto";
	font-size: 14px;
	border: 0px;
	font-weight: 600;
	border: 1px solid var(--primary-color);
}

.btn-default-ga {
	font-size: 14px;
	font-weight: 500;
	color: var(--primary-color);
	border-color: var(--primary-color);
}

/* .btn-default-ga:hover {
	font-size: 14px;
	font-weight: 500;
	color: var(--primary-color);
	border-color: var(--primary-color);
	background-color: var(--grayscale---gray-100);
} */

.btn-default-ga:hover,
.btn-default-ga:focus,
.btn-default-ga:active,
.btn-default-ga.active,
.btn-default-ga.focus,
.btn-default-ga.hover {
	border-color: var(--hover1);
	color: var(--hover1);
	box-shadow: none;
	outline: unset;
}

.btn-slim {
	padding-top: 2px;
	padding-bottom: 2px;
}

.opacity {
	filter: alpha(opacity=20);
	opacity: 0.2;
	-moz-opacity: 0.2;
	-webkit-opacity: 0.2;
}

.no-padding {
	padding: 0;
}

.title i.fa-ellipsis-v {
	float: right;
	font-size: 20px;
}

@media (max-width: 1197px) {
	.compact .content-wrapper {
		margin-left: 48px;
	}
}

@media (max-width: 769px) {
	.compact .content-wrapper {
		margin-left: 0px;
	}
	.content-wrapper,
	.compact.nav-toggled .content-wrapper {
		margin-left: 48px;
	}

	.toolbar-content .float-right {
		width: 100%;
		background-color: var(--grayscale---basic--white);
		border-bottom: 1px solid var(--grayscale---gray-200);
		border-top: 1px solid var(--grayscale---gray-200);
	}

	.toolbar-content .float-right .side-panel-ga-header {
		padding-left: 8px;
		padding-right: 8px;
	}

	.toolbar-content .float-right .side-panel-ga-header .title {
		font-size: 14px;
	}

	div.content-wrapper.content-wrapper-dashboard {
		padding-top: 52px;
	}
}

h3.subheading {
	color: var(--primary-color);
	font-size: 17px;
	font-weight: bold;
	margin-top: 0px;
}

.overflow-y-hidden {
	overflow-y: hidden;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.form-control {
	border-radius: 4px;
	height: 40px;
	line-height: 19px;
	padding: 10px 14px 10px 14px !important;
    box-sizing: border-box !important;
}

.form-control::placeholder {
	font-weight: 400;
	color: rgba(0, 0, 0, 0.42);
}

.form-control:focus {
	border-color: var(--secondary-color);
}

.relative {
	position: relative;
}

.page-title {
	font-weight: bold;
}

.subtitle {
	font-size: 14px;
	font-weight: bold;
}

.header .user-container img {
	border-radius: 20px;
}

form-builder input[type="number"],
form-builder input[type="text"] {
	max-width: 320px;
	min-width: 65px;
}
form-builder .label-container label {
	font-weight: 400;
	font-size: 14px;
}

form-builder h4.page-title {
	font-weight: 800;
	font-size: 14px;
}

form-builder {
	margin-top: 8px;
	display: block;
}

form-builder .inline-field {
	margin-top: 20px;
	float: left;
	margin: -3px;
}

form-builder .inline-field-proinfa {
	margin-top: 20px;
	float: left;
	margin: 0px 0px -16px 0px;
	width: 100%;
}

form-builder .inline-field-free {
	margin-top: 20px;
	float: left;
	margin: 0px 0px -16px 0px;
	width: 100%;
}

form-builder .month-input {
	width: 166px !important;
	-moz-appearance: textfield;
}

form-builder .inline-field-first:before {
	display: block;
	content: "Distribuição";
	padding: 0px 0px 10px 15px;
}

form-builder .inline-field .form-group {
	margin-bottom: 0px;
}

form-builder label.inline-first {
	display: inline-table;
	border-radius: 4px 0px 0px 0px;
}

form-builder label.inline-last {
	display: inline-table;
	border-radius: 4px 0px 0px 0px;
}

form-builder label.inline {
	float: left;
	border: 1px solid #52a247;
	display: inline-table;
	min-width: 95px;
	height: 34px;
	padding: 6px;
}

form-builder label.inline-proinfa {
	min-width: 95px;
}
form-builder .no-radius {
	border-radius: 0px;
}

form-builder input[type="radio"] {
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 15px;
	white-space: nowrap;
	overflow: hidden;
	width: 15px;
	height: 15px;
	float: left;
}

form-builder label.label-radio {
	padding-top: 3px;
	width: 183px;
	height: 34px;
}

.page-tabs {
	font-size: 14px;
	/*padding-bottom: 32px;*/
	z-index: 1;
	position: relative;
}

.page-tabs .page-tab {
	display: inline-block;
	cursor: pointer;
	text-align: center;
}

.page-tabs .page-tab:nth-child(n + 2) {
	margin-left: 16px;
}

.page-tabs .page-tab.active {
	border-bottom: 4px solid var(--primary-color);
	font-weight: 600;
}

.panel-footer-button {
	/*border-top: 1px solid var(--grayscale---gray-200);*/
	padding-top: 16px;
}

.less-tiny-margin-botton {
	margin-bottom: -1.4%;
}

.btn-group:hover .btn-color-ga {
	background-color: var(--hover1);
}

.btn-primary:active,
.btn-primary:focus {
	background-color: var(--primary-color);
	border: none;
}
.btn-primary:hover {
	background-color: var(--primary-color);
}
.toggle.btn.btn-primary {
	border: 1px solid var(--secondary-color);
}

.btn-add-ga,
.btn-add-ga:focus {
	font-family: "Roboto Condensed";
	background-color: var(--grayscale---basic--white);
	color: var(--primary-color);
	font-size: 17px;
	font-weight: 800;
	border: 1px var(--primary-color) solid;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 8px;
	padding-left: 8px;
	width: 33px;
	line-height: 17px;
}

.btn-add-ga .fa {
	vertical-align: text-bottom;
	margin-right: 0px;
}

.btn-outline-ga,
.btn-outline-ga:focus {
	background-color: var(--grayscale---basic--white);
	color: var(--primary-color);
	font-size: 17px;
	font-weight: 800;
	border: 1px var(--primary-color) solid;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-right: 8px;
	padding-left: 8px;
}

.btn-add-ga:hover {
	background-color: var(--primary-color);
	color: var(--grayscale---basic--white);
	border: 1px white solid;
}

.btn-outline-ga:hover {
	background-color: var(--primary-color);
	color: var(--grayscale---basic--white);
	border: 1px white solid;
}

/*remove button*/
.btn-remove-ga {
	font-family: "Roboto Condensed";
	background-color: var(--grayscale---basic--white);
	color: var(--error---error-500);
	font-size: 17px;
	font-weight: 800;
	border: 1px var(--error---error-500) solid;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 8px;
	padding-left: 8px;
	width: 33px;
	line-height: 17px;
}

.btn-remove-ga:hover {
	background-color: var(--error---error-500);
	color: var(--grayscale---basic--white);
	border: 1px white solid;
}

.btn-remove-ga .fa {
	vertical-align: text-bottom;
	margin-right: 0px;
}

tags-input .tags {
	border-radius: 4px;
	border-color: var(--secondary-color);
}

tags-input .tags .input {
	width: 100%;
}

.panel {
	margin-bottom: 24px;
}

tags-input .autocomplete .suggestion-item.selected,
tags-input .autocomplete .suggestion-item.selected em {
	background-color: var(--secondary-color);
}

tags-input .autocomplete .suggestion-item.selected.match-meter,
tags-input .autocomplete .suggestion-item.selected.match-meter em {
	background-color: var(--secondary-color);
}

tags-input .autocomplete .suggestion-item.selected.match-group,
tags-input .autocomplete .suggestion-item.selected.match-group em {
	background-color: var(--grayscale---gray-700);
}

tags-input .tags .tag-item.tag-group {
	background: var(--grayscale---gray-700);
}

tags-input .tags .tag-item.tag-meter {
	background: var(--secondary-color);
}

.sindu_dragging {
	background-color: var(--primary-color);
}

.sindu_dragger {
	background-color: var(--secondary-color);
}

/*div.DTCR_pointer {
  margin-left: -10px;
  width: 0;
  height: 0 !important;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--primary-color) transparent transparent transparent;
  background: transparent;
}*/

.dropdown-menu i.fa,
.dropdown-menu i.far,
.dropdown-menu i.fas,
.dropdown-menu i.fa-regular,
.dropdown-menu i.fa-solid {
	margin-right: 8px;
	width: 15px;
	text-align: center;
}

.content-wrapper {
	padding-top: 0px;
	z-index: 10;
}

/*Navbar*/
.ga-navbar {
	padding: 24px 0;
	display: flex;
	width: 100%;
}

.ga-navbar h3 {
	margin: 0;
	height: 19px;
	font-size: 16px;
	font-weight: 400;
}

.navbar-default {
	background-color: transparent;
}

.navbar-default a {
	color: var(--grayscale---gray-700);
}

.ga-navbar .navbar-content {
	height: 36px;
	align-self: center;
	align-items: center;
	justify-content: space-between;
	display: flex;
	width: 100%;
}

.ga-navbar .margin-bottom-sm {
	margin-bottom: 0px;
	padding: 0px;
}

.ga-navbar .btn-color-ga {
	font-size: 14px;
}

.ga-navbar .btn .fa,
.ga-navbar a.btn .fa {
	margin-right: 5px;
	margin-left: 5px;
}

.add-button {
	position: absolute;
	left: 0;
	top: 130px;
	right: 0px;
	display: flex;
	justify-content: flex-end;
	padding-right: 30px;
	height: 33px;
}

.description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.description .texts {
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
}

.description .texts .title {
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
	color: #404040;
}

.description .texts .subtitle {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #737373;
	margin-top: 4px;
}

.description .action-buttons {
	padding-bottom: 24px;
	display: flex;
}

.mr-4 {
    margin-right: 20px;
}

div.open-side-panel {
	margin-top: -24px;
	width: calc(100% - 279px) !important;
}

nav.open-side-panel {
	width: calc(100% - 255px) !important;
}

.menu-include {
	top: 0px;
	display: inline;
}

.welcome-user {
	color: var(--grayscale---gray-200);
	padding-right: 5px;
}

.header .user-container .dropdown-menu > li > a:hover {
	background: var(--grayscale---gray-200) !important;
}

#main-nav {
	height: calc(100vh - 80px);
	position: relative;
}

#main-nav.scroll-menu {
	overflow-y: auto;
}

/*.compact:not(.nav-toggled) .header .branding .logo .nav-label,
.mobile .header .branding .logo .nav-label,
.nav-toggled:not(.compact):not(.mobile) .header .branding .logo .nav-label {

}*/
#main-nav .footer-sub-menu {
	padding-bottom: 5px;
	position: absolute;
	bottom: 40px;
	width: 100%;
	background-color: var(--grayscale---gray-700);
}

#main-nav.scrollmenu {
	overflow-y: auto;
	overflow-x: hidden;
}

#main-nav .footer-sub-menu {
	padding-bottom: 5px;
	position: absolute;
	bottom: 40px;
	width: 100%;
	background-color: var(--grayscale---gray-700);
}

#main-nav .footer-menu {
	padding-bottom: 5px;
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: var(--grayscale---gray-700);
}

#main-nav.scroll-menu .footer-menu {
	margin-bottom: 0px;
	padding-bottom: 0px;
	position: sticky;
}

.branding {
	display: flex;
	align-items: center;
	justify-items: middle;
	justify-content: center;
}

#main-nav.scrollmenu .footer-menu {
	margin-bottom: 0px;
	padding-bottom: 0px;
	position: sticky;
}

/* width */
.main-nav-wrapper ::-webkit-scrollbar {
	width: 7px;
}

/* Track */
.main-nav-wrapper ::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

/* Handle */
.main-nav-wrapper ::-webkit-scrollbar-thumb {
	background: var(--grayscale---gray-400);
	border-radius: 10px;
}

#main-nav .footer-menu img {
	margin-bottom: 14px;
	margin-top: 10px;
	max-width: 65%;
}

#main-nav .footer-menu > li > span > div {
	padding-left: 20px;
}

#main-nav .footer-menu-120 {
	width: 120px;
}

.small-footer-logo {
	display: none;
}
.compact #main-nav .small-footer-logo,
.mobile #main-nav .small-footer-logo,
.nav-toggled #main-nav .small-footer-logo {
	display: inline-block;
}
.compact #main-nav .logo-icon-ga-small,
.mobile #main-nav .logo-icon-ga-small,
.nav-toggled #main-nav .logo-icon-ga-small {
	display: inline-block;
}
.compact .logo .small-footer-logo,
.mobile .logo .small-footer-logo,
.nav-toggled .logo .small-footer-logo {
	display: inline-block;
}
.compact .logo .logo-icon-ga-small,
.mobile .logo .logo-icon-ga-small,
.nav-toggled .logo .logo-icon-ga-small {
	display: inline-block;
}
/***/
.logo-icon-ga-small {
	display: none;
}

.compact #main-nav .full-footer-logo,
.mobile #main-nav .full-footer-logo,
.nav-toggled #main-nav .full-footer-logo {
	display: none;
}
.compact #main-nav .logo-icon-ga-full,
.mobile #main-nav .logo-icon-ga-full,
.nav-toggled #main-nav .logo-icon-ga-full {
	display: none;
}
.compact .logo .full-footer-logo,
.mobile .logo .full-footer-logo,
.nav-toggled .logo .full-footer-logo {
	display: none;
}
.compact .logo .logo-icon-ga-full,
.mobile .logo .logo-icon-ga-full,
.nav-toggled .logo .logo-icon-ga-full {
	display: none;
}

/**/

.mobile .page-tab {
	font-size: 15px;
}

.mobile .table-meters .dropdown-menu-right {
	right: auto;
	left: 0;
}

.side-panel-ga .loading-component {
	background-color: var(--grayscale---basic--white);
	filter: alpha(opacity=90);
	opacity: 0.9;
	-moz-opacity: 0.9;
	-webkit-opacity: 0.9;
	position: absolute;
	z-index: 99;
	width: 100%;
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.side-panel-ga .loading-component .spinner {
	padding: 0;
	margin: 0;
}

.loading-component {
	background-color: var(--grayscale---basic--white);
	filter: alpha(opacity=90);
	opacity: 0.9;
	-moz-opacity: 0.9;
	-webkit-opacity: 0.9;
	position: absolute;
	z-index: 99;
	width: 100%;
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.loading-component .spinner {
	padding: 0;
	margin: 0;
}
.search-box i {
	top: 12px;
	left: 12px;
	font-size: 15px;
	position: absolute;
	color: var(--grayscale---gray-400);
}
.search-input {
	padding-left: 35px !important;
}

.dataTables_filter,
.dataTables_length {
	display: none;
}

.dashboard-panel {
	z-index: 90;
	margin-top: -20px;
	margin-bottom: 20px;
	top: 110px;
}

.chart-tooltip {
	background-color:  var(--grayscale---gray-800);
	color: var(--grayscale---basic--white);
	padding: 5px;
	border-radius: 5px;
	position: relative;
	bottom: 50px;
	right: 30px;

	font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    opacity: 0.9;
}

.chart-tooltip-arrow {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid var(--grayscale---basic--white);
	bottom: 0;
	top: 50px;
	position: absolute;
}
.chart-tooltip-arrow:after {
	content: " ";
	width: 0;
	height: 0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-top: 11px solid var(--grayscale---gray-800);
	position: absolute;
	top: -20px;
	left: -23px;
	z-index: -1;
}

.plot-line-tooltip {
	background-color: var(--grayscale---basic--white);
	border: 1px solid var(--grayscale---gray-700);
	padding: 5px;
	border-radius: 5px;
	position: relative;
	bottom: 20px;
}

.plot-line-tooltip-arrow {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid var(--grayscale---basic--white);
	bottom: 0;
	top: 24px;
	position: absolute;
	left: 3px;
}
.plot-line-tooltip-arrow:after {
	content: " ";
	width: 0;
	height: 0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-top: 11px solid var(--grayscale---gray-800);
	position: absolute;
	top: -10px;
	left: -11px;
	z-index: -1;
}
.plot-line-tooltip-arrow-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid var(--grayscale---basic--white);
	bottom: 0;
	top: -10px;
	position: absolute;
	left: 3px;
}
.plot-line-tooltip-arrow-up:after {
	content: " ";
	width: 0;
	height: 0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-top: 11px solid var(--grayscale---gray-800);
	position: absolute;
	top: -1px;
	transform: rotate(180deg);
	left: -11px;
	z-index: -1;
}

/**/
.cbx,
.rb {
	position: relative;
	margin-bottom: unset;
	width: 17px;
	height: 17px;
	border: 1px solid var(--grayscale---gray-200);
	border-radius: 3px;
	vertical-align: middle;
	transition: background 0.1s ease;
	cursor: pointer;
}

.rb {
	border-radius: 8px;
}

.cbx:after {
	content: "";
	position: absolute;
	top: 1px;
	left: 5px;
	width: 5px;
	height: 11px;
	opacity: 0;
	transform: rotate(45deg) scale(0);
	border-right: 2px solid var(--grayscale---basic--white);
	border-bottom: 2px solid var(--grayscale---basic--white);
	transition: all 0.3s ease;
	transition-delay: 0.15s;
}

.rb:after {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	width: 7px;
	height: 7px;
	opacity: 0;
	transform: rotate(45deg) scale(0);
	background: var(--grayscale---basic--white);
	border-radius: 5px;
	transition: all 0.3s ease;
	transition-delay: 0.15s;
}

.lbl {
	margin-left: 5px;
	vertical-align: middle;
	cursor: pointer;
}
.checkbox:checked ~ .cbx {
	border-color: transparent;
	background: var(--chartColor);
	animation: jelly 0.6s ease;
}

.checkbox:checked ~ .cbx.cbx-dataviz---lt--green {
	background: var(--dataviz---lt--green);
}
.checkbox:checked ~ .cbx.cbx-dataviz---md--blue {
	background: var(--dataviz---md--blue);
}
.checkbox:checked ~ .cbx.cbx-dataviz---lt--pink {
	background: var(--dataviz---lt--pink);
}
.checkbox:checked ~ .cbx.cbx-dataviz---dk--orange {
	background: var(--dataviz---dk--orange);
}
.checkbox:checked ~ .cbx.cbx-dataviz---dk--pink {
	background: var(--dataviz---dk--pink);
}
.checkbox:checked ~ .cbx.cbx-dataviz---md--yellow {
	background: var(--dataviz---md--yellow);
}
.checkbox:checked ~ .cbx.cbx-dataviz---dk--blue {
	background: var(--dataviz---dk--blue);
}
.checkbox:checked ~ .cbx.cbx-dataviz---green {
	background: var(--dataviz---md--green);
}
.checkbox:checked ~ .cbx.cbx-dataviz---red {
	background: var(--dataviz---red);
}
.checkbox:checked ~ .cbx.cbx-dataviz---lt--orange {
	background: var(--dataviz---lt--orange);
}
.checkbox:checked ~ .cbx.cbx-dataviz---md--purple {
	background: var(--dataviz---md--purple);
}
.checkbox:checked ~ .cbx.cbx-dataviz---lt--blue {
	background: var(--dataviz---lt--blue);
}
.checkbox:checked ~ .cbx.cbx-dataviz---dk--purple {
	background: var(--dataviz---dk--purple);
}
.checkbox:checked ~ .cbx.cbx-dataviz---brown {
	background: var(--dataviz---brown);
}

.checkbox:checked ~ .cbx:after {
	opacity: 1;
	transform: rotate(45deg) scale(1);
}
.checkbox.phase-aggregated:checked ~ .cbx {
	background-color: var(--chartColor);
}
.checkbox.phase-a:checked ~ .cbx {
	background-color: var(--dataviz---md--blue);
}
.checkbox.phase-b:checked ~ .cbx {
	background-color: var(--dataviz---lt--pink);
}
.checkbox.phase-c:checked ~ .cbx {
	background-color: var(--dataviz---dk--purple);
}
.checkbox.phase-generation:checked ~ .cbx {
	background-color: var(--dataviz---dk--orange);
}
.checkbox:checked ~ .rb {
	border-color: transparent;
	background: var(--grayscale---gray-700);
	animation: jelly 0.6s ease;
}
.checkbox:checked ~ .rb:after {
	opacity: 1;
	transform: rotate(45deg) scale(1);
}
.cntr {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	text-align: center;
}

.modal button.close {
	color: var(--grayscale---basic--white);
	opacity: 1;
}
@-moz-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}
@-webkit-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}
@-o-keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}
@keyframes jelly {
	from {
		transform: scale(1, 1);
	}
	30% {
		transform: scale(1.25, 0.75);
	}
	40% {
		transform: scale(0.75, 1.25);
	}
	50% {
		transform: scale(1.15, 0.85);
	}
	65% {
		transform: scale(0.95, 1.05);
	}
	75% {
		transform: scale(1.05, 0.95);
	}
	to {
		transform: scale(1, 1);
	}
}

.hidden-xs-up {
	display: none !important;
}
/**/
branch-list .tags {
	min-height: 31px;
	padding-top: 0px;
}

tags-input .tags .tag-item.selected {
	background: -webkit-linear-gradient(#7adc6d 0%, #66cc58 45%, #41ab33 100%);
	background: -o-linear-gradient(#7adc6d 0%, #66cc58 45%, #41ab33 100%);
	background: linear-gradient(#7adc6d 0%, #66cc58 45%, #41ab33 100%);
}

tags-input .tags .tag-item {
	background: -webkit-linear-gradient(
		var(--secondary-color) 0%,
		var(--secondary-color) 47%,
		var(--secondary-color) 100%
	);
	background: -o-linear-gradient(
		var(--secondary-color) 0%,
		var(--secondary-color) 47%,
		var(--secondary-color) 100%
	);
	background: linear-gradient(
		var(--secondary-color) 0%,
		var(--secondary-color) 47%,
		var(--secondary-color) 100%
	);
}

tags-input .tags .tag-item,
ti-tag-item {
	font: 12px Roboto;
	padding-top: 6px;
	min-height: 25px;
	/*background-color: var(--primary-color);*/
	color: var(--grayscale---basic--white);
	font-weight: bold;
	border: 0px;
	/*height: 100%;*/
}

tags-input .tags .tag-item .remove-button {
	margin: -3px 2px 0px 12px;
	float: right;
	color: var(--grayscale---basic--white);
	font-size: 18px;
	font-weight: 200;
}

tags-input .tags .tag-item .remove-button:hover {
	text-decoration: none;
	font-weight: 800;
}

tags-input .tags.focused {
	outline: 0;
	-webkit-box-shadow: 0 0 3px 1px rgba(128, 195, 66, 0.6);
	-moz-box-shadow: 0 0 3px 1px rgba(128, 195, 66, 0.6);
	box-shadow: 0 0 3px 1px rgba(128, 195, 66, 0.6);
}

.ga-btn-group {
	padding: 0px;
}

.ga-btn-group button {
	height: 24px;
	border-color: var(--grayscale---gray-600);
	line-height: 1;
	color: var(--grayscale---gray-600);
}

.ga-btn-group li {
	padding: 4px 8px;
	cursor: pointer;
}

.ga-btn-group li:hover {
	background-color: #ececec;
}

.forecast-controller {
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 0px;
	width: 100%;
	margin-bottom: 4px;
	display: inline;
	margin-left: 4px;
	margin-right: 4px;
	text-align: right;
}

.forecast-controller-disabled label {
	color: var(--grayscale---gray-400);
}

.personificate-warning {
	position: absolute;
	align-items: center;
	justify-content: center;
	display: inline-flex;
	text-align: left;
	height: 60px;
	width: 270px;
	left: 50%;
	margin-top: -6px;
	border: 1px solid var(--grayscale---gray-200);
	border-radius: 5px;
	font-weight: bold;
	background-color: var(--grayscale---gray-100);
}

.personificate-warning .card-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 11px 16px;
	width: 270px;
	height: 34px;
}
.personificate-warning .personificate-name {
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.personificate-warning .fa-eye {
	font-size: 18px;
}

.personificate-warning .remove {
	font-size: 16px;
	cursor: pointer;
	color: #494d55;
}

/* The switch - the box around the slider */
.switch-input {
	position: relative;
	display: inline-block;
	width: 94px;
	height: 20px;
}

/* Hide default HTML checkbox */
.switch-input input {
	display: none;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--grayscale---basic--white);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border: 1px solid var(--secondary-color);
	width: 28px;
	margin: auto;
	height: 15px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 11px;
	width: 11px;
	left: 4px;
	bottom: 1px;
	background-color: var(--secondary-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked ~ .slider {
	background-color: var(--grayscale---basic--white);
}

input:focus ~ .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked ~ .slider:before {
	-webkit-transform: translateX(8px);
	-ms-transform: translateX(8px);
	transform: translateX(8px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
.switch-input-option {
	position: absolute;
	color: var(--grayscale---gray-400);
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
}
.switch-input-option.left-option {
	left: 0;
}

.switch-input-option.right-option {
	left: 68px;
}

input:checked ~ .left-option {
	color: var(--grayscale---gray-400);
	font-weight: 400;
}

input:checked ~ .right-option {
	color: var(--secondary-color);
	font-weight: 800;
}
input ~ .left-option {
	color: var(--secondary-color);
	font-weight: 800;
}
input ~ .right-option {
	color: var(--grayscale---gray-400);
	font-weight: 400;
}

.tariff-info i.fa-info-circle {
	position: absolute;
	font-size: 13px;
	display: none;
}

.tariff-info:hover i.fa-info-circle {
	display: block;
	top: 3px;
	left: 10px;
}

.tariff-info:hover > div:first-child {
	display: block;
	padding-right: 0px;
	padding-left: 30px;
}

i.fa-check-circle {
	color: var(--dataviz---md--green);
}

i.fa-exclamation-circle {
	color: var(--error---error-500);
}

i.fa-minus-circle {
	color: var(--error---error-500);
}

.ga-drop-zone {
	border: 2px dashed var(--grayscale---gray-400);
	color: var(--grayscale---gray-400);
	font-size: 15px;
	display: grid;
	text-align: center;
	padding: 15px 0px;
}

.upload-image {
	mask-image: url(/assets/images/custom_icons/icon_upload.svg);
	mask-size: 70px;
	mask-repeat: no-repeat;
	background-color: var(--grayscale---gray-400);
	width: 75px;
	height: 60px;
	margin: 0px 230px;
	margin-bottom: 10px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.upload-image {
		-webkit-mask-image: url(/assets/images/custom_icons/icon_upload.svg);
		-webkit-mask-size: 70px;
		-webkit-mask-repeat: no-repeat;
		background-color: var(--grayscale---gray-400);
		width: 75px;
		height: 60px;
		margin: 0px 110px;
	}
}

/*Meter dropdown*/
.dropdown-meter {
	padding-left: 0px;
}

.dropdown-meter a {
	color: var(--grayscale---gray-700);
}

#drop-meters {
	background-color: var(--grayscale---basic--white);
	margin-right: 20px;
	color: var(--grayscale---gray-700);
	height: 36px;
	border: 0px;
}
.dropdown-menu-item-container {
	border-bottom: 1px #ccc solid;
	display: inline-block;
	width: 100%;
}

.dropdown-menu-meter .dropdown-menu-item {
	color: var(--grayscale---gray-700);
	opacity: 0.5;
}

.dropdown-menu-meter .dropdown-menu-item.active {
	border-bottom: 2px var(--secondary-color) solid;
	opacity: 1;
}

.dropdown-menu-item {
	cursor: pointer;
}

.dropdown-menu-meter {
	padding: 8px;
}
.dropdown-menu-meter ul {
	padding: 0px;
}

.dropdown-menu-group {
	border-bottom: 1px #ccc solid;
	padding-bottom: 5px;
	margin-bottom: 8px;
}

source-selector .dropdown-toggle,
ng2-source-selector .dropdown-toggle {
	font-size: 18px;
	position: relative;
	outline: 0px;
	box-shadow: none;
	border: 0px;
}

source-selector .btn .fa.fa-angle-up,
ng2-source-selector .btn .fa.fa-angle-up {
	margin-right: 10px;
	margin-left: 8px;
}

source-selector .dropdown-meter a.active,
ng2-source-selector .dropdown-meter a.active {
	background-color: var(--primary-color);
	color: var(--grayscale---basic--white);
	font-weight: 800;
}

source-selector .search-box input,
ng2-source-selector .search-box input {
	height: 28px;
	width: 240px;
	margin-left: 5px;
}

source-selector .search-box,
ng2-source-selector .search-box {
	position: relative;
	margin: 4px 0px;
}

source-selector .search-box .fa-search,
ng2-source-selector .search-box .fa-search {
	position: absolute;
	z-index: 99;
	left: 10px;
	top: 5px;
}
@supports (-moz-appearance: none) {
	source-selector .search-box .fa-search,
	ng2-source-selector .search-box .fa-search {
		margin-top: 10px;
	}
}

source-selector .caret,
ng2-source-selector .caret {
	top: 18px;
	position: absolute;
}
source-selector .divisor,
ng2-source-selector .divisor {
	border-bottom: 1px #ccc solid;
	font-weight: 800;
}
source-selector a img,
ng2-source-selector a img {
	width: 12px;
	margin-right: 8px;
	margin-bottom: 2px;
}
@media (max-width: 575px) {
	#drop-meters {
		margin-right: 0px;
		font-size: 14px;
		padding-top: 9px;
	}
}

a {
	cursor: pointer;
}

/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xFIzIFKw.woff2)
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xMIzIFKw.woff2)
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xEIzIFKw.woff2)
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xLIzIFKw.woff2)
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xHIzIFKw.woff2)
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xGIzIFKw.woff2)
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local("Roboto Italic"), local("Roboto-Italic"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xIIzI.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2)
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2)
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2)
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2)
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2)
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2)
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2)
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2)
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2)
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2)
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2)
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2)
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
