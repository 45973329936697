.mobile measuring-points .page-tab{
  font-size: 15px;
}

.mobile measuring-points .add-meter{
  top: -26px;
}

.mobile measuring-points .table-responsive-ga {
  margin-top: 24px;
}
