goal-chart {
  min-height: 256px;
  display: flex;
}
.highcharts-container {
  overflow: visible !important;
}
svg.highcharts-root{
  overflow: visible !important;
}
.highcharts-series, .highcharts-label {
  overflow: visible !important;
}
goal-chart .chart-tooltip{
  overflow: visible !important;
}

goal-chart .notavghigh i{
  visibility: hidden !important;
}

.goal-component {
  width: 100%;
  height: 253px;
  border-radius: 10px;
  background-color: var(--grayscale---basic--white);
  box-shadow: 0px 4px 8px rgba(212, 212, 212, 0.5);

  .goal-selector-row {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid  var(--grayscale---gray-200);

    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--grayscale---gray-700);
    }

    .fa-ellipsis-v{
      position: absolute;
      right: 15px;
      font-size: 16px;
    }
  }
  .goal-panel {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 52px);

    .goal-define {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      white-space: pre-line;
      .title {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.4px;
        margin: 10px 0;
        color: var(--grayscale---gray-500);
      }

      .goal-field {
        display:flex;
        flex-direction: row;
        .input-group-addon{
          width: 43px;
          display: flex;
          align-items: center;
        }
        .form-control {
          margin-right: 16px;
          width: 100px;
        }
        .btn-color-ga {
          padding: 8px 16px;
        }
      }
    }

    .goal-chart {
      display: flex;
      flex-direction: row;
      width: calc(100%);

      .chart {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
      }

      .details {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .list {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--grayscale---gray-600);
          font-size: 14px;
          line-height: 19.4px;
          margin-top: 4px;

          .title {
            font-weight: 500;
            margin-right: 6px;
          }
          .value {
            font-weight: 400;
          }
          .money {
            color: var(--success---success-500);
            font-size: 18px;
            font-weight: 700;
            line-height: 23.1px;
          }
        }
      }
    }
  }
}
.separator-goal {
  width: 170px;
  margin: 10px 0;
  background: var(--grayscale---gray-200);
  height: 1px;
}

.goal-component .row{
  padding-top: 2px;
  padding-bottom: 2px;
}

.goal-component .container {
  align-self: center;
  display: flex;
  align-items: center;
}

goal-chart .title h3 {
  margin: 0;
  font-size : 16px;
  font-weight: 800;
  border-bottom: 1px solid var(--grayscale---gray-700);
  padding-bottom: 8px;
}

.col-md-5.chart{
  padding-left: 0px;
}

goal-chart .confirm-goal-button{
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white);
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}
goal-chart .confirm-goal-button:hover{
  background-color: var(--secondary-color);

}

goal-chart  .loading-component{
 background-color: var(--grayscale---basic--white);
 filter: alpha(opacity=90);
 opacity: 0.9;
 -moz-opacity: 0.9;
 -webkit-opacity: 0.9;
 position: absolute;
 z-index: 8;
 width: 100%;
 height: 99%;
 display: flex;
 align-content: center;
 justify-content: center;
 align-items: center;
 top: 0;
 margin: 0px;
 left: 0;
}
