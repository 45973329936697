meter-list {
  color: var(--grayscale---gray-500);
  position: relative;
  width: 100%;
  display: block;
  /*margin-top: -132px;*/
}

meter-list .meter {
    font-family: "Roboto";
    background-color: var(--primary-color);
    color: var(--grayscale---basic--white);
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    height: 20px;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    display: table;
    cursor: pointer;
}

meter-list .meter a{
  color: var(--grayscale---basic--white);
  text-decoration: none;
}

meter-list .meter:hover {
  background-color: var(--hover1);
}

meter-list .meter-generation .meter{
  background-color: var(--alert---alert-500);
  margin-left: 24px;
}

meter-list .meter-generation .meter:hover{
  background-color: var(--alert---alert-500);
}

meter-list .meter-generation{
  display: block;
  padding-top: 8px;
}

meter-list .side-panel-ga {
  height: 92vh;
}

meter-list .side-panel-ga .navigation .fa-calendar-alt {
  padding-right: 0px;
}

meter-list .side-panel-ga .ga-calendar-navigator .navigation{
  margin-left: 0px;
}

meter-list .side-panel-ga .ga-calendar-actions{
  font-size: 14px;
}

meter-list .side-panel-ga .ga-calendar-navigator i.fa-angle-left, .ga-calendar-navigator i.fa-angle-right {
  margin-left: 8px;
  margin-right: 8px;
}


meter-list .side-panel-ga {
    right: -288px;
    margin-top:0px;
}

meter-list form label {
  font-size: 14px;
}

meter-list .loading-component{
  background-color: var(--grayscale---basic--white);
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

meter-list .loading-component  .spinner {
  padding: 0;
  margin: 0;
}

meter-list .page-title h3{
  display : inline-block;
  margin-top : 0px;
}

meter-list input.inspection-day {
  width: 70px;
  display: inline-block;
  margin-left: 16px;
}

meter-list .conector-info {
  font-size: 11px;
  font-weight: 400;
}

meter-list .add-meter{
  display: none;

}
.with-tabs meter-list .add-meter{
  display: block;
  position: absolute;
  top: -66px;
  width: 172px;
  right: 0;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}

.with-tabs meter-list{
  margin-top: 0px;
}

.with-tabs meter-list .ga-navbar{
  display: none;
}

.with-tabs meter-list .add-meter.open-side-panel{
  width: calc(100% - 254px) !important;

}

.with-tabs meter-list .meter-panel {
  top: 180px;
  display:block;
}

.mobile .with-tabs meter-list .meter-panel {
    z-index: 3;
}
.mobile  .with-tabs meter-list .side-panel-ga {
    margin-top: -115px;
}

 meter-list .meter-panel {
  top: 80px;
  display:block;
}

meter-list .time{
  width: 63px;
}

meter-list .side-panel-ga-content .date-container.date-container-display{
  left: -23px;
}

meter-list #tableMeters_wrapper{
  top: -8px;
}

meter-details .optional-headers{
  cursor: pointer;
}

meter-details .optional-headers[aria-expanded="true"]>i{
  transform: rotate(180deg);
}

.datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active, 
.datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:active, .datetimepicker table tr td span.active:hover:active, .datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active:hover.active{
  background-color: var(--primary-color)!important;
  background-image: none;
}

.datetimepicker-dropdown-bottom-right{
  border-radius: 4px;
}