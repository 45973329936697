html {
  background: var(--grayscale---basic--white);
}

.header .branding {
  background: var(--primary-color);
}

/* Menu */
.theme-ga-1 .main-nav-wrapper .main-nav li.active a {
  border-left-color: var(--primary-color);
}

/* Panels */
.panel-ga>.panel-heading {
  color: var(--grayscale---basic--white);
  background-color: var(--secondary-color);
}

/* Login */
.login {
  background-color: var(--grayscale---basic--white);
}

.login .embed-responsive-item {
  border-left: 1px solid var(--grayscale---gray-400);
}

.login .btn-login {
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white);
}
.login .btn-login:hover{
  background-color: var(--secondary-color);
}

.login a {
  color: var(--grayscale---gray-400);
}
.chart{
  overflow: visible !important;
}
.login .form-control:focus {
  border-color: var(--primary-color);
}

.modal-header {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

.modal-header {
  background-color: var(--secondary-color);
  color: var(--grayscale---basic--white);
}

.btn-color-ga {
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white);
}

.btn-color-ga:hover {
  background-color: var(--hover1);
  color: var(--grayscale---basic--white);
  border: 1px solid var(--hover1);
}

.btn-outline-dark-ga {
color: var(--grayscale---gray-600);
background-color: transparent;
background-image: none;
border-color: var(--grayscale---gray-600);
}

.btn-outline-red-ga {
  color: var(--error---error-500);
  background-color: transparent;
  background-image: none;
  border-color: var(--error---error-500);
}

.btn-outline-ga{
color: var(--primary-color);
background-color: transparent;
background-image: none;
border-color: var(--primary-color);
font-size: 18px;
}

.ga-radio-label{
  padding: 0px 40px;
  position: relative;
}

/* Hide the browser's default radio button */
.ga-radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.ga-radio-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid var(--grayscale---gray-400);
}

/* On mouse-over, add a grey background color */
.ga-radio-label:hover input ~ .ga-radio-btn {
  background-color: var(--grayscale---gray-100);
}

/* When the radio button is checked, add a blue background */
.ga-radio-label input:checked ~ .ga-radio-btn {
  background-color: var(--secondary-color);
  border-width: 0px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.ga-radio-btn:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.ga-radio-label input:checked ~ .ga-radio-btn:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.ga-radio-label .ga-radio-btn:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--grayscale---basic--white);
}

.pace .pace-progress {
  background: var(--primary-color);
}

.appliances .table>tbody>tr>td.first,
.appliances .table>tbody>tr>td.first a {
  color: var(--primary-color);
}

.label-green {
  color: var(--primary-color);
}

.label-red {
  color: var(--error---error-500);
}

