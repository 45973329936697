.ga-calendar-actions .fa-angle-right:hover,
.ga-calendar-actions .fa-angle-left:hover {
    color: var(--secondary-color);
}

.ga-calendar-actions .fa-angle-right.disable-action,
.ga-calendar-actions .fa-angle-right.disable-action:hover,
.ga-calendar-actions .fa-angle-left.disable-action,
.ga-calendar-actions .fa-angle-left.disable-action:hover {
    color: #ccc;
    cursor: not-allowed;
}

.ga-calendar-actions {
    height: 29px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
}


.ga-calendar-actions span {
    margin-top: 3px;
}

.green-hover:hover {
    color: var(--secondary-color);
}

.date-container {
    display: none;
    position: absolute;
    z-index: 999;
    top: 160px;
}

.date-container-display .close {
    position: absolute;
    right: 1px;
    top: 0px;
    z-index: 1000;
    opacity: .4;
    font-size: 16px;
}

.date-container-display .close:hover {
    opacity: .6;
}

.date-container.date-container-display {
    display: block;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--grayscale---basic--white);
    padding-top: 10px;
    box-shadow: 0px 1px 1px 1px #d3d3d3;
}

.date-container .date-container-footer {
    border-top: 1px solid #ddd;
    height: 50px;
}

.ga-calendar-navigator {
    display: inline-block;
    border: none;
    font-size: 14px;
    outline: none;
    color:var(--grayscale---gray-700);
}

.ga-calendar-no-border {
    border: none;
}

.ga-calendar-navigator .dropdown {
    display: inline-block;
    padding: 0 5px;
}

.ga-calendar-navigator .dropdown button {
    font-size: 18px;
    color: var(--grayscale---gray-600);
}

.ga-calendar-navigator .dropdown .btn-default,
.ga-calendar-navigator .dropdown .btn-default:focus {
    border: none;
    outline: 0px;
}

.ga-calendar-navigator .dropdown .btn-default:hover,
.ga-calendar-navigator .dropdown .btn-default:focus {
    background-color: #fff;
}

.ga-calendar-navigator .navigation {
    display: inline-flex;
    padding: 8px 8px;
    align-items: center;
    position: relative;
}

.ga-calendar-navigator .daterangepicker.ltr .ranges {
    float: none
}

.ga-calendar-navigator-datepicker {
    display: inline;
}

.ga-calendar-navigator-datepicker .daterangepicker_input {
    width: 60%;
}

.ga-calendar-navigator-datepicker .daterangepicker_input i.fa-calendar-alt {
    color: var(--dataviz---md--green);
}

.ga-calendar-navigator-datepicker input.input-mini {
    border-color: var(--dataviz---md--green);
}

.ga-calendar-navigator-datepicker input.input-mini.active {
    border-color: var(--dataviz---md--green);
}

.ga-calendar-navigator .calendar-day-border {
    border-right: 1px solid var(--grayscale---gray-200);
    height: 48px;
    padding-top: 6px;
    margin-right: 10px;

}

.ga-calendar-navigator label {
  font-weight: 400;
}

.ga-calendar-navigator i.fa-angle-left,
.ga-calendar-navigator i.fa-angle-right {
    font-size: 20px;
    margin-left: 16px;
    margin-right: 16px;
    cursor: pointer;
    font-weight: bold;
}

.navigation .fa-calendar-alt {
    cursor: pointer;
    font-size: 17px;
    padding-right: 18px;
}

.navigation .fa-calendar-alt:hover {
    color: var(--secondary-color);
}

[date-picker] {
    border: none;
}

.date-container table>tbody>tr>td {
    border-bottom: none;
}

[date-picker='end'] {
    border-top-left-radius: 0px;
    border-left: none;
}

[date-picker='start'] {
    border-top-right-radius: 0px;
}

[ng-switch-when="month"],
[ng-switch-when="year"] {
    width: 180px;
}

[date-picker] .active:hover,
[date-picker] .now:hover,
[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active,
[date-picker] .active.disabled,
[date-picker] .now.disabled,
[date-picker] .active[disabled],
[date-picker] .now[disabled] {
    color: var(--grayscale---basic--white);
    background-color: var(--primary-color);
    opacity: 0.7;
}

[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active {
    background-color: var(--hover1);
    background-image: none;
}

[date-picker] .active {
    background-image: linear-gradient(to bottom, var(--primary-color), var(--primary-color));
}

[date-picker] .now {
    background-image: none;
    background-color: transparent;
    color: #333;
    border: 1px solid var(--secondary-color);
}

[date-picker] th:hover,
[date-picker] [ng-switch-when="date"] td span:hover {
    background-color: var(--hover1);
}

.date-range {
    display: block;
}

@media screen and (max-width: 412px) {

    .ga-calendar-navigator .dropdown button {
        font-size: 13px;
    }
    .ga-calendar-navigator .dropdown {
        padding: 0px;
        vertical-align: middle;
    }
    /*.ga-calendar-actions {
        display: none;
    }*/
    .ga-calendar-actions{
      font-size: 14px;
    }
    .ga-calendar-actions .formatted-date-day,
    .ga-calendar-actions .formatted-date-month{
      min-width: 112px;
    }

    .ga-calendar-navigator .dropdown .btn-default  {
          margin-top: 10px;
    }

    .ga-calendar-navigator i.fa-angle-left, .ga-calendar-navigator i.fa-angle-right {
      margin-left: 10px;
      margin-right: 10px;
    }
    .navigation .fa-calendar{
      padding-right: 0px;
    }
}

.formatted-date-day {
    min-width: 115px;
}

.formatted-date-month {
    min-width: 115px;
}

.formatted-date-hour {
    min-width: 60px;
}

.formatted-date-year {
    min-width: 40px;
}

.formatted-date-custom {
    min-width: 115px;
}

.formatted-date {
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.ga-calendar-navigator-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 998;
  top: 0;
  left: 0;
}

table.date-range>tbody>tr>td:first-child {
    padding-right: 8px;
    border-right: 1px solid #e6e6e6;
}

table.date-range>tbody>tr>td:last-child {
    padding-left: 8px;
}

.ga-calendar-navigator .dropdown button{
  padding-top: 3px;
}


ga-calendar-navigator .week-view table>tbody>tr:hover>td,
ga-calendar-navigator .week-view table>tbody>tr.selected>td{
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white);
  border-radius: 0px;
}

ga-calendar-navigator [date-picker].week-view table>tbody>tr:hover>td span.active,
ga-calendar-navigator [date-picker].week-view table>tbody>tr.selected>td span.active{
  background: none;
}

ga-calendar-navigator [date-picker].week-view th:hover,  [date-picker].week-view  [ng-switch-when="date"] td span:hover {
    background-color: var(--primary-color);
}

ga-calendar-navigator .week-view table>tbody>tr:hover>td .now {
    background-image: none;
    background-color: var(--grayscale---basic--white);
    color: #333;
    border: 1px solid var(--secondary-color);
}

ga-calendar-navigator .calendar-disabled.ga-calendar-navigator .navigation{
  border-color : #828282;
  color : #828282;
}
ga-calendar-navigator .calendar-disabled.ga-calendar-navigator .green-hover:hover,
ga-calendar-navigator .calendar-disabled.ga-calendar-navigator .navigation .fa-calendar-alt:hover{
  color : #828282;
  cursor: default;
}
