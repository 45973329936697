organization-selector .dropdown-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

organization-selector .dropdown-menu {
  width: 93%;
  max-height: 300px;
  overflow-y: auto;
}

organization-selector .dropdown-menu > li:hover {
  background-color: var(--secondary-color);
}

organization-selector .dropdown-item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 10px;
}

organization-selector .drop-item-col-id {
  width: 50px;
}
