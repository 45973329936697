energy-bill-metadata-list .add-button {
  position: absolute;
  left: 0;
  top: 180px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
}

energy-bill-metadata-list .description .action-buttons button i {
  margin-right: 5px;
}

energy-bill-metadata-list .buttons {
  float: right;
}

energy-bill-metadata-list table.ga-table.dataTable tbody td {
  border-bottom: 0px;
}

energy-bill-metadata-list .child-row .tr {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

energy-bill-metadata-list .expired {
  color: var(--error---error-500);
}

energy-bill-metadata-list .welcome-lightbulb {
  background-color: var(--alert---alert-500);
  width: 40px;
  height: 40px;
  border-radius: 30px;
  font-size: 30px;
  text-align: center;
  padding: 5px;
}

energy-bill-metadata-list .toggle-off {
  background: var(--primary-color) !important;
  border: 0;
  color: var(--grayscale---basic--white);
  font-weight: bold;
}

energy-bill-metadata-list .toggle-on {
  background: var(--primary-color) !important;
  border: 0;
  color: var(--grayscale---basic--white)!important;
  box-shadow: none!important;
  font-weight: bold;
}

energy-bill-metadata-list .toggle-on:hover {
  background: var(--primary-color) !important;
  border: 0;
  color: var(--grayscale---basic--white)!important;
  box-shadow: none!important;
  font-weight: bold;
}

/* energy-bill-metadata-list .btn-color-ga:focus,
.dropdown-toggle:focus,
.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:focus,
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-color: var(--grayscale---gray-200) !important;
  border: 0;
  color: var(--grayscale---gray-900);
} */

energy-bill-metadata-list .volume,
table.ga-table tbody .volume,
energy-bill-metadata-list .price,
table.ga-table tbody .price {
  text-align: right;
}

energy-bill-metadata-list .child-demand {
  width: 15%;
}

energy-bill-metadata-list .new-drop {
  margin-top: 15px;
}

energy-bill-metadata-list .drop-item-title {
  font-size: 16px;
  line-height: 19px;
}

energy-bill-metadata-list .fa-minus-circle {
  color: #494d55 !important;
}

energy-bill-metadata-list .del-modal-field {
  padding-top: 7px;
}

energy-bill-metadata-list .drop-box {
  display: flex;
  justify-content: center;
  flex-flow: column;
  border: 2px dashed var(--grayscale---gray-400);
  height: 150px;
  text-align: center;
}

energy-bill-metadata-list .upload-background {
  color: var(--grayscale---gray-400);
  font-size: 60px;
}

energy-bill-metadata-list .upload-text {
  z-index: 3;
  font-size: 14px;
}

energy-bill-metadata-list .upload-list {
  max-height: 383px;
  overflow: auto;
}

energy-bill-metadata-list .name-link {
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white) !important;
  font-weight: bold !important;
  border-radius: 5px;
  padding: 12px 10px 12px 20px;
  cursor: pointer;
}

energy-bill-metadata-list th.name{
  width: 25%;
}

energy-bill-metadata-list th.demand, energy-bill-metadata-list th.consumption, energy-bill-metadata-list th.conSurplus{
  width: 20%;
}

energy-bill-metadata-list th.others, energy-bill-metadata-list th.number{
  width: 10%;
}

energy-bill-metadata-list th.actions{
  width: 5%;
}

energy-bill-metadata-list toggle .btn-default:active{
  outline: 0px!important;
}

energy-bill-metadata-list toggle .btn-default:hover{
  border-color: #A9A9A9;
}

energy-bill-metadata-list toggle .btn-default .btn-default:active{
  border: 0px!important;
}

energy-bill-metadata-list toggle .btn-default:active{
  border: 1px solid #a9a9a9;
}

energy-bill-metadata-list toggle .toggle .toggle-handle{
  margin-right: 24px;
}

energy-bill-metadata-list toggle .toggle.off .toggle-handle{
  margin-left: 24px;
  margin-right: 0px;
}

energy-bill-metadata-list toggle .toggle.btn, energy-bill-metadata-list toggle .toggle-handle{
  border-radius: 14px;
}

energy-bill-metadata-list toggle .toggle-on{
  padding-left: 0px;
}

energy-bill-metadata-list toggle .toggle-off{
  padding-right: 0px;
}

energy-bill-metadata-list .background-inform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  position: relative;
  background: var(--grayscale---basic--white);
  box-shadow: 0px 4px 8px rgba(212, 212, 212, 0.5);
  border-radius: 10px;
  hr {
		width: 450px;
		background: var(--grayscale---gray-200);
		height: 1px;
	}
  .inform-title {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.8px;
    text-align: center;
    color: var(--grayscale---gray-500);
  }
  .inform-tips {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.4px;
    margin: 0;
    color: var(--grayscale---gray-500);
    a {
        color: var(--grayscale---gray-500);
        font-weight: 700;
        text-decoration: underline;
    }
  }
}