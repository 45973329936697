user-manager .add-button{
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

.invite-user-row {
    padding-bottom: 2%;
}

.p-uns {
  padding-right: unset;
  padding-left: unset;
}

.dropdown-options-hidden{
  display: none;
  position: absolute;
  float: right;
  left: -96px;
  z-index: 100;
}

.dropdown-options-shown{
  display: block;
  float: right;
}

.dropbtn{
  border: aliceblue;
  margin: -8px;
}

user-manager .side-panel-ga {
    right: -288px;
}

user-manager tags-input .tags .input{
  width: 255px;
}

user-manager .toggle.btn{
  min-height: 24px;
  min-width: 42px;
}

user-manager .toggle-on {
  background-color: var(--primary-color);
  padding-left: 3px;
}

user-manager .toggle-on.active:hover{
  background: var(--primary-color);
  border: 0px;
}

user-manager .toggle-group, user-manager .toggle-off, user-manager .toggle-on {
  top: -2px;
}

user-manager .toggle-off.btn-default.active {
  background-color:var(--grayscale---gray-200);
  padding-left: 16px;
  color: #828282;
}
