composition-list .add-button {
  position: absolute;
  left: 225px;
  top: 130px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  height: 33px;
  width: calc(100% - 225px) !important;
}

composition-list .buttons {
  float: right;
}

composition-list table.ga-table.dataTable tbody td {
  border-bottom: 0px;
}

composition-list .child-row .tr {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

composition-list .expired {
  color: var(--error---error-500);
}

composition-list .welcome-lightbulb {
  background-color: var(--alert---alert-500);
  width: 40px;
  height: 40px;
  border-radius: 30px;
  font-size: 30px;
  text-align: center;
  padding: 5px;
}

composition-list .toggle-on {
  background: var(--primary-color) !important;
  border: 0;
  color: var(--grayscale---basic--white);
  font-weight: bold;
}
composition-list .toggle-on:hover {
  background: var(--primary-color) !important;
  border: 0;
  color: var(--grayscale---basic--white);
  font-weight: bold;
}

/* composition-list .btn-color-ga:focus,
.dropdown-toggle:focus,
.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:active,
.open .dropdown-toggle.btn-color-ga:focus,
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-color: var(--grayscale---gray-200) !important;
  border: 0;
  color: var(--grayscale---gray-900);
} */

composition-list .volume,
table.ga-table tbody .volume,
composition-list .price,
table.ga-table tbody .price {
  text-align: right;
}

composition-list .child-demand {
  width: 15%;
}

composition-list .new-drop {
  margin-top: 15px;
}

composition-list .drop-item-title {
  font-size: 16px;
  line-height: 19px;
}
