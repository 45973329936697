#export-page.module {
    padding-left: 0%;
}

#export-page section {
    padding-left: 2%;
    margin-bottom: 3%;
}

#export-page .btn-group-left-caret.open .fa-chevron-down{
  transform: rotate(180deg);
}

#export-page .btn-group button{
  border-radius: 4px;
  border-color: var(--primary-color);
  color : #555;
}

#export-page .btn-group a{
  color: var(--grayscale---gray-600);
  display: block;
}

#export-page .scrollbox li{
  margin: 5px 0;
}

#export-page  .dropdown-menu-item{
  display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: 24px;
}

#export-page .form-group-radio{
  margin-top: 6px;
}

#export-page label {
  font-weight: 400;
}

#export-page ga-calendar-navigator button.dropdown2-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}


.btn-primary[disabled], .btn-primary[disabled]:hover{
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  opacity: 1;
}

.btn-primary:active:focus{
  background-color: var(--secondary-color);
}

#export-page source-selector button.dropdown-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}

#export-page source-selector button .caret{
  top: 15px;
  margin-left: -2px;
}

#export-page .calendar-day-border {
  border: none;
  padding-left: 0px;
}
/*
#export-page .navbar-default {
    margin-bottom: 0px;
    margin-left: 0px;
}*/

/*#export-page .navbar-default h3 {
    margin-top: 0px;
    margin-bottom: 16px;
}*/
.page-tab-header {
  padding: 24px 24px 0 24px;
  position: relative;
}
.page-tab-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--grayscale---gray-700);
}
