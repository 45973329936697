.power-demand-component .power-active {
    border-bottom: 4px solid var(--primary-color);
    font-weight: 800;
}

.power-demand-component .power-inactive {
}

.power-demand-component .power-selector span {
    cursor: pointer;
    width: 60px;
    display: inline-block;
    text-align: center;
    height: 25px;
    font-size: 16px;
    color: var(--grayscale---gray-700);

}

.power-demand-component .chart {
  padding-left: 0px;
  top: 0px;
}

.power-selector {
    margin-bottom: -38px;
    margin-top: -3px;
    position: relative;
    z-index: 11;
    margin-left: 16px;
    width: 33%;
}

.power-demand-main-info {
    font-size: 16px;
    padding-right: 0px;
    top:-3px;
}

body:not(.nav-toggled) .open-side-panel .power-demand-component .date-info{
  font-size: 13px;
}
body:not(.nav-toggled) .open-side-panel .power-demand-component .power-demand-main-info{
  font-size: 14px;
  padding-left: 3px;
}

.power-demand-component .little-botom-margin {
    margin-bottom: 8px;
    border-bottom: 1px solid var(--grayscale---gray-700);
    width: 100%;
}

.power-demand-component .little-space {
    margin-bottom: 7%;
}

.power-demand-component .phase {
    position: relative;
    z-index: 1000;
}
.power-demand-component .highcharts-legend-item .phase{
  font-size: 16px;
}
.power-demand-component .highcharts-legend-item .phase-a{
  color:var(--dataviz---md--blue);
}
.power-demand-component .highcharts-legend-item .phase-b{
  color:var(--dataviz---md--green);
}
.power-demand-component .highcharts-legend-item .phase-c{
  color:var(--dataviz---dk--pink);
}
.power-demand-component .highcharts-legend-item  .phase-geral{
  color: var(--chartColor);
}
.power-demand-component .inductive-penalty{
  color : var(--error---error-500);
}

.power-demand-component .unit {
 font-weight: 100;
}

.power-demand-component .date-info{
  font-size: 14px;
  display: block;
  line-height: 12px;
}

body:not(.nav-toggled) .open-side-panel .power-demand-component .date-info{
  font-size: 13px;
}
body:not(.nav-toggled) .open-side-panel .power-demand-component .power-demand-main-info{
  font-size: 14px;
  padding-left: 3px;
}

.power-demand-component .measurement-container{
  margin-bottom : 6px;
}

.power-demand-component .minute-label{
  position: absolute;
  margin-left: -32px;
}

.power-demand-component .minute-label .twenty-four{
  margin-left: 14px;
}

.power-demand-component .hour-label{
  position: absolute;
}

.power-demand-component .hour-label.twenty-four{
  margin-left: 30px;
}

.power-demand-component .phases-container{
  z-index: 10;
  text-align: end;
  position: absolute;
  top: -3px;
  padding-right: 30px;
}

.power-demand-component .phase-row{
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.power-demand-component .phase-row .phase-a ~ .lbl{
  color:var(--dataviz---md--blue);
}
.power-demand-component .phase-row .phase-b ~ .lbl{
  color:var(--dataviz---lt--pink);
}
.power-demand-component .phase-row .phase-c ~ .lbl{
  color:var(--dataviz---dk--purple);
}
.power-demand-component .phase-row .phase-geral ~ .lbl{
  color: var(--chartColor);
}

.power-demand-component .Ponta{
  position: absolute;
  right: 625px;
  top: -10px;
}

.power-demand-component .fPonta{
  position: absolute;
  right: 340px;
  top: -10px;
}

.power-demand-component .chart.period-day .highcharts-legend, .power-demand-component .chart.period-day .highcharts-legend-checkbox {
  display: none;
}

@media(min-width:768px) {
    .panel-ga.powerDemandChart>.panel-body {
        height: 320px;
    }
}

.highcharts-container input{
    position: relative;
    z-index: 101;
}
@media screen and (max-width: 412px) {
  .power-demand-component .power-selector-row {
    min-height:25px;
  }

  .power-demand-component .power-selector{
    min-width: 100%;
  }
}
