outlier-detection .all-good-box {
    background: #DFF0E1;
    border-radius: 5px;
    border: 1px solid #CDE9D1;
    padding: 15px;
    font-size: 16px!important;

}

outlier-detection .header-box {
    background-color: var(--error---error-50);
    border: 1px solid var(--error---error-500)!important;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #CDE9D1;
    margin-bottom: 10px;
    font-size: 16px!important;

}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    outlier-detection .icon-warning {
        -webkit-mask-image: url(/assets/images/custom_icons/exclamation_circle_light.svg);
        -webkit-mask-size: 20px;
        -webkit-mask-repeat: no-repeat;
        width: 22px;
        height: 22px;
        display: inline-block;
        background-color: var(--grayscale---gray-600);
        margin-left: 10px;
        margin-right: 10px;
  }
}

outlier-detection h3{
    margin-top: 15px;
    font-size: 16px;
    line-height: 21px;
}

outlier-detection .consumption-text {
    color: var(--secondary-color)!important;
    font-size: 16px!important;
    font-weight: bold!important;
}

outlier-detection tr{
    height: 40px!important;
}
outlier-detection th{
    font-size: 14px!important;
}

outlier-detection .fit-text {
    color: var(--dataviz---dk--blue)!important;
    font-size: 16px!important;
    font-weight: bold!important;
}

outlier-detection .no-data-box {
    background-color: var(--grayscale---gray-100);
    padding: 15px;
    border: 1px solid var(--grayscale---gray-100);
    font-size: 16px!important;
}

outlier-detection .text-table {
    border-top: 1px solid var(--grayscale---gray-600);
    border-bottom: 1px solid var(--grayscale---gray-600);
    margin-top: 25px;
}

outlier-detection .table-line {
    border-bottom: 1px solid var(--grayscale---gray-200)
}

outlier-detection .positive-text {
    font-size: 16px;
    font-weight: bold;
}


outlier-detection .negative-text {
    font-size: 16px;
    font-weight: bold;
}


outlier-detection .table-header {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold!important;
}

outlier-detection .table-header-border {
    border-bottom: 2px solid var(--grayscale---gray-200);
}

outlier-detection .table-header-alignment {
    text-align: right!important;
}

outlier-detection .table-row-alignment {
    text-align: right!important;
}

outlier-detection .positive-text {
    color: var(--error---error-500)!important;
}

outlier-detection .negative-text {
    color: var(--success---success-500)!important;
}

outlier-detection .loading-component{
    background-color: var(--grayscale---basic--white);
    filter: alpha(opacity=90);
    opacity: 0.9;
    -moz-opacity: 0.9;
    -webkit-opacity: 0.9;
    position: absolute;
    z-index: 7;
    width: 100%;
    height: 99%;
    display: flex;
    align-content: center;
    align-items: center;
    top: 0;
    margin: 0px;
    left: 0;
  }

  outlier-detection .loading-component-container{
    min-height: 50px;
}