realtime {
  display: flex;
  position: relative;
}

.realtime-component {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 253px;
  border-radius: 10px;
  background-color: var(--grayscale---basic--white);
  box-shadow: 0px 4px 8px rgba(212, 212, 212, 0.5);

  .row{
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .power-selector-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid  var(--grayscale---gray-200);

    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--grayscale---gray-700);
    }

    .power-selector {
      margin: 0;
      width: fit-content;
    
      span {
        cursor: pointer;
        width: 23%;
        display: inline-block;
        text-align: center;
        height: 25px;
        font-size: 16px;
        color: var(--grayscale---gray-600);
      }
    }
  }

  .realtime-content {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;

    .realtime-chart {
      width: calc(100%);
      height: 170px;
      position: relative;
      top: 16px !important;
      margin-bottom: 5px;
    }

    .power-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 147px;
      height: 170px;
    }
  }

  .container {
    align-self: center;
    display: flex;
    align-items: center;
  }

  .realtime-title h3 {
   margin: 0;
   font-size : 16px;
   font-weight: 800;
   border-bottom: 1px solid var(--grayscale---gray-700);
   padding-bottom: 8px;
  }
}

realtime .icon-sun{
  background-image: url(/assets/images/custom_icons/icon_sun.svg);
  width: 22px;
  display: inline-block;
  height: 23px;
}

realtime .realtime-loading {
  text-align: center;
  height: 90%;
}
realtime .realtime-loading-small {
  text-align: left;
  height: 90%;
}

realtime .lost-connection {
  text-align: center;
  height: 90%;
  font-size: 16px;
}

realtime .lost-connection.show-offline-flag-icon {
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    left: 0;
    height: 123px;
    right: 0;
}

realtime .gateway-panel {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  height: calc(100% - 52px);
}

.realtime-connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.4px;
    margin-top: 8px;
    color: var(--grayscale---gray-500);
  }
  .subtitle {
    margin-top: 0;
  }

  .separator {
    width: 240px;
    margin: 10px 0;
    background: var(--grayscale---gray-200);
    height: 1px;
  }

  .tips {
    display: flex;
    white-space: pre-line;
    flex-direction: column;
    font-size: 14px;
    line-height: 19.4px;
    color: var(--grayscale---gray-500);
  }

  .saiba-mais {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px; 
    padding: 8px 16px;
  }
}

realtime .gateway-panel.no-time {
  padding-top: 40px;
}

realtime .loading-component, realtime-advanced .loading-component{
  background-color: var(--grayscale---basic--white);
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 99%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  top: 0;
  margin: 0px;
  left: 0;
  border-radius: 10px;
}
realtime-advanced .loading-component-container{
  position: unset;
}

realtime .power-container {
  min-height: 190px;
  display: flex;
  flex-direction: column;
}

realtime .icon-bolt{
  mask-image: url(/assets/images/custom_icons/if_icon-ios7-bolt_211696.svg);
  mask-size: 27px;
  mask-repeat: no-repeat;
  background-color: var(--chartColordark);
  width: 22px;
  display: inline-block;
  height: 23px;
  margin-bottom: 5px;
}

.realValueRow {
  display: flex;
  align-items: center;
}

tbody td b.invoice{
  padding-left: 2px;
}

tbody realtime .gateway-panel p:first-child, tbody realtime .gateway-panel a{
  display: none;
}

tbody realtime .gateway-panel p{
  margin-top: 40px;
  padding-right: 40px;
  margin-left: -70px;
}

.dropdown-toggle#drop-powertype {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid var(--grayscale---gray-200);
  background-color: var(--grayscale---basic--white);
  color: var(--grayscale---gray-600);
  padding: 1px 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 160px;

  .fa-chevron-down:before {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--grayscale---gray-400);
  }
}

realtime-advanced .dropdown-toggle {
  font-size: 18px;
  position: relative;
  outline: 0px;
  box-shadow: none;
  border: 0px;
  width: 150px;
}
realtime-advanced .dropdown-menu{
  position: absolute;
  margin-top: 0px;
}

realtime-advanced .generation-info{
  margin-top: -13px;
  font-size: 14px;
  padding-left: 0; 
  margin-left: -14px;
  font-weight: 400;
  padding-right: 0;
}

realtime-advanced .powerReactive{
  font-size: 15px !important;
}

realtime-advanced .dropdown-menu > li{
  border-bottom: none;
  padding: unset;
}

realtime-advanced .title h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  border-bottom: 1px solid var(--grayscale---gray-700);
  padding-bottom: 8px;
}
realtime-advanced .dropdown-menu a{
  padding: 10px;
  line-height: 20px;
  font-size: 14px;
  color: var(--grayscale---gray-600);
}
realtime-advanced .dropdown-menu a:hover {
  background-color: var(--grayscale---gray-100);
}

realtime-advanced #measurelines{
  margin-left: 0px;
  font-weight: 500;
  .checkbox-label {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 0px;
    width: 100%;
    padding-left:0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    .cbx {
      width: 14px;
      height: 14px;
    }
    .cbx::after{
      top: 1px;
      left: 3px;
      width: 6px;
      height: 9px;
    }
  }
}
realtime-advanced #measurelines .lbl{
  font-weight: 500;
  margin-bottom: 0;
}

realtime-advanced .row-repeat:first-child #measurelines{
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grayscale---gray-200);
}

realtime-advanced .row-repeat:first-child #measurelines.space-info{
  border-bottom: 1px solid var(--grayscale---gray-200);
  height: 55px;
  margin-bottom: 2px;
}

realtime-advanced .row-values > div {
  color: var(--grayscale---gray-600);
  font-weight: 400;
  font-size: 14px;
  text-align: end;
  line-height: 19.4px; 
}

realtime-advanced .row-repeat{
  overflow: hidden;
  padding-top:0px;
  padding-bottom:6px;
}
realtime-advanced .lost-connection{
  padding-top: 10px;
}
realtime-advanced .ajust-size{
  width: 100%;
  .serie-simbol {
    color: var(--grayscale---gray-400);
    margin-left: 4px;
  }
}
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
  realtime-advanced #measurelines{
    display: flex;
    justify-content: space-between;
    padding-right: 0px;
  }
  .realtime-component .row-values{
    display: contents;
    margin-right: 0px;
    margin-left: 15px;
  }
  realtime-advanced .generation-info{
    position: absolute;
    margin-top: 25px;
  }
 }

 @media (min-width: 1000px) {
  realtime-advanced .power-container{
    float: left;
  }
  realtime-advanced .live-widget{
    width: 50%;
    float: left;
  }
  realtime-advanced .realtime-size-ajust{
    width: calc(100% - 147px);
    float: left;
    position: relative;
  }
  .table-ajust-size{
    margin-left: 35px;
  }
 }

@media (max-width: 1000px){
  panel-supervisor .panel-ga>.panel-body {
    height: 415px!important;
  }
  panel-supervisor .live-widget{
    height: 500px;
  }
}
 @media (max-width: 1000px) {
  realtime-advanced .power-container{
    float: left;
  }
  realtime-advanced .live-widget{
    width: 100%;
    float: left;
  }
  /* realtime-advanced .ajust-size{
    width: 100%;
    padding-right: 15px;
  } */
  realtime-advanced .realtime-size-ajust{
    width: calc(100% - 147px);
    float: left;
  }
  realtime-advanced .power-selector-row{
    padding-left: 15px;
  }
  .table-ajust-size{
    margin-left: 180px;
  }
 }

 realtime-advanced .icon-img{
  display: grid;
  padding-left: 38px;
  margin-bottom: -25px;
 }


 @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  .selector:not(*:root), .goal-value {
    display: -webkit-inline-box;
    position: absolute;
    right: 25px;
  }
 }
 
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  realtime .icon-bolt{
    -webkit-mask-image: url(/assets/images/custom_icons/if_icon-ios7-bolt_211696.svg);
    -webkit-mask-size: 27px;
    -webkit-mask-repeat: no-repeat;
    background-color: var(--chartColordark);
    width: 22px;
    display: inline-block;
    height: 23px;
  }
}

.p-uns {
  padding-left: unset;
  padding-right: unset;
}

