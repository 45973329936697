task-manager .container-fluid {
  padding-top: 30px;
  padding-left: 30px;
  overflow:visible !important;
  display: absolute;
}

task-manager section {
    margin-top: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--grayscale---gray-200);
}

task-manager section .row:last-child {
    margin-bottom: 8px;
}

/*source selector custom css*/
task-manager .dropdown-meter{
  margin-top: 0px;
}

task-manager source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}
task-manager .invalid-source source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--error---error-500);
}

task-manager .invalid-source source-selector .dropdown-toggle#drop-meters:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
}

task-manager source-selector .caret{
  top: 16px;
}

task-manager .consolidation-table{
  margin-top: -17px;
  margin-bottom: 0px;
}

task-manager #consolidation{
  font-weight: 800;
  font-size: 21px;
}

task-manager #consolidation label, task-manager .page-title{
  font-size: 14px;
}

task-manager #consolidation .table>tbody>tr>td{
  padding-top: 0;
  height: 50px;
  padding-top: 12px;
}
task-manager #consolidation .table>tbody>tr:first-child>td{
  border-top-width: 0px;
}

task-manager #consolidation .table>tbody>tr>td:first-child{
  text-align: left;
  padding-left: 35px;
}

task-manager td.hidden-column, task-manager th.hidden-column{
  display: none;
}  
task-manager td.edit, task-manager td.delete{
  padding-left: 0px!important;
}

task-manager .source.meter{
  max-width: unset!important;
}

#task-manager-page button.dropdown2-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}
task-manager .dropdown-menu.electric{
  left: 20px;
  top: 35px;
}

task-manager .dropdown-eletric {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}

task-manager .dropdown-eletric button {
  font-size: 18px;
  color: var(--grayscale---gray-600);
}

task-manager .dropdown-eletric .btn-default,
task-manager .dropdown-eletric .btn-default:focus {
  border: none;
  outline: 0px;
}

task-manager .dropdown-eletric .btn-default:hover,
task-manager .dropdown-eletric .btn-default:focus {
  background-color: #fff;
}
#task-manager-page .calendar-day-border {
  border: none;
  padding-left: 0px;
}

task-manager  .hour-label{
  position: absolute;
  margin-left: -20px;
}


task-manager  .hour-label.two-digits{
  margin-left: -22px;
}

task-manager  .hour-label.twelve{
  /*margin-left: -28px;*/
}
task-manager  .hour-label .twenty-four{
  margin-left: 14px;
}

@media (min-width: 1400px) {
  task-manager  .hour-label{
    margin-left: -28px;
  }

  task-manager  .hour-label.two-digits{
    margin-left: -32px;
  }

  task-manager  .hour-label.twelve{
    /*margin-left: -36px;*/
  }
  task-manager  .hour-label .twenty-four{
    margin-left: 32px;
  }
}

task-manager .navigation {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
task-manager .invalid-date .navigation {
  border: 1px solid var(--error---error-500);
}

task-manager .invalid-date .navigation:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
  top: 6px;
}

task-manager .navigation  .ga-calendar-actions{
  font-size: 14px;
  height: 30px;
}

task-manager .navigation .fa-calendar-alt{
  padding-right: 4px;
  font-size: 15px;
}

task-manager .ga-calendar-actions span{
  margin-top: 2px;
}

task-manager .formatted-date-hour {
  min-width: 50px;
}
task-manager .formatted-date-month, task-manager .formatted-date-custom{
  min-width: 50px;
}

task-manager .switch-row {
  display: none;
  height: 34px;
}

task-manager label.cbx {
  top: 0px;
}

task-manager .panel-ga .loading-component{
  display: none;
}

task-manager .panel-ga {
  box-shadow: none;
}

task-manager .panel-ga>.panel-body{
  height: 100%;
  min-height: 0%;
  padding: 0px 16px 0px 16px;
}

task-manager .not-printing .panel-ga>.panel-body{
  padding: 0px;
}

task-manager .not-printing .panel-ga>.panel-heading{
 display: none;
}

task-manager .not-printing .print-header {
  display: none;
}

task-manager .not-printing .panel-ga {
  border : none;
}

task-manager .cbx {
  display: none;
}
task-manager .not-printing .cbx {
  display: inline-block;
}
task-manager .hide-print{
  display: none;
}
task-manager .not-printing .hide-print{
  display: table-row;
}

task-manager .panel {
  margin-bottom: 16px;
}

task-manager #consolidation .table>tbody>tr>td.first-row {
  padding-top: 12px;
}

task-manager .green-color {
  color: var(--success---success-500)!important;
}

task-manager .dark-green-color {
  color: #52A247;
}

task-manager .results-period{
  text-align: right;
}

task-manager .title-container{
  display: flex;
  justify-content: center;
}

task-manager .results-title{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

task-manager .total-currency-savings{
  font-size: 42px;
  color: var(--success---success-500)!important;
}

task-manager .total-percentage-savings{
  font-size: 42px;
  color: #52A247;
}

task-manager .total-percentage-arrow-down{
  margin-left: 20px;
}

task-manager .units-section-container{
  margin-top: 20px;
}

task-manager .units-section-cols-container{
  display: flex;
  justify-content: space-evenly;
}

task-manager .units-section-col{
  display: flex;
  flex-direction: column;
}

task-manager .units-section-subtitle{
  margin: 5px 0;
}

task-manager .unit-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

task-manager .unit-percentage{
  margin-right: 15px;
  font-size: 26px;
  color: #52A247;
}

task-manager .unit-details{
  display: flex;
  flex-direction: column;
}

task-manager .unit-currency-savings{
  color: var(--success---success-500)!important;
}

task-details #task-page{
  padding: 30px;
}

task-manager #empty{
  margin-top: 15px!important;
}

task-manager .header-msg{
  font-weight: bold;
  font-size: 16px;
}

task-manager .sub-msg{
  width: 400px;
  display: inline-block;
  color: var(--grayscale---gray-500);
}

task-manager .graph-text{
  margin-top: 30px;
  text-align: center;
  color: var(--grayscale---gray-600);
  display: flex;
  flex-direction: column;
  align-items: center;
}

task-manager .graph-text .text{
  font-size: 20px;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
  min-height: 60px;
  display: flex;
  color: var(--grayscale---gray-600);
}

task-manager .graph-panel{
  margin-right: 15px;
  background: var(--grayscale---basic--white);
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
}

task-manager .cards-panel{
  background: var(--grayscale---basic--white);
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
  display: grid;
  grid-template-columns: 50% 50%;
}

task-manager .mini-panel{
  border: 1px solid #f3f3f3;
  background: var(--grayscale---basic--white);
  position: relative;
  border-radius: 5px;
  background-clip: padding-box;
  padding-top: 10px;
  padding-left: 15px;
  height: 85px;
}

task-manager .mini-panel .title{
  font-size: 18px;
}

task-manager .mini-panel .link{
  color: var(--primary-color);
  bottom: 15px;
  position: absolute;
  cursor: pointer;
}

task-manager .mini-panel .value{
  position: absolute;
  font-size: 48px;
  top: 10%;
  right: 5%;
  font-weight: 700;
}

task-manager .mini-panel.selected{
  border: 3px solid var(--primary-color);
}

task-manager .status-row{
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

#drop-status{
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

task-manager .status-selector .dropdown-menu{
  position: absolute;
  top: unset;
  left: unset;
  margin-top: 0px;
  border-radius: 4px;
  width: min-content;
}

task-manager .status-selector .dropdown-menu > li{
  border-bottom: none;
  padding: 3px 5px;
}

task-manager .status-selector .dropdown-menu a{
  padding-bottom: 5px;
  padding-top: 5px;
}

task-manager .status-selector .dropdown-menu a:hover {
  background-color: var(--grayscale---gray-200);
}

task-details .checklist-list{
  width: 100%;
  table-layout: fixed;
}

task-details .checklist-list span{
  margin: 5px 0px;
}

task-details .checklist-list .info{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
}

task-details .checklist-list .info i{
  font-size:1.4rem;
  cursor: pointer;
}

task-details input.ng-touched.ng-invalid-required{
  border:1px solid var(--error---error-500);
}

task-details input.invalid{
  border:1px solid var(--error---error-500);
}

task-details .task-form{
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto auto auto auto;
  grid-column-gap: 30px;
}

task-details .task-form .row{
  grid-column: 1;
}

task-details .task-form .side-row{
  margin-left: -15px;
  margin-right: -15px;
  grid-column: 2;
  grid-row: 1;
}

task-details .btn-container{
  padding: 15px; 
  margin: auto; 
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  width: 160%;
  grid-column: 1;
}

task-details .ok-btn{
  margin-left: -47px;
  height: 26px;
  align-self: center;
  padding-top: 2px;
}

task-details .ok-sub-btn{
  height: 26px;
  align-self: center;
  padding: 2px 4px 6px 4px;
}

task-details .fa-plus-circle{
  color: var(--secondary-color);
  cursor: pointer;
}

.edit_icon {
  font-size: 14px;
  margin-right: 5%;
  margin-bottom: -2%;
  margin-left: -1%;
  cursor: pointer;
}

.add_icon {
  background-image: url(/assets/images/custom_icons/plus-circle-dotted.svg);
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  margin-right: 5%;
  margin-bottom: -2%;
  margin-left: -1%;
  cursor: pointer;
}

.fa-trash {
  font-size: 14px;
  margin-right: 5%;
  margin-bottom: -2%;
  margin-left: -1%;
  cursor: pointer;
}

task-details .user-selector input{
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px !important;
}

task-details .user-selector .dropdown-container {
  width: 100%;
}

task-details .user-selector .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

task-details .user-selector .dropdown-menu > li:hover {
  background-color: var(--secondary-color);
}

task-details .user-selector .dropdown-item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 0px;
}

task-details .user-selector .drop-item-col-id {
  width: 50px;
}


tags-input.primary .tags .tag-item {
  background: -webkit-linear-gradient(var(--primary-color) 0%, var(--primary-color) 47%, var(--primary-color) 100%) !important;
  background: -o-linear-gradient(var(--primary-color) 0%, var(--primary-color) 47%, var(--primary-color) 100%) !important;
  background: linear-gradient(var(--primary-color) 0%, var(--primary-color) 47%, var(--primary-color) 100%) !important;
  
}

tags-input.secondary .tags .tag-item {
  background: -webkit-linear-gradient(var(--secondary-color) 0%, var(--secondary-color) 47%, var(--secondary-color) 100%) !important;
  background: -o-linear-gradient(var(--secondary-color) 0%, var(--secondary-color) 47%, var(--secondary-color) 100%) !important;
  background: linear-gradient(var(--secondary-color) 0%, var(--secondary-color) 47%, var(--secondary-color) 100%) !important;
}

tags-input.blue .tags .tag-item {
  background: -webkit-linear-gradient(#016895 0%, #016895 47%, #016895 100%) !important;
  background: -o-linear-gradient(#016895 0%, #016895 47%, #016895 100%) !important;
  background: linear-gradient(#016895 0%, #016895 47%, #016895 100%) !important;
}

tags-input.accent .tags .tag-item {
  background: -webkit-linear-gradient(#C74632 0%, #C74632 47%, #C74632 100%) !important;
  background: -o-linear-gradient(#C74632 0%, #C74632 47%, #C74632 100%) !important;
  background: linear-gradient(#C74632 0%, #C74632 47%, #C74632 100%) !important;
}

tags-input.green .tags .tag-item {
  background: -webkit-linear-gradient(#417865 0%, #417865 47%, #417865 100%) !important;
  background: -o-linear-gradient(#417865 0%, #417865 47%, #417865 100%) !important;
  background: linear-gradient(#417865 0%, #417865 47%, #417865 100%) !important;
}

tags-input.orange .tags .tag-item {
  background: -webkit-linear-gradient(#D1660F 0%, #D1660F 47%, #D1660F 100%) !important;
  background: -o-linear-gradient(#D1660F 0%, #D1660F 47%, #D1660F 100%) !important;
  background: linear-gradient(#D1660F 0%, #D1660F 47%, #D1660F 100%) !important;
}

tags-input.transparent .tags .tag-item {
  background: -webkit-linear-gradient( transparent 0%, transparent 47%, transparent 100%) ;
  background: -o-linear-gradient(transparent 0%, transparent 47%, transparent 100%);
  background: linear-gradient(transparent 0%, transparent 47%, transparent 100%);
}

tags-input.danger .tags .tag-item {
  background: -webkit-linear-gradient(#42081B 0%, #42081B 47%, #42081B 100%) !important;
  background: -o-linear-gradient(#42081B 0%, #42081B 47%, #42081B 100%) !important;
  background: linear-gradient(#42081B 0%, #42081B 47%, #42081B 100%) !important;
}

tags-input.purple .tags .tag-item {
  background: -webkit-linear-gradient(#350D36 0%, #350D36 47%, #350D36 100%) !important;
  background: -o-linear-gradient(#350D36 0%, #350D36 47%, #350D36 100%) !important;
  background: linear-gradient(#350D36 0%, #350D36 47%, #350D36 100%) !important;
}

tags-input.doing .tags .tag-item {
  background: -webkit-linear-gradient(#7A863B 0%, #7A863B 47%, #7A863B 100%) !important;
  background: -o-linear-gradient(#7A863B 0%, #7A863B 47%, #7A863B 100%) !important;
  background: linear-gradient(#7A863B 0%, #7A863B 47%, #7A863B 100%) !important;
}

tags-input.alert .tags .tag-item {
  background: -webkit-linear-gradient(#FEC51D 0%, #FEC51D 47%, #FEC51D 100%) !important;
  background: -o-linear-gradient(#FEC51D 0%, #FEC51D 47%, #FEC51D 100%) !important;
  background: linear-gradient(#FEC51D 0%, #FEC51D 47%, #FEC51D 100%) !important;
}

/* override default tag styles for colors to look less ugly */

.tags-input {
  padding: 6px 5px;
  border-radius: 4px;
  margin-top: -6px;
}
