comparative-analysis .container-fluid {
  padding-top: 24px;
  overflow:visible !important;
  display: absolute;

}

comparative-analysis section {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--grayscale---gray-200);
}

comparative-analysis section .row:last-child {
    margin-bottom: 8px;
}

/*source selector custom css*/
comparative-analysis .dropdown-meter{
  margin-top: 0px;
}

comparative-analysis source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--secondary-color);
  padding-top: 7px;
  font-size: 14px;
}
comparative-analysis .invalid-source source-selector .dropdown-toggle#drop-meters{
  border: 1px solid var(--error---error-500);
}

comparative-analysis .invalid-source source-selector .dropdown-toggle#drop-meters:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
}

comparative-analysis source-selector .caret{
  top: 16px;
}

comparative-analysis .consolidation-table{
  margin-top: -17px;
  margin-bottom: 0px;
}

comparative-analysis #consolidation{
  font-weight: 800;
  font-size: 21px;
}

comparative-analysis #consolidation label, comparative-analysis .page-title{
  font-size: 14px;
}

comparative-analysis #consolidation .table>tbody>tr>td{
  padding-top: 0;
  height: 50px;
  padding-top: 12px;
}
comparative-analysis #consolidation .table>tbody>tr:first-child>td{
  border-top-width: 0px;
}

comparative-analysis #consolidation .table>tbody>tr>td:first-child{
  text-align: left;
  padding-left: 35px;
}

#comparative-analysis-page button.dropdown2-toggle{
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}
comparative-analysis .dropdown-menu.electric{
  left: 20px;
  top: 35px;
}

comparative-analysis .dropdown-eletric {
  display: inline-block;
  vertical-align: top;
}

comparative-analysis .dropdown-eletric button {
  font-size: 18px;
  color: var(--grayscale---gray-600);
}

comparative-analysis .dropdown-eletric .btn-default,
comparative-analysis .dropdown-eletric .btn-default:focus {
  border: none;
  outline: 0px;
}

comparative-analysis .dropdown-eletric .btn-default:hover,
comparative-analysis .dropdown-eletric .btn-default:focus {
  background-color: #fff;
}
#comparative-analysis-page .calendar-day-border {
  border: none;
  padding-left: 0px;
}

comparative-analysis  .hour-label{
  position: absolute;
  margin-left: -20px;
}


comparative-analysis  .hour-label.two-digits{
  margin-left: -22px;
}

comparative-analysis  .hour-label.twelve{
  /*margin-left: -28px;*/
}
comparative-analysis  .hour-label .twenty-four{
  margin-left: 14px;
}

@media (min-width: 1400px) {
  comparative-analysis  .hour-label{
    margin-left: -28px;
  }

  comparative-analysis  .hour-label.two-digits{
    margin-left: -32px;
  }

  comparative-analysis  .hour-label.twelve{
    /*margin-left: -36px;*/
  }
  comparative-analysis  .hour-label .twenty-four{
    margin-left: 32px;
  }
}

comparative-analysis .navigation {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
comparative-analysis .invalid-date .navigation {
  border: 1px solid var(--error---error-500);
}

comparative-analysis .invalid-date .navigation:after{
  content: "\f071";
  font-family: Font Awesome\ 5 Free;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  font-weight: 900;
  top: 6px;
}

comparative-analysis .navigation  .ga-calendar-actions{
  font-size: 14px;
  height: 17px;
}

comparative-analysis .navigation .fa-calendar-alt{
  padding-right: 4px;
  font-size: 15px;
}

comparative-analysis .ga-calendar-actions span{
  margin-top: 2px;
}

comparative-analysis .formatted-date-hour {
  min-width: 50px;
}
comparative-analysis .formatted-date-month, comparative-analysis .formatted-date-custom{
  min-width: 50px;
}

comparative-analysis .print-header{
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--grayscale---gray-200);
  padding-bottom: 8px;
}

comparative-analysis .print-header>.row {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

comparative-analysis .print-header>.row>div{
  height: 60px;
}

comparative-analysis .print-header img {
  height: 55px;
  margin-top: 6px;
}

comparative-analysis .print-header .print-date{
  color:var(--grayscale---gray-400);
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 0px;
}
comparative-analysis .print-header .print-title{
  font-size: 18px;
  display: flex;
  align-items: flex-end;
}

comparative-analysis  .not-printing .switch-row {
  display: block;
}
comparative-analysis .switch-row {
  display: none;
  height: 34px;
}

comparative-analysis label.cbx {
  top: 0px;
}

comparative-analysis .panel-ga .loading-component{
  display: none;
}

comparative-analysis .panel-ga {
  box-shadow: none;
}

comparative-analysis .panel-ga>.panel-body{
  height: 100%;
  min-height: 0%;
  padding: 0px 16px 0px 16px;
}

comparative-analysis .not-printing .panel-ga>.panel-body{
  padding: 0px;
}

comparative-analysis .not-printing .panel-ga>.panel-heading{
 display: none;
}

comparative-analysis .not-printing .print-header {
  display: none;
}

comparative-analysis .not-printing .panel-ga {
  border : none;
}

comparative-analysis .not-printing .serie-legend {
  display: none;
}
comparative-analysis .cbx {
  display: none;
}
comparative-analysis .not-printing .cbx {
  display: inline-block;
}
comparative-analysis .hide-print{
  display: none;
}
comparative-analysis .not-printing .hide-print{
  display: table-row;
}

comparative-analysis .serie-legend {
  width:15px;
  height:15px;
  display: inline-block;
  background: var(--primary-color);
}

comparative-analysis .serie-legend.dataviz---lt--green {
  background: var(--dataviz---lt--green);
}
comparative-analysis .serie-legend.dataviz---md--blue {
  background: var(--dataviz---md--blue);
}
comparative-analysis .serie-legend.dataviz---lt--pink {
  background: var(--dataviz---lt--pink);
}
comparative-analysis .serie-legend.dataviz---dk--orange {
  background: var(--dataviz---dk--orange);
}
comparative-analysis .serie-legend.dataviz---dk--pink {
  background: var(--dataviz---dk--pink);
}
comparative-analysis .serie-legend.dataviz---md--yellow {
  background: var(--dataviz---md--yellow);
}
comparative-analysis .serie-legend.dataviz---dk--blue {
  background: var(--dataviz---dk--blue);
}
comparative-analysis .serie-legend.dataviz---green {
  background: var(--dataviz---md--green);
}
comparative-analysis .serie-legend.dataviz---red {
  background: var(--dataviz---red);
}
comparative-analysis .serie-legend.dataviz---lt--orange {
  background: var(--dataviz---lt--orange);
}
comparative-analysis .serie-legend.dataviz---md--purple {
  background: var(--dataviz---md--purple);
}
comparative-analysis .serie-legend.dataviz---lt--blue {
  background: var(--dataviz---lt--blue);
}
comparative-analysis .serie-legend.dataviz---dk--purple {
  background: var(--dataviz---dk--purple);
}
comparative-analysis .serie-legend.dataviz---brown {
  background: var(--dataviz---brown);
}

comparative-analysis .panel {
  margin-bottom: 16px;
}

comparative-analysis #consolidation .table>tbody>tr>td.first-row {
  padding-top: 12px;
}

comparative-analysis .formatted-date-day {
  min-width: 60px!important;
}

comparative-analysis .col-md-12, .col-sm-6, .col-md-6, .col-md-5, .col-md-1 {
  padding-left: unset;
  padding-right: unset;
}

kpi .status-percent{
  font-size: large;
  font-weight: bold;
}

kpi td.goal, kpi td.indicator{
  white-space: nowrap;
}

kpi .not-printing{
  display: none;
}
