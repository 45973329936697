.table-title {
  padding-left: 2px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: var(--grayscale---gray-600);
}

.table-dropdown {
  max-height: 300px;
  overflow-y: auto;
  display: inline-flex;
  white-space: nowrap;
  padding: 0px 8px 6px 8px;
}

.table-dropdown-icon {
  padding-right:5px;
}

table.ga-table .number,
table.ga-table tbody .number {
  text-align: right;
}

table.ga-table{
  padding-left: 8px;
  padding-right: 8px;
  table-layout: fixed;
  box-sizing: border-box;
}
  
table.ga-table thead > tr, table.ga-table  tbody > tr {
  height: 73px;
}
  
table.ga-table tbody > tr > td, table.ga-table thead > tr > th {
  vertical-align: middle;
  font-weight: 400;
}
  
table.ga-table  tbody > tr >td {
  color : var(--grayscale---gray-500);
  font-size: 14px;
  text-overflow: ellipsis;
  text-align: left;
}

meter-list table.ga-table  tbody > tr >td {
  padding-left: 23px;
}

table tbody ul{
  padding-left: 0px;
}

meter-list .checkbox input[type="checkbox"]{
    margin-top: 0px;
}

meter-list .multiselect-clear-filter{
    display: none;
}
meter-list .dropdown-menu > .active > a, 
meter-list .dropdown-menu > .active > a:focus, 
meter-list .dropdown-menu > .active > a:hover {
  background-color: var(--grayscale---basic--white);
}
  
meter-list li.active input{
  background-color: var(--primary-color);
}

table.ga-table thead > tr > th {
  color: var(--grayscale---gray-600);
  font-size: 16px;
  padding: 10px 10px;
}
  
.dataTables_scrollBody table thead{
  overflow: -moz-hidden-unscrollable;
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .dataTables_scrollBody table thead{
    display: none;
  }
}
  
.dataTables_scrollHeadInner, .dataTables_scrollHeadInner table{
  width: 100%;
}
  
table.ga-table{
  margin-bottom: 0;
}
  
.table-responsive-ga{
  padding-left: 0px;
  padding-top: 0px!important;
  overflow: initial;
  overflow: auto !important;
}
  
/* .mobile .table-responsive-ga {
  overflow: auto; 
} */
  
table.ga-table.dataTable thead th {
  border-bottom: 1px solid var(--grayscale---gray-400);
}
  
table.ga-table.dataTable tbody td {
  border-bottom: 1px solid var(--grayscale---gray-200);
}

table.ga-table.dataTable tbody td.actions td {
  border-bottom-width: 0px;
}

table.ga-table.dataTable tbody td.childCntrl{
  cursor: pointer;
}

table.ga-table.dataTable tbody tr.shown td.childCntrl i.fa-chevron-down{
  transform: rotate(180deg);
}
  
table.ga-table.dataTable.no-footer {
  border-bottom: 1px solid var(--grayscale---gray-200);
}
table.ga-table.dataTable tbody td.no-border {
  border-bottom: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--grayscale---basic--white) !important;
  background:var(--primary-color);
  font-weight: bold;
  border-radius: 5px;
  border:0px;
}
  
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: var(--grayscale---basic--white) !important;
  background:var(--secondary-color);
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid transparent;
}
  
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.3em 0.7em;
}
  
div.dataTables_wrapper div.dataTables_info {
  padding-left: 9px;
  color: var(--grayscale---gray-500);
  padding-top: 14px;
}
  
div.dataTables_wrapper div.dataTables_paginate {
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
}  

table .fa.fa-ellipsis-vertical, table .fa.fa-user-plus {
  cursor: pointer;
}

table .fa.fa-ellipsis-vertical:hover, table .fa.fa-user-plus:hover {
  color: var(--grayscale---gray-500);
}

table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  width: 10px;
  height: 22px;
  position: unset;
  top: 25px;
  font-family: "Font Awesome 6 Pro";
  padding-left: 9px;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  color: #c4c4ce;
  font-size: 0.8em;
  padding-top: 0.12em;
  font-weight: 900;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
  font-size: 0.8em;
  font-weight: 900;
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
  font-size: 0.8em;
  font-weight: 900;
}

i.icon-drag {
  visibility: hidden;
  background-image: url(/assets/images/custom_icons/drag_grip.svg);
  width: 9px;
  height: 15px;
  display: inline-block;
  background-repeat: no-repeat;
  padding-right: 15px;
  margin-bottom: -2px;
}
  
th:hover i.icon-drag {
  visibility: visible;
  cursor: grab;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  th:hover i.icon-drag {
    visibility: visible;
    cursor: -webkit-grab;
  }
}

th.hover, td.hover{
  background-color: whitesmoke;
}

th:active i.icon-drag, th:active, i.icon-drag:active,
table.DTCR_clonedTable th i.icon-drag {
}

table.DTCR_clonedTable th{
  position: relative;
  cursor: move !important;
  background-color: var(--grayscale---basic--white);
  width: 100% !important;
  height: 73px;;
}
  
table.DTCR_clonedTable th.type{
  width: 175px !important;
}

table.DTCR_clonedTable th.status{
  width: 95px !important;
}

table.DTCR_clonedTable.dataTable{
  position:absolute !important;
  background-color:rgba(255,255,255,0.7);
  z-index:202;
}
  
table.DTCR_clonedTable thead th.sorting:after,
table.DTCR_clonedTable thead th.sorting_asc:after,
table.DTCR_clonedTable thead th.sorting_desc:after {
  width: 10px;
  height: 22px;
  position: absolute !important;
  top: 25px;
  font-family: "Font Awesome 6 Pro";
  padding-left: 9px;
}
  
div.DTCR_pointer{
  width:1px;
  background-color: var(--primary-color);
  z-index:201
}

.source {
  font-family: "Roboto";
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white);
  font-size: 14px;
  border: 0px;
  border-radius: 5px;
  font-weight: bold;
  height: 20px;
  padding: 3px;
  border-collapse: separate;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
  display: inline-table;
  cursor: pointer;
  float: left;
  max-width: 140px;
}

tr.childs .source.meter{
  margin-left: 38px;
}

.source.meter {
background-color: var(--primary-color);
}

.source.meter.meter:hover {
background-color: var(--hover1);
color: var(--grayscale---basic--white);
}

.source.group:hover {
background-color: var(--hover2);
color: var(--grayscale---basic--white);
}

.source.group {
  background-color: var(--secondary-color);
}
.realtime-loading {
  position: absolute;
  text-align: left;
  height: 100%;
  width: 100%;
}
.realtime-loading-small {
  height: 90%;
}

.lost-connection {
  height: 90%;
  font-size: 16px;
}

tr.childs{
  background-color: var(--grayscale---gray-100)!important;
}

td .lost-connection.show-offline-flag-icon {
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    left: 0;
    height: 123px;
    right: 0;
}

table ul > .minify-dropdown-btn{
  overflow: hidden!important;
  width: 70px!important;
}

table tr:last-child .dropdown-toggle ~ ul.dropdown-menu{
  max-height: 150px!important;
}