ga-loading .spinner {
  margin: auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

ga-loading .spinner > div {
  background-color: var(--primary-color);
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

ga-loading .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

ga-loading .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

ga-loading .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

ga-loading .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

ga-loading .load-complete {
  color: var(--primary-color);
  font-size: 39px;
}

ga-loading .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}