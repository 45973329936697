.description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .texts {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;

        .title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #404040;
        }

        .subtitle  {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #737373;
            margin-top: 4px;
        }
    }

    .action-buttons {
        padding-bottom: 24px;
    }
}

consuming-unit-list .name-link {
  background-color: var(--primary-color);
  color: var(--grayscale---basic--white) !important;
  font-weight: bold !important;
  border-radius: 5px;
  padding: 12px 10px 12px 20px;
  cursor: pointer;
}

consuming-unit-list .add-button {
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

.row {
    margin-right: unset;
    margin-left: unset;
}

.p-uns {
    padding-right: unset;
    padding-left: unset;
}