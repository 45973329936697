.panel-body.appliances {
    height: 100%;
    font-size: 14px;
    padding: 0px;
    min-height: 198px;
}

.appliances .table>tbody>tr>td.first,
.appliances .table>tbody>tr>td.first a {
    font-weight: bold;
}

.label-green {
    font-weight: 800;
}

.label-red {
    font-weight: 800;
}

.panel .empty {
    height: 100px;
    min-height: 100px;
}

.appliances  .loading-component{
  background-color: var(--grayscale---basic--white);
  filter: alpha(opacity=90);
  opacity: 0.9;
  -moz-opacity: 0.9;
  -webkit-opacity: 0.9;
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 99%;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  margin: 0px;
  left: 0;
}

.appliances  .realtime-loading {
  text-align: center;
  height: 90%;
}
