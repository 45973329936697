group-meters-panel .side-panel-ga-content .container-fluid{
  border-bottom: 0px;
  padding: 0;
}

group-meter-list .meter-row{
  color : var(--grayscale---gray-700);
  font-size: 14px;
  padding: 4px 0px;
  height: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid var(--grayscale---gray-200);
  cursor: pointer;
  position: relative;
	min-height: 70px;
	background: var(--grayscale---basic--white);
	gap: 6px;
}

group-meter-list .meter-row .meter-list-title {
	font-size: 12px;
	font-weight: 500;
}

group-meter-list .meter-row .meter-list-value {
	display: flex;
	flex-direction: column;
}
roup-meter-list .meter-row .meter-list-value span{
	font-size: 12px;
	font-weight: 400;
}

group-meter-list .meter-row a {
	color : var(--grayscale---gray-700);
}

group-meter-list .meter-row .group-value {
	flex-direction: row;
  display: flex;
  align-items: end;
  width: 40%;
	padding-left: 10px;
}

group-meters-panel .meters-scrollbox::-webkit-scrollbar-thumb {
  background: var(--grayscale---gray-400);
  border-radius: 10px;
  border: 5px solid #FFF;
}

group-meter-list .meter-row .group-value span{
	font-size: 12px;
	color: var(--grayscale---gray-600);

}

group-meter-list .meter-row:hover {
	background: var(--grayscale---gray-100);
}

group-meter-list .column-card {
  display: flex;
  flex-direction: column;
  width: calc(100%);
}

group-meter-list .meter-label {
  padding-left: 0px;
  position: initial;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
group-meter-list .meter-values {
  padding-right: 0px;
  padding-left: 0px;
  text-align: left;
  width: calc(100%);
}

group-meter-list .meter-legend {
  position: absolute;
  width: 5px;
  height: calc(100%);
  left: 0;
}

group-meter-list .meter-dataviz---lt--green {
  border-left: 6px solid var(--dataviz---lt--green);
}

group-meter-list .meter-dataviz---md--blue {
	border-left: 6px solid  var(--dataviz---md--blue);
}

group-meter-list .meter-dataviz---lt--pink {
  border-left:6px solid var(--dataviz---lt--pink);
}

group-meter-list .meter-dataviz---dk--orange {
  border-left:6px solid var(--dataviz---dk--orange);
}

group-meter-list .meter-dataviz---dk--pink {
  border-left:6px solid var(--dataviz---dk--pink);
}

group-meter-list .meter-dataviz---md--yellow {
  border-left:6px solid var(--dataviz---md--yellow);
}

group-meter-list .meter-dataviz---dk--blue {
  border-left:6px solid var(--dataviz---dk--blue);
}

group-meter-list .meter-dataviz---green {
  border-left:6px solid var(--dataviz---md--green);
}

group-meter-list .meter-dataviz---red {
	border-left:6px solid var(--dataviz---red);
}

group-meter-list .meter-dataviz---lt--orange {
  border-left:6px solid var(--dataviz---lt--orange);
}

group-meter-list .meter-dataviz---md--purple{
  border-left:6px solid var(--dataviz---md--purple);
}

group-meter-list .meter-dataviz---lt--blue {
  border-left:6px solid var(--dataviz---lt--blue);
}

group-meter-list .meter-dataviz---dk--purple {
  border-left:6px solid var(--dataviz---dk--purple);
}

group-meter-list .meter-dataviz---brown {
  border-left:6px solid var(--dataviz---brown);
}

group-meter-list .meter-dataviz---brown {
  border-left:6px solid var(--dataviz---brown);
}

group-meter-list .meter-invoice-value .col-sm-12,
group-meter-list .meter-active-energy .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

group-meter-list .meter-label {
  font-weight: 800;
}

group-meter-list .meter-icon {
  cursor: pointer;
  z-index: 999;
}

group-meter-list .meter-icon .fa-bar-chart{
  color: var(--grayscale---basic--white);
}

group-meters-panel .meters-scrollbox{
	position: fixed;
	max-height: 40vh;
	overflow-y: auto;
	width: 100% !important;
	z-index: 111;
}

group-meters-panel .tabs {
	padding: 0 12px;
}

group-meters-panel .unit {
  font-weight: 400;
}

group-meters-panel .money-value{
  font-weight: 400;
}

