ga-expense ga-expense .expense-panel .panel-body.expense-meter {
    height: 543px;
    position: relative;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified {
    background-color: #d3d3d3;
    margin: -5px -5px 0px -5px;
    width: calc(100% + 10px);
    height: 32px;
}

ga-expense .expense-appliances {
    height: 297px;
}

ga-expense .list-group {
    padding-left: 0;
    margin-bottom: 20px;
}


ga-expense .appliances .list-group {
    height: 270px;
    margin-bottom: 0px;
}

.expenses-item-line,
.expenses-item-line-category,
ga-expense .unclassified-consumption-line {
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #D5D5D5;
    font-size: 15px;
    outline: none;
}

ga-expense .appliances .expenses-item-line {
    padding-top: 11px;
    padding-bottom: 11px;
}

.appliances .expenses-item-line>.row,
ga-expense .unclassified-consumption-line>.row {
    position: relative;
    height: 45px;
}

.expenses-item-line>.row,
ga-expense .unclassified-consumption-line>.row {
    padding-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

ga-expense .expenses-item-line {
    cursor: pointer;
}

.expenses-item-line,
.expenses-item-line-category,
ga-expense .unclassified-consumption-line {
    font-size: 15px;
}

.categories,
ga-expense .appliances {
    color: var(--grayscale---gray-700);
}


.appliance-number,
ga-expense .appliance-action-icon {
    padding-left: 10px;
}

ga-expense .appliance-number {
    height: 100%;
    padding-right: 0px;
}

.appliance-number,
.appliance-name,
.appliance-action-icon,
ga-expense .invoice-detail-item {
    position: relative;
    /*height: 45px;*/
}

div.appliance-name,
ga-expense div.invoice-detail-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
    padding-left: 0px;
}

ga-expense div.appliance-name {
    font-size: 14px;
}

ga-expense .appliance-values .ng-binding {
    font-size: 14px;
}

.appliance-number,
ga-expense .appliance-action-icon {
    padding-left: 10px;
}

.appliance-number,
.appliance-name,
.appliance-action-icon,
ga-expense .invoice-detail-item {
    position: relative;
    height: 45px;
}

ga-expense .appliance-action-icon {
    display: none;
}






/* -----------------------------------*/


/* ------->>> Expense Panel <<<-------*/


/* -----------------------------------*/

ga-expense ul.expense-tabs.nav-tabs.nav-justified {
    background-color: #d3d3d3;
    margin: -5px -5px 0px -5px;
    width: calc(100% + 10px);
    height: 34px;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li {
    max-height: 34px !important;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li a {
    color: var(--grayscale---basic--white);
    max-height: 34px !important;
    font-size: 17px;
    font-weight: 800;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li.active a {
    color: var(--grayscale---gray-700);
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li a:hover {
    color: var(--secondary-color);
    border-bottom: none;
}


/* -----------------------------------*/


/* ------->>> Expense Panel <<<-------*/


/* -----------------------------------*/

ga-expense ul.expense-tabs.nav-tabs.nav-justified {
    background-color: var(--grayscale---basic--white);
    /*margin: -5px -5px 0px -5px;*/
    width: 100%;
    height: 37px;
    margin-left: 0px;
    margin-top: 0px;
    border-bottom: 1px solid var(--grayscale---gray-200);
    margin-bottom: -2px;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li {
    max-height: 34px !important;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li a {
    color: var(--grayscale---gray-700);
    max-height: 32px !important;
    font-size: 16px;
    font-weight: normal;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li.active {
    border-bottom: 3.82px solid var(--primary-color);
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified li.active a {
    color: var(--grayscale---gray-700);
    font-weight: bold;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified>li:first-child>a {
    border-radius: 0px;
    border: 0px;
}

ga-expense ul.expense-tabs.nav-tabs.nav-justified>li:last-child>a {
    border-right: 0px;
    border-radius: 0px 0px 0px 0px;
    border-bottom: 0px;
}

ga-expense ul.expense-tabs span {
   position: relative;
   bottom: 5px;
   font-size: 14px;
}

ga-expense ul.expense-tabs li span.identify-badge {
    background-color: var(--secondary-color);
    position: absolute;
    margin-bottom: 0px;
    right: 2px;
    padding: 3px 6px;
}

ga-expense .identify-img {
    display: block;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 30px;
}

ga-expense .expense-pagination-container {
    height: 28px;
}

ga-expense .expense-pagination {
    color: var(--grayscale---gray-700);
    height: 28px;
    margin: 0px -5px;
    width: 104%;
}

ga-expense .expense-pagination .disabled {
    color: #d3d3d3;
}

ga-expense .expense-pagination div {
    width: 50%;
    height: 100%;
    float: left;
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: 17px;
    line-height: 28px;
    color: var(--grayscale---gray-700);
}

ga-expense .expense-pagination div button {
    font-size: 17px;
    line-height: 28px;
    text-decoration: none;
    padding: 0px;
    color: var(--grayscale---gray-700);
    border: 0px;
    height: 28px;
    margin-top: -3px;
    width: 100%;
    border: none;
    background: none;
    outline: none;
    border-radius: 0px;
}

ga-expense .expense-pagination div button:hover {
    background-color: var(--secondary-color);
    color: var(--grayscale---basic--white);
}

ga-expense .expense-pagination div button.btn:focus {
    outline: none;
}

ga-expense .expense-pagination div button i {
    font-size: 24px;
    line-height: 28px;
}

ga-expense .expense-prev {
    border-right: 1px solid #ccc;
}

ga-expense .expense-prev i {
    float: left;
    padding-left: 10px;
}

ga-expense .expense-next i {
    float: right;
    ;
    padding-right: 10px;
}

ga-expense .expense-panel .total {
    height: 238px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
}

ga-expense .expense-panel .total.meter {
    height: 162px;
}

ga-expense .expense-panel .total.meter.no-disaggregation {
    height: 238px;
}

ga-expense .expense-panel .total.meter .invoice-detail {
    width: 100%;
}

ga-expense .expense-panel .total.meter .invoice-detail .col-md-12 {
    padding-left: 0;
    padding-right: 7px;
}

ga-expense .expense-panel .total.generation {
    height: 162px;
}

ga-expense .expense-panel .total .total-label {
    color: var(--secondary-color);
    font-weight: 700;
    font-size: 21px;
    display: inline-block;
    align-self: flex-start;
    margin-left: 8px;
}

.totalCurrency,
ga-expense .totalWatts {
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    /*min-height: 50%;
    width:auto;*/
    color: var(--secondary-color);
    /*  margin:  auto;*/
    padding-top: 0px;
    letter-spacing: -1px;
}

ga-expense .totalCurrency {
    /*margin-top: 10px;
  line-height: 40px;*/
}

ga-expense .totalWatts {
    border-bottom: 0px;
    font-size: 30px;
    font-weight: 300;
    padding-top: 0px;
}

ga-expense .totalLight {
    font-size: 30px;
    font-weight: 300;
}

ga-expense .expense-panel .panel-body.expense-meter {
    height: 545px;
    position: relative
}

ga-expense .expense-panel .panel-body.expense-meter.no-disaggregation {
    height: 248px;
}

ga-expense .expense-appliances {
    height: 297px;
}

.categories,
ga-expense .appliances {
    color: var(--grayscale---gray-700);
}

ga-expense .appliances .list-group {
    height: 270px;
    margin-bottom: 0px;
}

.expenses-item-line,
.expenses-item-line-category,
ga-expense .unclassified-consumption-line {
    margin-left: 0px;
    margin-right: -5px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #D5D5D5;
    font-size: 15px;
    outline: none;
}

ga-expense .expenses-item-line {
    cursor: pointer;
}

.expenses-item-line>.row,
ga-expense .unclassified-consumption-line>.row {
    /*padding-left: 10px;*/
    padding-left: 15px;
    padding-right: 15px;
}

ga-expense .unclassified-consumption-line {
    border: none;
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
}

.appliances .expenses-item-line>.row,
ga-expense .unclassified-consumption-line>.row {
    position: relative;
    height: 45px;
}

ga-expense .appliances .expenses-item-line {
    padding-top: 11px;
    padding-bottom: 11px;
}

ga-expense .unclassified-consumption-line {
    padding-top: 1px;
    padding-bottom: 1px;
}

ga-expense .appliances .expenses-item-line>.row>div>div:not(.row) {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 21px;
}

.appliance-number,
.appliance-name,
.appliance-action-icon,
ga-expense .invoice-detail-item {
    position: relative;
    height: 45px;
}

ga-expense .appliance-number {
    height: 100%;
}

.appliance-action-icon-container,
ga-expense .appliance-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.appliance-name,
ga-expense div.invoice-detail-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
    padding-left: 0px;
}

ga-expense div.appliance-name {
    font-size: 14px;
}

ga-expense div.invoice-detail {
    padding-top: 4px;
    padding-bottom: 4px;
}

ga-expense div.invoice-detail-item {
    font-size: 20px;
}

ga-expense div.invoice-detail-item .icon-holder {
    float: left;
    width: 30px;
    text-align: center;
}

ga-expense div.appliance-number>div {
    margin-top: 7px;
}

.appliance-number,
ga-expense .appliance-action-icon {
    padding-left: 10px;
}

ga-expense .appliance-action-icon {
    display: none;
}

.appliance-action-icon i,
ga-expense .appliance-number i {
    cursor: pointer;
    font-size: 16px;
}

ga-expense .appliance-values {
    padding: 0px;
    margin-left: -3%;
}

a.categories-item,
ga-expense a.categories-more {
    font-weight: 600;
    font-size: 17px;
    border-bottom: 1px solid #4f4f51;
    color: #4f4f51;
    border-radius: 0px;
    position: relative;
    display: block;
    vertical-align: middle;
    padding: 10px 0px 10px 0px;
}

ga-expense a:hover.categories-item {
    text-decoration: none;
    color: #515253;
}

ga-expense a.ranking-more {
    border-bottom: 0px;
    font-weight: 600;
}

.appliance-number-container,
ga-expense .appliance-action-icon-container {
    width: 27px !important;
    height: 27px !important;
    cursor: pointer;
}

ga-expense .appliance-number-container div {
    font-size: 20px;
    font-weight: 200;
    position: relative;
    text-align: center;
}

ga-expense a.categories-item>.price {
    float: right;
    font-weight: 400;
}

ga-expense a.categories-item>.r-item {
    padding-bottom: 5px;
    float: left;
}

ga-expense .category-description {
    display: none;
    padding-right: 0px;
    padding-left: 10px;
}

ga-expense .category-description span {
    text-align: center;
    display: block;
}

ga-expense .event-type-description-icon {
    font-size: 17px;
    font-weight: 700;
}

ga-expense .event-type-description-name {
    font-size: 14px;
    margin-top: -5px;
}

ga-expense .category-details {
    display: block;
    height: 39px;
    padding-right: 0px;
}

ga-expense .category-details>span {
    margin: auto;
}

ga-expense .event-type {
    /*  background-color: var(--grayscale---gray-700);*/
    height: 39px;
    width: 70px;
    display: block;
    /*  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;*/
    margin-left: -5px;
    background-repeat: no-repeat;
}

ga-expense .event-type-back-arrow {
    -webkit-mask-image: url('/assets/images/event-type-back-arrow.svg');
    mask-image: url('/assets/images/event-type-back-arrow.svg');
}

ga-expense .event-type-smre {
    /*-webkit-mask-image: url(/assets/images/event-type-smre.svg);
  mask-image: url(/assets/images/event-type-smre.svg);*/
    background-image: url('/assets/images/event-type-smre-dark.svg');
}

ga-expense .event-type-bgre {
    /*-webkit-mask-image: url(/assets/images/event-type-bgre.svg) ;
  mask-image: url(/assets/images/event-type-bgre.svg) ;*/
    background-image: url('/assets/images/event-type-bgre-dark.svg');
}

ga-expense .event-type-smrf {
    /*  -webkit-mask-image: url(/assets/images/event-type-smrf.svg) ;
  mask-image: url(/assets/images/event-type-smrf.svg);*/
    background-image: url('/assets/images/event-type-smrf-dark.svg');
}

ga-expense .event-type-refr {
    /*  -webkit-mask-image: url(/assets/images/event-type-smrf.svg) ;
  mask-image: url(/assets/images/event-type-smrf.svg) ;*/
    background-image: url('/assets/images/event-type-smrf-dark.svg');
}

ga-expense .event-type-bgrf {
    /*-webkit-mask-image: url(/assets/images/event-type-bgrf.svg);
  mask-image: url(/assets/images/event-type-bgrf.svg);*/
    background-image: url('/assets/images/event-type-bgrf-dark.svg');
}

ga-expense .event-type-liel {
    /*  -webkit-mask-image: url(/assets/images/event-type-liel.svg) ;
  mask-image: url(/assets/images/event-type-liel.svg) ;*/
    background-image: url('/assets/images/event-type-liel-dark.svg');
}

ga-expense .event-type-description-liel {
    margin-top: 7px;
    font-size: 16px;
}

ga-expense .event-type-others {
    -webkit-mask-image: url('/assets/images/event-type-others.svg');
    mask-image: url('/assets/images/event-type-others.svg');
}


/*Modal Unclassified Explanation*/

ga-expense #modalUnclassifiedExplanation {
    font-size: 16px;
    font-weight: 100;
}

ga-expense #modalUnclassifiedExplanation .modal-body {
    padding: 20px;
}

ga-expense #modalUnclassifiedExplanation .row {
    margin-bottom: 10px;
}

ga-expense #modalUnclassifiedExplanation .power-off {
    text-align: center;
    color: #ccc;
    font-size: 60px;
}

ga-expense #modalUnclassifiedExplanation .modal-footer {
    text-align: center;
    ;
}

ga-expense .modal-icon {
    padding-left: 10px;
}


/*end Modal Unclassified Explanation*/


/*interactions*/

ga-expense .expenses-item-line:hover {
    background-color: var(--grayscale---gray-100);
    color: var(--grayscale---gray-700);
}

ga-expense .expenses-item-line.active {
    background-color: var(--grayscale---gray-700);
    color: var(--grayscale---basic--white);
}

ga-expense .expenses-item-line.active .appliance-number-container {
    border-color: var(--grayscale---basic--white);
}

ga-expense .expenses-item-line.active {
    background-color: var(--grayscale---gray-700);
    color: var(--grayscale---basic--white);
}

ga-expense .expenses-item-line.active:hover {
    background-color: var(--grayscale---gray-700);
    color: var(--grayscale---basic--white);
}


ga-expense .expenses-item-line:hover .appliance-action-icon {
    display: block;
}

ga-expense .expenses-item-line:hover .appliance-number {
    display: none;
}

ga-expense .expenses-item-line:hover .category-description {
    display: block;
}

ga-expense .expenses-item-line:hover .category-details {
    display: none;
}

ga-expense .expenses-item-line:hover span.price {
    border-color: var(--grayscale---basic--white);
}

ga-expense .expenses-item-line:hover .event-type {
    /*  background-color: var(--grayscale---basic--white);*/
}

ga-expense .expenses-item-line:hover .event-type-smre {
    background-image: url('/assets/images/event-type-smre.svg');
}

ga-expense .expenses-item-line:hover .event-type-bgre {
    background-image: url('/assets/images/event-type-bgre.svg');
}

ga-expense .expenses-item-line:hover .event-type-smrf {
    background-image: url('/assets/images/event-type-smrf.svg');
}

ga-expense .expenses-item-line:hover .event-type-refr {
    background-image: url('/assets/images/event-type-smrf.svg');
}

ga-expense .expenses-item-line:hover .event-type-bgrf {
    background-image: url('/assets/images/event-type-bgrf.svg');
}

ga-expense .expenses-item-line:hover .event-type-liel {
    background-image: url('/assets/images/event-type-liel.svg');
}

ga-expense .appliance-action-icon-container:hover {
    /*background-color: var(--error---error-500);*/
}

/*.expenses-item-line:hover .appliance-number-container,
ga-expense .expenses-item-line:hover .appliance-action-icon-container {
    border: 2px solid #fff;
}*/

ga-expense .unclassified-consumption-line .modal-icon .appliance-number {
    display: block;
    padding: 0px;
}

ga-expense .unclassified-consumption-line .modal-icon .appliance-action-icon {
    display: none;
    padding: 0px;
}

ga-expense .unclassified-consumption-line div.appliance-name {
    font-size: 14px;
}

ga-expense .unclassified-consumption-line .modal-icon:hover .appliance-number {
    display: none;
}

ga-expense .unclassified-consumption-line .modal-icon:hover .appliance-action-icon {
    display: block;
}

ga-expense .expenses-item-line .appliance-name {
    margin-left: -24px;
}

ga-expense .expenses-item-line:hover .appliance-name {
    margin-left: 0px;
}

ga-expense .expenses-item-line .appliance-values {
    margin-left: 18px;
}

ga-expense .expenses-item-line:hover .appliance-values {
    margin-left: -6px;
}



/*End interactions*/

ga-expense span.price {
    font-size: 20px;
}

ga-expense .activeEnergy {
    font-size: 20px;
}

ga-expense .activeEnergy span {
    font-weight: 100;
}

ga-expense .bottom-panels .col-sm-4 {
    padding: 5px 5px 0 5px;
}

ga-expense .working i {
    font-size: 95px;
    color: var(--secondary-color);
    padding-top: 28px;
    padding-bottom: 15px;
}

ga-expense .working p {
    font-size: 16px;
}

ga-expense .working {
    padding: 30px 10px 0px 10px;
}

ga-expense .working h3 {
    color: var(--grayscale---gray-700);
    margin-top: 0px;
}


/*Modal appliance*/

ga-expense #modalClassificationApplianceDetail {
    color: var(--grayscale---gray-700);
}

ga-expense #modalClassificationApplianceDetail .form-group {
    width: 100%;
}

ga-expense #modalClassificationApplianceDetail label {
    font-size: 17px;
    font-weight: 100;
}

ga-expense #modalClassificationApplianceDetail .form-control {
    width: 80%;
    font-size: 17px;
    font-weight: 100;
}

ga-expense #modalClassificationApplianceDetail table {
    margin-bottom: 0px;
}

ga-expense #modalClassificationApplianceDetail table>thead>tr>th {
    font-weight: 600;
}

ga-expense #modalClassificationApplianceDetail .row {
    margin-bottom: 20px;
}

ga-expense #modalClassificationApplianceDetail .row.all-occurences {
    text-align: center;
    margin-bottom: 0px;
}

ga-expense #modalClassificationApplianceDetail .message {
    margin-bottom: 5px;
}

ga-expense .all-occurences ul {
    margin-bottom: 0px;
}

.occurences-prev,
ga-expense .occurences-next {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.occurences-prev button,
ga-expense .occurences-next button {
    color: var(--grayscale---gray-700);
    width: 100%;
    background: none;
}

.occurences-prev .disabled,
ga-expense .occurences-next .disabled {
    color: #d3d3d3;
}

.occurences-prev button:hover,
.occurences-next button:hover,
.occurences-prev:hover,
ga-expense .occurences-next:hover {
    text-decoration: none;
    color: var(--secondary-color);
}

.occurences-prev button:focus,
ga-expense .occurences-next button:focus {
    outline: none;
    color: var(--grayscale---gray-700);
    text-decoration: none;
}

ga-expense .pagination-consumption {
    padding: 0px;
    display: inline-block;
    margin: 0 10px;
}

ga-expense .pagination-consumption li {
    display: inline;
    list-style: none;
    padding: 0 5px;
}

ga-expense .pagination-consumption li.current {
    list-style: none;
    color: var(--secondary-color);
    font-weight: 600;
}

ga-expense .pagination-consumption span {
    cursor: pointer;
}

ga-expense .power-icon {
    border: 2px solid;
    border-radius: 20px;
    font-size: 17px;
    margin-right: 10px;
}

ga-expense .power-icon i {
    left: 2px;
    top: 2px;
}

ga-expense h3.last-occurrences {
    font-family: "Roboto";
    margin-top: 0px;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 400;
}

ga-expense .table>tbody>tr>td {
    border-top: 0px;
}

ga-expense .expense-scrollbox {
    overflow: auto;
    height: 339px;
    width: 100% !important;
    padding-right: 0px !important;
}

ga-expense .unclassified-consumption .fa-angle-right {
    font-size: 20px;
    font-weight: bold;
    margin-top: 4%;
}

ga-expense .mobile .side-panel-ga-open {
    width: 100%;
}

ga-expense .mobile .expense-scrollbox {
  /*  margin-top: 23px;*/
}

ga-expense .mobile  ul.expense-tabs.nav-tabs.nav-justified li {
  width: 50%;
  float: left;
}
