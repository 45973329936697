consumption-profile .table > thead > tr > th,
consumption-profile .table > tbody > tr > td {
  border : 1px var(--grayscale---gray-400) solid;
  vertical-align: center;
  color : var(--grayscale---gray-700);
  border-left: 0px;
  border-right:  0px;
}

consumption-profile .table>thead:first-child>tr:first-child>th {
  border : 1px var(--grayscale---gray-400) solid;
}

consumption-profile .table > tbody > tr > td {
  text-align: right;
  background-color: var(--grayscale---basic--white);
}

consumption-profile .table > thead > tr > th {
  text-align: center;
  background-color: var(--grayscale---gray-100);
}

consumption-profile table.table.profile-hour{
  width: 2300px;
}
consumption-profile table.table.profile-invoice, consumption-profile table.table.profile-month{
  max-width: calc(100% + 300px);
  width: calc(100% + 300px);
}

consumption-profile .loading-component{
  margin-left: -30px;
  margin-top: -15px;
 }

consumption-profile div[date-picker] table {
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px #d3d3d3;
}

consumption-profile .table > tbody > tr.summarization-line > td{
  color: #828282
}

consumption-profile #profileStartDate,
consumption-profile #profileEndDate {
  border: none;
  padding-left: 0px;
  cursor: pointer;
}

consumption-profile .calendar-icon{
  display: inline;
  position: absolute;
  top: 28px;
  left: 85px;
  font-size: 17px;
  cursor: pointer;
}

consumption-profile .table > tbody > tr.summarization-line > td  {
  background-color: var(--grayscale---gray-50);
  font-weight: bold;
}

