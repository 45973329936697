company-selector .dropdown-container {
  width: 100%;
}

company-selector .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

company-selector .dropdown-menu > li:hover {
  background-color: var(--secondary-color);
}

company-selector .dropdown-item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 10px;
}

company-selector .drop-item-col-id {
  width: 50px;
}
