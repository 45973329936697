organization-list .add-button{
  position: absolute;
  left: 0;
  top: 34px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  height: 33px;
}

organization-list .side-panel-ga{
  right: -288px;
}

organization-list .dataTables_filter {
  display: none;
}

.no-img{
  position: absolute;
  z-index: 1000000;
  margin-left: -20px;
  font-size: small;
  align-content: center;
  border-color: transparent;
  background: transparent;
  color: lightgrey;
  margin-top: -4px;
}

.color-btn[disabled]{
  cursor: inherit;
}
